import React, { useEffect, useState } from 'react';
import { ReportesLayout } from '../../../theme/Layout';
import GananciaWidget from './Widgget/GananciaWidget';
import moment from 'moment';
import useReporteGanancia from '../hooks/useReporteGanancia';
import { useAuth } from '../../../context/authcontext';
import GraficaLinea from './Widgget/GraficaLinea';
import TablaSinPaginador from '../../../utils/Components/Tablas/TablaSinpaginador/TablaSinPaginador';
import { PDFViewer } from '@react-pdf/renderer';
import GananciaPDF from '../../PDF/GananciaPDF';
import Loader from '../../../utils/Components/Loader/Loader'

const ReporteDeganancias = () => {
  const { user, FormatoPeso, resolution } = useAuth();

  const [Show, setShow] = useState(false)
  const {
    HanlderLoadReportePkt1,
    DataSetAnterior,
    DataSetActual,
    TotalMesanterior,
    TotalMesActual,
    TotalBultosMesAnterior,
    TotalBultosMesActual,
    LoadingReporte,
    ObtnerDias,
    Datadias,
    DiasTotales,
    DiasTranscurridos,
    DiasRestantes,
  } = useReporteGanancia()
  const AñoActual = moment().year() // Año actual
  const MesActual = parseInt(moment().format('MM'), 10); // mes Actual
  const diaActual = moment().format('DD'); // Dia Actual



  useEffect(() => {

    if (user.id == "119" || user.id == "7" || user.id == "1" || user.id == "8" || user.id == "78") {
      HanlderLoadReportePkt1(0, 0)
      ObtnerDias(AñoActual, MesActual, diaActual)
    } else {
      HanlderLoadReportePkt1(user.id, user.idciudad)
      ObtnerDias(AñoActual, MesActual, diaActual)
    }
  }, [user])

  const RenderItemCabezera = () => {
    return (
      <>
        <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
          <p>Sucursal</p>
        </div>
        <div className='ItemHeader-TablaSinPaginador' style={user.id == "119" || user.id == "1" || user.id == "7" ? { flex: 1 } : { display: 'none' }}>
          <p>Mes anterior</p>
        </div>
        <div className='ItemHeader-TablaSinPaginador' style={user.id == "119" || user.id == "1" || user.id == "7" ? { flex: 1 } : { display: 'none' }}>
          <p>Mes Actual</p>
        </div>
        <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
          <p>Bultos</p>
        </div>
        <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
          <p>Embalaje</p>
        </div>
        <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
          <p>{user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? "Promedio" : "Notas de credito"}</p>
        </div>
        <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
          <p>Tendencia</p>
        </div>
      </>
    )
  }
  const RenderItemCabezeraMovil = () => {
    return (
      <>
        <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
          <p>Sucursal</p>
        </div>
        <div className='ItemHeader-TablaSinPaginador' style={user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? { flex: 1 } : { display: 'none' }}>
          <p>Mes anterior</p>
        </div>
        <div className='ItemHeader-TablaSinPaginador' style={user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? { flex: 1 } : { display: 'none' }}>
          <p>Mes Actual</p>
        </div>
      </>
    )
  }
  const RenderItemCuerpo = () => {
    return (
      <>
        <div className="cuerpoTabla">
          {
            DataSetAnterior?.map(resAnterior => {
              return (
                DataSetActual?.map(resActual => {
                  if (resAnterior.idSucursal == resActual.idSucursal) {
                    return (
                      <>
                        <div className='Cuerpo-TablaSinPaginador' style={Number(resActual.ventas) > Number(resAnterior.ventas) ? { background: 'green', color: '#fff', fontWeight: 'bold' } : {}}>
                          <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                            <p>{resAnterior.sucursal}</p>
                          </div>
                          <div className='ItemCuerpo-TablaSinpaginador' style={user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? { flex: 1 } : { display: 'none' }}>
                            <p>{FormatoPeso(resAnterior.ventas)}</p>
                          </div>
                          <div className='ItemCuerpo-TablaSinpaginador' style={user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? { flex: 1 } : { display: 'none' }}>
                            <p>{FormatoPeso(resActual.ventas)}</p>
                          </div>
                          <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                            <p>{FormatoPeso(resActual.numBulto)}</p>
                          </div>
                          <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                            <p>{FormatoPeso(resActual.embalaje)}</p>
                          </div>
                          <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                            <p> {user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? FormatoPeso(resActual.ventas / DiasTranscurridos) : resActual.notasCredito}</p>
                          </div>
                          <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                            <p>{FormatoPeso(Number(resActual.ventas / DiasTranscurridos) * DiasTotales)}</p>
                          </div>
                        </div>
                      </>
                    )
                  }
                })
              )
            })
          }
        </div>
      </>
    )
  }
  const RenderItemCuerpoMovil = () => {
    return (
      <>
        <div className="cuerpoTabla">
          {
            DataSetAnterior?.map(resAnterior => {
              return (
                DataSetActual?.map(resActual => {
                  if (resAnterior.idSucursal == resActual.idSucursal) {
                    return (
                      <>
                        <div className='Cuerpo-TablaSinPaginador' style={Number(resActual.ventas) > Number(resAnterior.ventas) ? { background: 'green', color: '#fff', fontWeight: 'bold' } : {}}>
                          <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                            <p>{resAnterior.sucursal}</p>
                          </div>
                          <div className='ItemCuerpo-TablaSinpaginador' style={user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? { flex: 1 } : { display: 'none' }}>
                            <p>{FormatoPeso(resAnterior.ventas)}</p>
                          </div>
                          <div className='ItemCuerpo-TablaSinpaginador' style={user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? { flex: 1 } : { display: 'none' }}>
                            <p>{FormatoPeso(resActual.ventas)}</p>
                          </div>
                        </div>
                        <div style={{background:'#94959ba3',paddingTop:'10px'}}>
                          <ul style={{listStyle:'none',margin:'0px',padding:'0px',paddingLeft:'14px'}}>
                            <li>
                              <p style={{fontSize: "12px",marginTop:'0px'}}>Promedio: {user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? FormatoPeso(resActual.ventas / DiasTranscurridos) : resActual.notasCredito}</p>
                            </li>
                            <li>
                              <p style={{fontSize: "12px",marginTop:'0px'}}>Tendencia: {user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? FormatoPeso(Number(resActual.ventas / DiasTranscurridos) * DiasTotales) : resActual.notasCredito}</p>
                            </li>
                          </ul>
                        </div>
                      </>
                    )
                  }
                })
              )
            })
          }
        </div>
      </>
    )
  }
  const HanlderShow = () => {
    if (Show == true) {
      setShow(false)
    } else {
      setShow(true)
    }
  }



  return (
    <div id="ContenedorPrincipal">
      {/* <h1 onClick={() => HanlderShow()}>CAMBIO</h1> */}
      {
        LoadingReporte ?
          <>
           <div style={{display: 'flex', justifyContent: 'center' , width: '100%', alignItems: 'center'}}>
               <Loader
                title="Cargando reporte ganancias"
               ></Loader>
            </div>
          </>
          :
          Show == false ?
            <ReportesLayout>
              <div className='ContainerReporteGanancias' style={{marginBottom: 20}}>
                <div className='HeaderReporteTitle'>
                  <h3>Dashboard <span>PKT 1</span></h3>
                </div>
                {/* <div className='filtrosGanancia'>
                  <div className='continaerFechaFiltro'>
                    <p className='ContainerFechaFilto'>DE:</p>
                    <p className='Containerfiltrog'>01-11-2023</p>
                  </div>
                  <div className='continaerFechaFiltro'>
                    <p className='ContainerFechaFilto'>Hasta:</p>
                    <p className='Containerfiltrog'>01-11-2023</p>
                  </div>
                </div> */}
              </div>
              <div className='CuerpoWidgetsGanancias'>
                <div className='ContainerGanancias'>
                  <div className='ContinerGananciWid'>
                    <GananciaWidget
                      titulo="Ventas"
                      subtitulo="Mes Anterior"
                      data={FormatoPeso(TotalMesanterior)}
                      total={TotalMesanterior}
                      comparacion={TotalMesanterior}
                    ></GananciaWidget>
                  </div>
                  <div className='ContinerGananciWid'>
                    <GananciaWidget
                      titulo="Ventas"
                      subtitulo="Mes Actual"
                      data={FormatoPeso(TotalMesActual)}
                      total={TotalMesActual}
                      comparacion={TotalMesanterior}
                    ></GananciaWidget>
                  </div>
                </div>
                <div className='ContainerGanancias'>
                  <div className='ContinerGananciWidSub'>
                    <GananciaWidget
                      titulo="Bultos del mes"
                      data={FormatoPeso(TotalBultosMesActual)}
                      total={TotalBultosMesActual}
                      comparacion={TotalBultosMesAnterior}
                    ></GananciaWidget>
                  </div>
                  <div className='ContinerGananciWidSub'>
                    <GananciaWidget
                      titulo="Promedio"
                      data={FormatoPeso(TotalMesActual / DiasTranscurridos)}
                      total={TotalBultosMesActual}
                      comparacion={0}
                    ></GananciaWidget>
                  </div>
                  <div className='ContinerGananciWidSub'>
                    <GananciaWidget
                      titulo="Tendencia"
                      data={FormatoPeso(Number(TotalMesActual / DiasTranscurridos) * DiasTotales)}
                      total={TotalBultosMesActual}
                      comparacion={0}
                    ></GananciaWidget>
                  </div>
                </div>

                <div style={user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? { display: 'flex', width: '100%', justifyContent: 'center' } : { display: 'none' }}>
                  <div style={{ position: 'realative', width: '100%' }}>
                    <GraficaLinea
                      MesActual={DataSetActual}
                      MesAnterior={DataSetAnterior}
                      resolution={resolution}
                    ></GraficaLinea>
                  </div>
                </div>

                <div className='TablaDeskGanancia'>
                  <div style={user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? { display: 'flex', width: '100%', justifyContent: 'center', marginTop: 10 } : { marginTop: 10 }}>
                    <div style={{ position: 'realative', width: '100%' }}>
                      <TablaSinPaginador
                        Cabezera={RenderItemCabezera}
                        Cuerpo={RenderItemCuerpo}
                        Modal={false}
                      />
                    </div>
                  </div>
                </div>


                <div className='TablaMovilGanancia'>
                  <div style={user.id == "119" || user.id == "1" || user.id == "7" || user.id == "8" || user.id == "78" ? { display: 'flex', width: '100%', justifyContent: 'center', marginTop: -10 } : { marginTop: 10 }}>
                    <div style={{ position: 'realative', width: '100%' }}>
                      <TablaSinPaginador
                        Cabezera={RenderItemCabezeraMovil}
                        Cuerpo={RenderItemCuerpoMovil}
                        Modal={false}
                      />
                    </div>
                  </div>
                </div>



              </div>

            </ReportesLayout>
            :
            <>
              <PDFViewer style={{ width: '100%', height: "100vh" }}>
                <GananciaPDF
                  TotalMesanterior={TotalMesanterior}
                ></GananciaPDF>
              </PDFViewer>
            </>
      }



    </div>
  )
}

export default ReporteDeganancias