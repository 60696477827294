import React from 'react'

const CalendarioIcon = (props) => {
  return (
    <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 24.0469C2 24.3825 2.15804 24.7045 2.43934 24.9418C2.72064 25.1792 3.10218 25.3125 3.5 25.3125H28.5C28.8978 25.3125 29.2794 25.1792 29.5607 24.9418C29.842 24.7045 30 24.3825 30 24.0469V10.125H2V24.0469Z" fill={props.color}/>
    <path d="M30 4.6285C29.9998 4.46292 29.9607 4.29902 29.885 4.14624C29.8094 3.99346 29.6987 3.85481 29.5592 3.7383C29.4198 3.62178 29.2544 3.52969 29.0726 3.46734C28.8909 3.40499 28.6962 3.37361 28.5 3.375H25.005V1.6875H22.005V3.375H9.995V1.6875H6.995V3.375H3.5C3.30377 3.37361 3.10915 3.40499 2.92735 3.46734C2.74556 3.52969 2.58018 3.62178 2.44075 3.7383C2.30132 3.85481 2.1906 3.99346 2.11495 4.14624C2.03931 4.29902 2.00024 4.46292 2 4.6285V7.59375H30V4.6285Z" fill={props.color}/>
    </svg>
    
  )
}

export default CalendarioIcon