import React,{useState} from 'react'
import axios from 'axios'
import config from '../../config/config';
import useHttpsEstatus from '../../utils/hooks/useHttpsEstatus';
const useCiudades = () => {
  const{HanlderHttpEstatus}=useHttpsEstatus()
    const [Ciudades, setCiudades] = useState()
    const CargarCiudades = async() =>{
        let isMounted = true;
        const source = axios.CancelToken.source();
        const URL_Ciudades = `${config.API_URL}Ciudad/All`
        try {
            const ResponseCiudades = await axios.get(URL_Ciudades, {
                cancelToken: source.token
              });
              if(isMounted){
               
                setCiudades(ResponseCiudades.data)
              }
        } catch (error) {
            console.error(error)
            HanlderHttpEstatus(error.response.status,'')
        }
    }
  return {
    CargarCiudades,
    Ciudades
  }
}

export default useCiudades