import React, { useState } from 'react'
import config from '../../../config/config'
import axios from 'axios';
import moment from 'moment'
import { useAuth } from '../../../context/authcontext';

const useReporteGanancia = () => {

  const [LoadingReporte, setLoadingReporte] = useState(false);
  const [DataSetAnterior, setDataSetAnterior] = useState();
  const [DataSetActual, setDataSetActual] = useState()
  const [TotalMesanterior, setTotalMesanterior] = useState(0)
  const [TotalMesActual, setTotalMesActual] = useState(0)
  const [TotalBultosMesAnterior, setTotalBultosMesAnterior] = useState()
  const [TotalBultosMesActual, setTotalBultosMesActual] = useState()
  const [Datadias, setDatadias] = useState()
  const [DiasTotales, setDiasTotales] = useState()
  const [DiasTranscurridos, setDiasTranscurridos] = useState()
  const [DiasRestantes, setDiasRestantes] = useState()




  const HanlderLoadReportePkt1 = async (usuario, Sucursal) => {
    setLoadingReporte(true)
    const primerDiaMesAnterior = moment().clone().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    const UltimodiaMesAnterior = moment().clone().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    const primerDiaMesActual = moment().startOf('month').format('YYYY-MM-DD');
    const DiaActual = moment().format('YYYY-MM-DD');

   

    const URL_REPORTESMESANTERIOR = `${config.API_REPORTES}Ventas/Ventas?FechaInicio=${primerDiaMesAnterior}&FechaFin=${UltimodiaMesAnterior}&IdUsuario=${usuario}&IdSucursal=${Sucursal}`
    const URL_REPORTESMESACTUAL = `${config.API_REPORTES}Ventas/Ventas?FechaInicio=${primerDiaMesActual}&FechaFin=${DiaActual}&IdUsuario=${usuario}&IdSucursal=${Sucursal}`

    
    try {
      const ResponseMesAnterior = await axios.get(URL_REPORTESMESANTERIOR)
      const ResponseMesActual = await axios.get(URL_REPORTESMESACTUAL)
    
      const TotalAnterior = ResponseMesAnterior.data?.reduce((total, item) => total + item.ventas, 0);
      const TotalBultosAnterior = ResponseMesAnterior.data?.reduce((total, item) => total + item.numBulto, 0);
      setDataSetAnterior(ResponseMesAnterior.data)
     
      await setTotalMesanterior(TotalAnterior)
      await setTotalBultosMesAnterior(TotalBultosAnterior)
      const TotalActual = ResponseMesActual.data?.reduce((totalActual, item) => totalActual + item.ventas, 0);
      const TotalBultosActual = ResponseMesActual.data?.reduce((totalActual, item) => totalActual + item.numBulto, 0);
     
      setDataSetActual(ResponseMesActual.data)
      await setTotalMesActual(TotalActual)
      await setTotalBultosMesActual(TotalBultosActual)
      setLoadingReporte(false)
    } catch (error) {
      console.log(error.response)
      setLoadingReporte(false)
    }
  }


  const HandlerLoadReorteAnterior = async (FechaInicio, FechaFinal, Suc) => {
    const URL_REPORTES = `${config.API_REPORTES}Ventas/Ventas?FechaInicio=${FechaInicio}&FechaFin=${FechaFinal}&IdUsuario=0&IdSucursal=0`
    try {
      const ResponseVentaMesAnterior = await axios.get(URL_REPORTES)
      const TotalAnterior = ResponseVentaMesAnterior.data?.reduce((total, item) => total + item.ventas, 0);
      const TotalBultosAnterior = ResponseVentaMesAnterior.data?.reduce((total, item) => total + item.numBulto, 0);
      setTotalMesanterior(TotalAnterior)
      setTotalBultosMesAnterior(TotalBultosAnterior)
    } catch (error) {
      console.log(error.response)
    }
  }

  const HandlerLoadReporteActual = async (FechaInicioActual, FechaFinalActual, Suc) => {
    const URL_REPORTESACTUAL = `${config.API_REPORTES}Ventas/Ventas?FechaInicio=${FechaInicioActual}&FechaFin=${FechaFinalActual}&IdUsuario=0&IdSucursal=0`
    try {
      const ResponseVentaMesActual = await axios.get(URL_REPORTESACTUAL)
      const TotalActual = ResponseVentaMesActual.data?.reduce((total, item) => total + item.ventas, 0);
      const TotalBultosActual = ResponseVentaMesActual.data?.reduce((total, item) => total + item.numBulto, 0);
     
      setTotalMesActual(TotalActual)
      setTotalBultosMesActual(TotalBultosActual)
     
    } catch (error) {
      console.log(error.response)
    }
  }

  const ObtnerDias = async (año, mes, dia) => {
    const URL_OBTENERDIAS = `${config.API_URL}Utils/GetDays/${año}/${mes}/${dia}`
    try {
      const ResponseDias = await axios.get(URL_OBTENERDIAS)
     
      setDatadias(ResponseDias.data.data[0])
      setDiasTotales(ResponseDias.data.data[0].total)
      setDiasTranscurridos(ResponseDias.data.data[0].transcurridos)
      setDiasRestantes(ResponseDias.data.data[0].restanes)
    } catch (error) {
      console.log(error.response)
    }
  }


  return {
    HandlerLoadReorteAnterior,
    HandlerLoadReporteActual,
    DataSetAnterior,
    DataSetActual,
    TotalMesanterior,
    TotalMesActual,
    TotalBultosMesAnterior,
    TotalBultosMesActual,
    HanlderLoadReportePkt1,
    LoadingReporte,
    ObtnerDias,
    Datadias,
    DiasTotales,
    DiasTranscurridos,
    DiasRestantes,
  }
}

export default useReporteGanancia