import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import config from '../../../config/config';
import * as turf from "@turf/turf";
import { useAuth } from '../../../context/authcontext';
import { faL } from '@fortawesome/free-solid-svg-icons';

const useGetZona = () => {
  const navigate = useNavigate();
  const [Datazona, setData] = useState(null);
  const [DataZonaUsuario, setDataZonaUsuario] = useState()
  const [error, setError] = useState(null);
  const [geoJsonZona, setgeoJsonZona] = useState()
  const [LoadingZona, setLoadingZona] = useState()
  const [isEditzona, setisEditzona] = useState(false)
  const [cordsaddfance, setcordsaddfance] = useState()
  const { user } = useAuth()
  const [seleccionarZona, setseleccionarZona] = useState()

  const LoadZonasPorCiudad = async () => {
    setLoadingZona(true)
    let isMounted = true;
    const source = axios.CancelToken.source();
    let URL_Zona = `${config.API_URL}Geolocalizacion/getgeofanceall?cityId=${JSON.parse(localStorage.getItem("Ciudad")).idciudad}`
    try {
      const RespuestaZona = await axios.get(URL_Zona, {
        cancelToken: source.token
      });

      if (isMounted) {
        setLoadingZona(false)
        setDataZonaUsuario(RespuestaZona.data);
      }
    } catch (error) {
      console.error(error)
    }
  }


  const LoadZonas = async () => {
    setLoadingZona(true)
    let isMounted = true;
    const source = axios.CancelToken.source();
    let URL_Zona = `${config.API_URL}Geolocalizacion/getgeofanceall`
    try {
      const RespuestaZona = await axios.get(URL_Zona, {
        cancelToken: source.token
      });

      if (isMounted) {
        setLoadingZona(false)
        setData(RespuestaZona.data);
        RespuestaZona.data?.map(responsecords => {
                    let Arraygeocerca = new Array(responsecords.cords)
                    let ObjGeocerca = JSON.parse(Arraygeocerca[0]);
                    const cordenadas = ObjGeocerca.map(resto => { return resto });
                    let geocercadata = turf.polygon(cordenadas);
                    setgeoJsonZona(geocercadata)
                  })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const NuevaZonaMapa = (data) => {
    setLoadingZona(true)
    let Addgeofance = {
      method: 'post',
      url: `${config.API_URL}Geolocalizacion/addfance`,
      headers: {
        'Authorization': `Bearer  ${JSON.parse(localStorage.getItem("Token"))}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(Addgeofance)
      .then(function (response) {
        setLoadingZona(false)
        // window.location.reload()
        navigate('/Zonas')

      })
      .catch(function (error) {
        if (error.response.status === 401) {
          var Requesttoken = JSON.stringify({
            "token": JSON.parse(localStorage.getItem("Token")),
            "refreshToken": JSON.parse(localStorage.getItem("Refreshtoken"))
          });

          var Refreshtokenapi = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${config.API_URL}Login/RefreshToken`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: Requesttoken
          };

          axios(Refreshtokenapi)
            .then(function (responseToken) {

              setTimeout(() => {
                // BEGING REFRESH

                let Addgeofance = {
                  method: 'post',
                  url: `${config.API_URL}Geolocalizacion/addfance`,
                  headers: {
                    'Authorization': `Bearer  ${responseToken.data.token}`,
                    'Content-Type': 'application/json'
                  },
                  data: data
                };

                axios(Addgeofance)
                  .then(function (response) {

                  })
                  .catch(function (error) {

                    // console.log(error);
                  });
                // ENDO REFRESH
              }, 3000);
            })
            .catch(function (error) {
              setLoadingZona(false)
              // Swal.fire({
              //   icon: 'warning',
              //   title: "Sesión expirada",
              //   confirmButtonColor: "#3BAF29"
              // }).then((result) => {

              //   window.scrollBy(0.900)
              // })
              // logout(user.id)
            });

        }
        // console.log(error);
      });
  }

  const EditarZonaMapa = (dataEditarZona) => {
    // let ZonaId = `${Datazona.id}`
    let Updategeofance = {
      method: 'post',
      maxBodyLength: Infinity,
      url:`${config.API_URL}Geolocalizacion/UpdateFance/${seleccionarZona.id}`,
      headers: {
        'Authorization': `Bearer  ${JSON.parse(localStorage.getItem("Token"))}`,
        'Content-Type': 'application/json'
      },
      data: dataEditarZona
    };
    
    axios.request(Updategeofance)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      if (error.response.status === 401) {
        var Requesttoken = JSON.stringify({
          "token": JSON.parse(localStorage.getItem("Token")),
          "refreshToken": JSON.parse(localStorage.getItem("Refreshtoken"))
        });

        var Refreshtokenapi = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${config.API_URL}Login/RefreshToken`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: Requesttoken
        };

        axios(Refreshtokenapi)
          .then(function (responseToken) {

            setTimeout(() => {
              // BEGING REFRESH

              let Updategeofance = {
                method: 'post',
                url: `${config.API_URL}Geolocalizacion/UpdateFance/${seleccionarZona.id}`,
                headers: {
                  'Authorization': `Bearer  ${responseToken.data.token}`,
                  'Content-Type': 'application/json'
                },
                data: dataEditarZona
              };

              axios(Updategeofance)
                .then(function (response) {

                })
                .catch(function (error) {

                  // console.log(error);
                });
              // ENDO REFRESH
            }, 3000);
          })
          .catch(function (error) {

            // Swal.fire({
            //   icon: 'warning',
            //   title: "Sesión expirada",
            //   confirmButtonColor: "#3BAF29"
            // }).then((result) => {

            //   window.scrollBy(0.900)
            // })
            // logout(user.id)
          });
      }

      console.log(error);
    });
  }
  
  const handlereditzona = (e,data) => {
    e.preventDefault()
      if(isEditzona === true){
          setisEditzona(false)
      }else{
          setisEditzona(data)
      }
  }


  const handleraddcords = (data) =>{
    setcordsaddfance(data)
}

const HandlerCambioCoordenadas = (data) => {
  setcordsaddfance(data)

}
 //SELECCONAR ZONA
 const SelectZona = async(data) => {
  setseleccionarZona(data)
}
  return {
    LoadZonas,
    Datazona,
    NuevaZonaMapa,
    DataZonaUsuario,
    LoadZonasPorCiudad,
    geoJsonZona,
    handleraddcords,
    cordsaddfance,
    EditarZonaMapa,
    handlereditzona,
    seleccionarZona,
    SelectZona,
    HandlerCambioCoordenadas,
    LoadingZona
  }
}

export default useGetZona