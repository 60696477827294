import React, { useEffect } from 'react'






const CalendarioComponent = (props) => {
  const HandlerCerrarModalRecolector = () => {
    props.closeModal()
  }

  const hanlderSeleccionarMes = (data) => {
    props.seleccionarMes(data)
    props.openModal()
  }

  return (
    <div className='ContainerCalendarioCustom' >
      {
        props.calendario?.map(resCalendario => {
          return (
            <div className='ContainerCardsCalendario' onClick={() => hanlderSeleccionarMes(resCalendario.nombre)}>
              <div className='ContainerCalendarioCardsTitle'>
                <p>{resCalendario.nombre} {JSON.stringify(props.ModalKey)}</p>
              </div>
              <div className='ContainerSemanas'>
                {
                  props.diasSemana?.map(resDiasSemana => {
                    return (
                      <div className='ContainerDiaySemana'>
                        <p style={{fontWeight:'bold', color:'#333333', textTransform:'uppercase'}}>{resDiasSemana.substring(0, 1)}</p>
                        {
                          resCalendario.dias.map(r => {
                            const dayOfMonth = parseInt(r.fecha.substring(0, 2), 10);
                            const firstDayOfMonth = 1; // Día a partir del cual se muestra el día del mes en la primera fila
                            if (r.nombre === resDiasSemana)
                              return (
                                <p
                                  className='ContainerDia'
                                  style={
                                    r.tipo == 1 ? { background: '#B7DEB2', color: '#fff' }
                                      :
                                      r.tipo == 2 ? { color: '#94959B' }
                                        :
                                        { background: 'transparent', color:'#333333' }
                                  }>    {dayOfMonth} </p>
                              )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }


    </div>
  )
}
export default CalendarioComponent