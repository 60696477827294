import React, {useState} from 'react'
const useSiedemenu = () =>{
    const [Activemenu, setActivemenu] = useState(true);

    const handlerActiveMenu = (data) =>{
        setActivemenu(data)
    }
  return {
    handlerActiveMenu,
    Activemenu
  }
}

export default useSiedemenu