import React from 'react'
import AvatarIcon from '../../assets/imagenes/AvatarInicio.webp'

function CardBienvenido(props) {
  return (
    <div className='Container-BodyCardBienvenido'>
        <div className='ContenedorNombreAvatar'>
            <h2>BIENVENIDO:</h2>
            <h3>{props.nombre}</h3>
        </div>
        <div className='ContenedorImganeAvatar'>
            <img src={AvatarIcon}/>
        </div>
    </div>
  )
}

export default CardBienvenido