const URL_DESAROLLO = process.env.REACT_APP_API_URL
const URL_PROD = process.env.REACT_APP_PROD_URL

const URL_SOCKET = process.env.REACT_APP_SOCKET_URL
const URL_SOCKETDESARROLLO = process.env.REACT_APP_SOCKETDESAROLLO_URL

const URL_Documentacion = process.env.REACT_APP_DOCUMENTACION_URL
const URL_DocumentacionDesarrollo = process.env.REACT_APP_DOCUMENTACIONDESARROLLO_URL

const URL_REPORTES = process.env.REACT_APP_REPORTES_URL
const URL_REPORTESDESARROLLO = process.env.REACT_APP_REPORTESDESARROLLO_URL

const API_URL = `${URL_PROD}/api/v2/`
const API_SOCKET = `${URL_SOCKET}`
const API_Documentacion = `${URL_Documentacion}/api/v2/`
const API_REPORTES = `${URL_REPORTES}/Api/V1/`

const VersionApp = "1.0.2";
// const Socket_URL = `https://socketdesarrollo.paq1.com.mx/hub`;
    const Socket_URL = `https://socket.paq1.com.mx/hub`;
// localStorage.setItem("Version", VersionApp)
// URL_PROD
// URL_SOCKETDESARROLLO
export default {
    VersionApp,
    API_URL,
    API_SOCKET,
    Socket_URL,
    API_Documentacion,
    API_REPORTES
}
