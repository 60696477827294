import React, { useRef, useState, useEffect, useCallback} from 'react'
import mapboxgl from 'mapbox-gl';
import { v4 } from 'uuid';
import { Subject } from 'rxjs';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import axios from 'axios';
import * as turf from "@turf/turf";
// import API_URL from '../config';
mapboxgl.accessToken = 'pk.eyJ1IjoicGt0MWRldmVsb3BlciIsImEiOiJjbDZ6OXVjbzgwMmFrM3BsY3FweGkyYWJzIn0.r8Iu1ZteE0WwYt1LFfT1yg';

export const UseMapaPrueba = (Zoom) => {
    const mapaDiv = useRef();

    const mapa = useRef();
    // const [ coords, setCoords ] = useState( puntoinicial );
    const [iszonaclick, setiszonaclick] = useState(false)
    const [cordsdata, setcordsdata] = useState()
    const setRef = useCallback((node) => {
        mapaDiv.current = node;
    }, []);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapaDiv.current,
            style: 'mapbox://styles/mapbox/outdoors-v12',
            center: [-102.6012034945277, 24.007726083194417],
            zoom: Zoom,
            minZoom: Zoom,

        });
        mapa.current = map;

        return () => {
            if (mapa.current) {
                mapa.current.remove();
            }
          };
    }, []);

  return {
    setRef,
    cordsdata
  }
}
export default UseMapaPrueba