import React from 'react'

const SearchIcon =(props) => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.26727 0.0172806C4.01644 0.413996 1.41211 3.12836 0.770198 6.78753C0.623476 7.62794 0.623476 9.04776 0.770198 9.88816C1.06364 11.5585 1.75599 13.0462 2.78305 14.2155C3.83763 15.4161 5.08936 16.1782 6.60244 16.5384C7.30854 16.7106 8.61529 16.7106 9.33974 16.5436C10.1651 16.3557 11.0408 15.9798 11.6644 15.5466C11.834 15.4317 11.9899 15.3326 12.0037 15.3326C12.022 15.3326 12.9115 16.3296 13.9844 17.5406C16.199 20.0514 16.144 20.0044 16.7813 19.9992C17.2399 19.9992 17.515 19.8739 17.813 19.5346C18.111 19.1953 18.2211 18.8821 18.2211 18.3601C18.2211 17.6241 18.2669 17.692 16.0523 15.1603C14.1678 13.0097 14.1036 12.9262 14.1633 12.8113C14.6539 11.9292 15.0023 10.9374 15.1857 9.87772C15.3325 9.0582 15.3325 7.62794 15.1857 6.78753C14.5943 3.40501 12.3292 0.815929 9.37642 0.142559C8.92708 0.0381603 7.6891 -0.0349197 7.26727 0.0172806ZM8.75743 2.56983C9.83493 2.75252 10.8161 3.3215 11.6002 4.2141C13.127 5.95234 13.5397 8.5884 12.6318 10.7808C12.3476 11.4646 12.0495 11.95 11.6002 12.4616C10.0734 14.1946 7.75788 14.6696 5.84131 13.6413C4.2457 12.78 3.1682 11.1566 2.91144 9.22523C2.57672 6.73533 3.66339 4.30806 5.63498 3.14924C6.61161 2.57504 7.66618 2.37669 8.75743 2.56983Z" fill="white"/>
</svg>

  )
}

export default SearchIcon