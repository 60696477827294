import React from 'react'

const Bellh = (props) => {
  return (
    <svg id='Campana' className={props.Alertas ? 'Active' : ''} width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id='Curepocampana' d="M27.4114 22.0936C27.2904 21.9507 27.1717 21.8079 27.0551 21.67C25.4523 19.7693 24.4826 18.6221 24.4826 13.2414C24.4826 10.4557 23.8028 8.17 22.463 6.45571C21.4751 5.18929 20.1397 4.22857 18.3795 3.51857C18.3569 3.50622 18.3366 3.49001 18.3198 3.47071C17.6867 1.39214 15.9542 0 14.0002 0C12.0462 0 10.3144 1.39214 9.68132 3.46857C9.66443 3.48716 9.64448 3.50285 9.62231 3.515C5.51473 5.17286 3.5185 8.35357 3.5185 13.2393C3.5185 18.6221 2.55026 19.7693 0.945984 21.6679C0.829415 21.8057 0.710661 21.9457 0.589722 22.0914C0.277319 22.4608 0.0793865 22.9102 0.0193482 23.3864C-0.04069 23.8626 0.0396793 24.3457 0.250945 24.7786C0.700462 25.7071 1.65851 26.2836 2.75206 26.2836H25.2563C26.3448 26.2836 27.2963 25.7079 27.7472 24.7836C27.9594 24.3506 28.0405 23.8671 27.981 23.3904C27.9215 22.9136 27.7238 22.4636 27.4114 22.0936Z" fill={props.color}/>
    <path id='Campanita' d="M14.0009 31.9989C15.0537 31.9981 16.0866 31.7179 16.9902 31.1881C17.8937 30.6583 18.6341 29.8986 19.1329 28.9896C19.1564 28.946 19.168 28.8973 19.1665 28.848C19.1651 28.7987 19.1508 28.7507 19.1248 28.7085C19.0988 28.6663 19.0622 28.6314 19.0184 28.6072C18.9746 28.583 18.9252 28.5703 18.875 28.5703H9.12838C9.07806 28.5701 9.02854 28.5827 8.98467 28.6069C8.94079 28.6311 8.90404 28.666 8.87799 28.7082C8.85195 28.7504 8.8375 28.7985 8.83606 28.8478C8.83461 28.8971 8.84622 28.946 8.86975 28.9896C9.36846 29.8985 10.1088 30.6581 11.0121 31.1879C11.9155 31.7177 12.9483 31.9979 14.0009 31.9989Z" fill={props.color}/>
    </svg>
    
  )
}

export default Bellh