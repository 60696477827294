import React from 'react'

const MonitoreoIcon = (props) => {
    return (
        <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 14.2987C19.0714 11.0746 23.1181 9.42832 31.9286 9.3982C32.0694 9.39779 32.209 9.41864 32.3392 9.45955C32.4694 9.50045 32.5878 9.56059 32.6874 9.63651C32.787 9.71244 32.8659 9.80264 32.9195 9.90191C32.9732 10.0012 33.0005 10.1076 33 10.2149V24.9163C33 25.1329 32.8871 25.3407 32.6862 25.4939C32.4852 25.647 32.2127 25.7331 31.9286 25.7331C23.3571 25.7331 20.0458 27.0506 18 29C15.9663 27.0603 12.6429 25.7331 4.07144 25.7331C3.40983 25.7331 3.00001 25.3221 3.00001 24.8178V10.2149C2.99948 10.1076 3.02683 10.0012 3.08048 9.90191C3.13414 9.80264 3.21304 9.71244 3.31264 9.63651C3.41223 9.56059 3.53056 9.50045 3.66079 9.45955C3.79102 9.41864 3.93058 9.39779 4.07144 9.3982C12.8819 9.42832 16.9286 11.0746 18 14.2987Z" fill={props.color} stroke="#6A6A6A" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.8102 0C11.9401 0 7.17725 2.89991 7.17725 6.47321C7.17725 12.2272 17.8102 20.1389 17.8102 20.1389C17.8102 20.1389 28.4431 12.2272 28.4431 6.47321C28.4431 2.89991 23.6802 0 17.8102 0ZM17.8102 10.0694C16.969 10.0694 16.1467 9.90071 15.4472 9.58458C14.7478 9.26846 14.2027 8.81913 13.8807 8.29343C13.5588 7.76773 13.4746 7.18927 13.6387 6.63119C13.8028 6.07311 14.2079 5.56048 14.8027 5.15812C15.3975 4.75577 16.1554 4.48177 16.9804 4.37076C17.8054 4.25975 18.6606 4.31672 19.4378 4.53447C20.2149 4.75223 20.8792 5.12098 21.3465 5.59409C21.8139 6.06721 22.0633 6.62345 22.0633 7.19246C22.0621 7.95523 21.6136 8.68652 20.8162 9.22587C20.0189 9.76523 18.9378 10.0686 17.8102 10.0694Z" fill="#D6D6D6" />
        </svg>
    )
}

export default MonitoreoIcon