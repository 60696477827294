import React, { useEffect, useState, useRef } from 'react';
import TablaPaginador from '../../utils/Components/Tablas/paginador/TablaPaginador';
import imgAsignar from '../../assets/iconos/addRec.svg';
import UsePeticiones from '../hooks/UsePeticiones';
import Swal from 'sweetalert2';
import ModalComponent from '../../utils/Components/modal/ModalComponent';
import useModal from '../../utils/Components/modal/usemodal';
import moment from 'moment';
import Peticiones from '../../pages/Peticiones';
import { PeticionesLayout } from '../../theme/Layout';
import UseRecolectores from '../hooks/UseRecolectores';
import { useAuth } from '../../context/authcontext';

import useAsignarRecolector from '../Petiticiones/hooks/useAsignarRecolector';


const PeticionesPendientes = (props) => {
    let Tablapaginador = useRef()
    const { LoadRecolectores, Recolectores } = UseRecolectores()
    const { ModalCambioRecolector, AbrirModalCambioRecolector, CerrarModalCambioRecolector } = useModal()
    const { HandlerAsignarRecolector } = useAsignarRecolector()
    const { user, socket } = useAuth()
    const [Seleccionar, setSeleccionar] = useState()
    const [PeticionSeleccionada, setPeticionSeleccionada] = useState()
    const [active, setactive] = useState(false)
    const Peticiones = useRef({});

    useEffect(() => {
        if (!socket) return;
        socket.on('UnassignPicker', (data) => {
            props.CargaPeticiones(moment().format('YYYY-MM-DD'), JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        })
    }, [socket])
    useEffect(() => {
        if (!socket) return;
        socket.on('AssignPicker', (data) => {
            props.CargaPeticiones(moment().format('YYYY-MM-DD'), JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        })
    }, [socket])





    const handlerAcciones = (data) => {
        if (Seleccionar === data) {
            setSeleccionar()
        } else {
            setSeleccionar(data)
        }
    }
    const handlerFueraAcciones = (e, type) => {
        e.preventDefault()
        if (type === active) {
            setSeleccionar(true)
        } else {
            setSeleccionar()
        }
    }
    const HandleModalAsignarRecolector = (e, PeticionSeleccionada) => {
        setPeticionSeleccionada(PeticionSeleccionada)
        AbrirModalCambioRecolector(e)
    }
    const HandlerCerrarModalRecolector = () => {
        CerrarModalCambioRecolector()
        setPeticionSeleccionada()
    }


    useEffect(() => {
        LoadRecolectores(JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    }, [user])

    const AsignarRecolector = (peticion, recolector, nombre) => {
        Swal.fire({
            title: `Esta por reasignar la petición del cliente ${PeticionSeleccionada.cliente} al recolector ${nombre}`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Continuar',
            denyButtonText: `Cancelar`,
            denyButtonColor: "#DC1D1D",
            confirmButtonColor: "#3BAF29"
        }).then((result) => {
            if (result.isConfirmed) {
                HandlerAsignarRecolector(recolector, peticion)
                HandlerCerrarModalRecolector()
            }
        })
    }

    const RenderItemCambioRecolector = () => {
        return (
            <PeticionesLayout>
                <div className='Cuerpo-ListaModal'>
                    {
                        Recolectores?.map(resp => {
                            return (
                                <>
                                    <div onClick={(result) => AsignarRecolector(PeticionSeleccionada.id, resp.id, resp.descripcion)} className="ItemCuerpoLista">
                                        <div style={{ width: '45px', paddingRight: '10px' }}>
                                            {/* <img style={{width:'100%'}} src={imgRecolector} alt="" /> */}
                                        </div>
                                        <div>
                                            <p>{resp.descripcion}</p>
                                        </div>
                                    </div></>
                            )
                        })
                    }
                </div>
            </PeticionesLayout>
        )
    }
    const RenderItemCabeceraTablaPendientes = () => {
        return (
            <>
                <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
                    <p>Origen</p>
                </div>
                <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
                    <p>Cliente</p>
                </div>
                <div className='ItemHeader-TablaPaginador' style={{ flex: 4 }}>
                    <p>Dirección</p>
                </div>
                <div className='ItemHeader-TablaPaginador campoFecha' style={{ flex: 2 }}>
                    <p>Fecha</p>
                </div>
                <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                    <p></p>
                </div>
            </>
        )
    }
    const RenderItemCuerpoTablaPendientes = () => {
        return (
            <>
            <div className="cuerpoPendientes">
                {
                    props.PeticionesMapa?.map((resp, index) => {
                        if (resp.idusuarioasignado === 0 && resp.estatus === "P" && resp.estatus != "C") {
                            return (
                                <>
                                    <div className="Cuerpo-TablaSinPaginador" style={{height:'50px'}}>
                                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2, paddingTop:'5px' }}>
                                            <img src={resp.urlorigen} width={30} />
                                            <p style={{ margin: '0px' }}> -{resp.id}</p>
                                        </div>
                                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2, padding:'2px' }}>
                                            <p>{resp.cliente}</p>
                                        </div>
                                        <div className='ItemHeader-TablaPaginador' style={{ flex: 4, textAlign:'justify', padding:'2px' }}>
                                            <p>{resp.direccion}</p>
                                        </div>
                                        <div className='ItemCuerpo-Tablapaginador campoFecha' style={{ flex: 2}}>
                                            <p>{moment(resp.fecha).format('DD/MM/YYYY hh:mm A')}</p>
                                        </div>
                                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, position: 'relative', height:'66px' }}>
                                            <div
                                                onClick={() => handlerAcciones(resp.id)}
                                                className={`ContainerActions ${resp.id === Seleccionar ? "active" : ""}`}>
                                                <span style={{ background: '#9597A7' }}></span>
                                                <span style={{ background: '#9597A7' }}></span>
                                                <span style={{ background: '#9597A7' }}></span>
                                            </div>
                                            <div onMouseLeave={(e) => handlerFueraAcciones(e)} className="ContainerSubAction"
                                                style={resp.id !== Seleccionar 
                                                    ?
                                                    {display:'none'}
                                                    :
                                                    (props.PeticionesMapa.length > 1 ? index > props.PeticionesMapa.length - 3 : false)
                                                    ? {display:'block', bottom:'57px'}
                                                    : {display:'block', top: '57px'}
                                                  
                                                  }>
                                                <div onClick={() => handlerAcciones(resp.id)} className="ItemSubmenu">
                                                    <img style={{ width: '34px' }} src={imgAsignar} alt="" />
                                                    <p onClick={(e) => HandleModalAsignarRecolector(e, resp)}>Asignar recolector</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    }
                    )
                }
                </div>
            </>
        )
    }

    return (
        <>
            <ModalComponent
                Nombremodal={ModalCambioRecolector}
                Cerrarmodal={HandlerCerrarModalRecolector}
                Titulo={"Seleccionar recolector"}
                Contenido={RenderItemCambioRecolector}
            ></ModalComponent>
            <TablaPaginador
                Cabezera={RenderItemCabeceraTablaPendientes}
                Cuerpo={RenderItemCuerpoTablaPendientes}
                identificador={Tablapaginador}
            ></TablaPaginador>
        </>
    )
}

export default PeticionesPendientes