import React from 'react'


const GananciaWidget = (props) => {
  return (
    <div className='ContainerCurepoWidget'>
        <div className='ContainerTituloWidget' style={Number(props.comparacion) > Number(props.total) ? {background:'#D60000'} :  Number(props.comparacion) == 0 ? {background:'#6a6a6a'}  : {background:'#26711A'}}>
            <p>{props.titulo}</p>
            <p>{props.subtitulo}</p>
        </div>
        <div className='ContenedorDataWidget'>
            <p> {props.data}</p>
        </div>
    </div>
  )
}

export default GananciaWidget