import React from 'react'
import { Draggable } from 'react-beautiful-dnd';
import IconoPaginadorUltimo from '../../../assets/iconos/IconoPaginadorUltimo';

const ListaDraggable = (props) => {
  return (
    <div>
        <Draggable
         draggableId={`${props.peticion.id}`}
         key={props.peticion.id}
         index={props.index} style={{ cursor: 'pointer !important' }}
         isDragDisabled={props.peticion.estatus === "T" ? true : props.peticion.estatus === "C" ? true : false}
         >
        {(provided, snapshot) => (
          <div
          
          className={[`CardSinglePetitions ${props.peticion.estatus === "R" ? "bkAmarillo" : props.peticion.estatus === "D" ? "bkazul" : "BkBlanco"}`]}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='containerAbsoluteOrigen'>
                <img src={props.peticion.urlorigen} alt="" />
              </div>
              <div>
                <p style={{
                  color: '#151B4E',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  textAlign: 'center'
                }}>{props.peticion.cliente}</p>
              </div>

            </div>

            <div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '10px',
                cursor: 'pointer'
              }}>
                <div className='ContainerObservaciones'>
                  <p>
                  {props.peticion.observaciones === null ? "Sin observación" : props.peticion.observaciones}
                  </p>
                </div>
                <div
                  onClick={() => props.AbrirModal(props.peticion)}
                  style={{
                    width: '20px',
                    height: '20px'
                  }}
                 >
                  <IconoPaginadorUltimo></IconoPaginadorUltimo>
                  </div>
              </div>
            </div>
            {provided.placeholder}

            <div className='EstatusPeticionCirculo' style={{ background:  props.peticion?.estatus === "A" ? "#3BAF29" :
                props.peticion?.estatus === "R" ? "#FFB531" :
                props.peticion?.estatus === "D" ? "#35C1ED" :
                props.peticion?.estatus === "T" ? "#151B4E" :
                props.peticion?.estatus === "C" ? "#B40000" : "#9597A7"}}>
             
            </div>
          </div>
        )} 
        </Draggable>
    </div>
  )
}

export default ListaDraggable