import React,{useState,useEffect}  from 'react'
import config from '../../config/config'
import axios from 'axios'
function useClientes() {
    const [ClientesPaginador, setClientesPaginador] = useState();
    const [Pagina, setPagina] = useState(1);
    const [Paginas, setPaginas] = useState();
    const [Registrosporpagina, setRegistrosporpagina] = useState(10);
    const [Totalpaginas, setTotalpaginas] = useState();
    const [TotalRegistros, setTotalRegistros] = useState();
    const [Buscar, setBuscar] = useState();
    const [loadingDirecciones, setloadingDirecciones] = useState(false);

    const handlerSiguiente = (num) => {
      let siguiente = Pagina + num;
      if (siguiente <= Totalpaginas) {
        setPagina(siguiente)
        ClientesDirecciones(Buscar, siguiente, Registrosporpagina)
      }
    }
    const handlerAnterior = (num) =>{
      let anterior = Pagina - num; 
      if (anterior > 0) {
        setPagina(anterior)
        ClientesDirecciones(Buscar, anterior, Registrosporpagina)
      }
    }
    const HanlderChangueRegistros = (data) => {
      setRegistrosporpagina(data)
      setPagina(1)
      ClientesDirecciones(Buscar,1,data)
    }
    const HandlerBuscar = (data) => {
      setBuscar(data)
    }
    const HanlderChangueResultados = (num) =>{
      const ArrayResultados = Array.from({ length: num / 10 }, (_, index) => (index + 1) * 10);
      ArrayResultados.push(num)
      setTotalRegistros(ArrayResultados)
    }

    // FUNCIONES DE PAGINADOR
    const CargarClientes = async() => {
        let isMounted = true;
        const source = axios.CancelToken.source();
        const URL_CLIENTES = `${config.API_URL}Cliente/paginator/all?page=1&registerbypage=20`
        try {
            const ResponseClientes = await axios.get(URL_CLIENTES, {
                cancelToken: source.token
              });
              
              if (isMounted) {
               
                setClientesPaginador(ResponseClientes.data.resultado)
                setTotalpaginas(ResponseClientes.data.totalPaginas)
                setTotalRegistros(ResponseClientes.data.totalRegistros)
              }
        } catch (error) {
            console.error(error)
        }
    }

    //DIRECCIONES
    const ClientesDirecciones = async(nombre, pag, registros) => {
      setloadingDirecciones(true)
      let isMounted = true;
      const source = axios.CancelToken.source();
      const URL_CLIENTES = `${config.API_URL}Cliente/paginator/all?search=${nombre === undefined ? '' : nombre}&page=${pag}&registerbypage=${registros === undefined ? '1000' : registros}`
      try {
          const ResponseClientes = await axios.get(URL_CLIENTES, {
              cancelToken: source.token
            });
            if (isMounted) {
              setloadingDirecciones(false)
              setClientesPaginador(ResponseClientes.data.resultado)
              setTotalpaginas(ResponseClientes.data.totalPaginas)
              HanlderChangueResultados(ResponseClientes.data.totalRegistros)
            }
      } catch (error) {
        setloadingDirecciones(false)
          console.error(error)
      }
  }





    const CargarClientesPaginador = async(cliente) => {
      
        let isMounted = true;
        const source = axios.CancelToken.source();
        const URL_CLIENTE_Buscador = `${config.API_URL}Cliente/paginator/all?search=${cliente}&page=1&registerbypage=100`
        try {
          const ResponseClientes = await axios.get(URL_CLIENTE_Buscador, {
            cancelToken: source.token
          });
          
          if (isMounted) {
          
            setClientesPaginador(ResponseClientes.data.resultado)
          }
        } catch (error) {
          console.error(error)
        }
            // https://wsdesarrollo.paq1.com.mx/api/v2/Cliente/paginator/all?search=juan%20perez&page=1&registerbypage=10
    }
  return {
    CargarClientes,
    ClientesPaginador,
    CargarClientesPaginador,
    Buscar,
    HandlerBuscar,
    Pagina,
    handlerAnterior,
    Totalpaginas,
    handlerSiguiente,
    TotalRegistros,
    HanlderChangueRegistros,
    Pagina,
    ClientesDirecciones,
    loadingDirecciones
  }
}

export default useClientes