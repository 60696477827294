import React, { useState } from 'react'

const useModal = () => {
    const [ModalClientePeticion, setModalClientePeticion] = useState(false);
    const [ModalDireccion, setModalDireccion] = useState(false);
    const [ModalSinDireccion, setModalSinDireccion] = useState(false);
    const [ModalOrigenPeticiones, setModalOrigenPeticiones] = useState(false)
    const [ModalDetallesOnsitePeticiones, setModalDetallesOnsitePeticiones] = useState(false)
    const [ModalNuevaDrireccionRecolecion, setModalNuevaDrireccionRecolecion] = useState(false)
    const [ModalResumenPeTicion, setModalResumenPeTicion] = useState(false);
    const [ModalEstatusPeticion, setModalEstatusPeticion] = useState(false);
    const [ModalCambioSucursal, setModalCambioSucursal] = useState(false);
    const [ModalMercanciaPeticion, setModalMercanciaPeticion] = useState(false);
    const [ModalCambioRecolector, setModalCambioRecolector] = useState(false);
    const [ModalDireccionesCliente, setModalDireccionesCliente] = useState(false);
    const [ModalEditarDireccionCliente, setModalEditarDireccionCliente] = useState(false)
    const [ModalCalendario, setModalCalendario] = useState(false)

    const [disable, setdisable] = useState(false)

    const AbrirModalMercanciaPeticion = () => {
        setModalMercanciaPeticion(true)
        setdisable(false)
    }
    const CerrarModalMercanciaPeticion = () => {
        setModalMercanciaPeticion(false)
    }

    const AbrirModalCambioSucursal = () => {
        setModalCambioSucursal(true)
    }

    const CerrarModalCambioSucursal = () => {
        setModalCambioSucursal(false)
    }
    const AbrirModalEstatusPeticion = () => {
        setModalEstatusPeticion(true)
    }
    const CerrarModalEstatusPeticion = () => {
        setModalEstatusPeticion(false)
    }
    // FUNCIONES MODAL RESUMEN PETICIONES
    const AbrirModalResumenPeticiones = () => {
        setModalResumenPeTicion(true)
        setdisable(false)
    }
    const CerrarModalResumenPeticiones = () => {
        setModalResumenPeTicion(false)
    }

    // FUNCIONES MODAL CLIENTE PETICIONES
    const AbrirModalClientePeticiones = () => {
        setModalClientePeticion(true)
    }
    const CerrarModalClientePeticiones = () => {
        setModalClientePeticion(false)
    }
    // FUNCIONES MODAL DIRECCIONES PETICIONES
    const AbrirModalDirecciones = () => {
        setModalDireccion(true)
    }
    const CerrarModalDirecciones = () => {
        setModalDireccion(false)
    }
    // FUNCION MODAL SIN DIRECCIONES
    const AbrirModalSinDirecciones = () => {
        setModalSinDireccion(true)
    }
    const CerrarModalSinDirecciones = () => {
        setModalSinDireccion(false)
    }
    // FUNCION MODAL ORIEGEN PETICIONES
    const AbrirModalOrigenPeticiones = () => {
        setModalOrigenPeticiones(true)
    }
    const CerrarModalOrigenPeticiones = () => {
        setModalOrigenPeticiones(false)
    }
    // FUNCION MODAL DATOS ONSITE PETICIONES 
    const AbrirModalDatosOnsitePeticiones = () => {
        setModalDetallesOnsitePeticiones(true)
    }
    const CerrarModalDatosOnsitePeticiones = () => {
        setModalDetallesOnsitePeticiones(false)
    }

    // FUNCION NUEVA DIRECCION RECOLECION 
    const AbrirNuevaDireccionRecolecion = () => {
        setModalNuevaDrireccionRecolecion(true)
    }
    const CerrarNuevaDireccionRecoleccion = () => {
        setModalNuevaDrireccionRecolecion(false)
    }

    // FUNCION CAMBIAR DE RECOLECTOR A LA PETICION
    const AbrirModalCambioRecolector = () => {
        setModalCambioRecolector(true)
    }
    const CerrarModalCambioRecolector = () => {
        setModalCambioRecolector(false)
    }

    // FUNCION MODAL DIRECCIONES CLIENTES
    const AbrirModalDireccionesCliente = () => {
        setModalDireccionesCliente(true)
    }
    const CerrarModalDireccionesCliente = () => {
        setModalDireccionesCliente(false)
    }

    // FUNCION MODAL NUEVA DIRECCION CLIENTE
    const AbrirModalEditarDireccionCliente = () => {
        setModalEditarDireccionCliente(true)
    }
    const CerrarModalEditarDireccionCliente = () => {
        setModalEditarDireccionCliente(false)
    }

    // FUNCION MODAL CALENDARIO
    const AbrirModalCalendario = () => {
        setModalCalendario(true)
    }
    const CerrarModalCalendario = () => {
        if(ModalCalendario === true){
            setModalCalendario(false)
        }
    }

    return {
        // MODAL CLIENTE PETICIONES
        ModalClientePeticion,
        AbrirModalClientePeticiones,
        CerrarModalClientePeticiones,
        // MODAL DIRECCIONES
        ModalDireccion,
        AbrirModalDirecciones,
        CerrarModalDirecciones,
        // MODAL SIN DIRECCION
        ModalSinDireccion,
        AbrirModalSinDirecciones,
        CerrarModalSinDirecciones,
        // MODAL ORIGEN PETICIONES
        ModalOrigenPeticiones,
        AbrirModalOrigenPeticiones,
        CerrarModalOrigenPeticiones,
        // MODAL DATOS ONSITE PETICIONES
        ModalDetallesOnsitePeticiones,
        AbrirModalDatosOnsitePeticiones,
        CerrarModalDatosOnsitePeticiones,
        // MODAL NUEVA DIRECCION RECOLECION 
        ModalNuevaDrireccionRecolecion,
        AbrirNuevaDireccionRecolecion,
        CerrarNuevaDireccionRecoleccion,
        // MODAL RESUMEN PETICION
        ModalResumenPeTicion,
        AbrirModalResumenPeticiones,
        CerrarModalResumenPeticiones,
        // MODAL PETICIONES ACT
        ModalEstatusPeticion,
        AbrirModalEstatusPeticion,
        CerrarModalEstatusPeticion,
        // MODAL CAMBIO SUC
        ModalCambioSucursal,
        AbrirModalCambioSucursal,
        CerrarModalCambioSucursal,
        // MODAL MERCANCIA PETICION
        AbrirModalMercanciaPeticion,
        CerrarModalMercanciaPeticion,
        ModalMercanciaPeticion,
        // MODAL CAMBIO RECOLECTOR
        ModalCambioRecolector,
        AbrirModalCambioRecolector,
        CerrarModalCambioRecolector,
        //DISABLE
        disable,
        setdisable,
        //MODAL DIRECCIONES CLIENTE
        ModalDireccionesCliente,
        AbrirModalDireccionesCliente,
        CerrarModalDireccionesCliente,
        //MODAL NUEVA DIRECCION CLIENTE
        ModalEditarDireccionCliente,
        AbrirModalEditarDireccionCliente,
        CerrarModalEditarDireccionCliente,
        // MODAL CALENDARIO 
        ModalCalendario,
        AbrirModalCalendario,
        CerrarModalCalendario
    }
}

export default useModal