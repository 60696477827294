import React,{useState,useEffect} from 'react'
const Uselogin = (props) => {
    const loginChanenel = new BroadcastChannel('login');
    const [usuario, setusuario] = useState()
    const [contraseña, setcontraseña] = useState()
    // Efecto de renderizado de sesion
    useEffect(() => {
        loginChanenel.onmessage = () => {
          window.location.replace("/");}
      }, [])
    
      // Funciones de custom hook
      const CambioUsuario = (data) =>{
        setusuario(data)
      }
      const CambioContraseña = (data) =>{
        setcontraseña(data)
      }
      const IniciarSecion = (data) =>{
        data.preventDefault();
        //Dd
      //  props.login(usuario,contraseña)
        
      }

  return {
    usuario,
    contraseña,
    CambioUsuario,
    CambioContraseña,
    IniciarSecion
  }
}

export default Uselogin