import React from 'react'
import { Droppable } from "react-beautiful-dnd";
import ListaDraggable from './ListaDraggable';

const DragPetitionComponent = (props) => {
  return (
    <div className='BodyPetitions'>
        <Droppable
            droppableId={props.id}
            index={props.title}
        >
            {(provided, snapshot) => (  
                <div
                className="ContainerBodyDRAG"
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
                >
                    {props.Peticiones?.map((peticion, index) => (
                        <ListaDraggable key={index} index={index} peticion={peticion} AbrirModal={props.AccionAbrirModal}></ListaDraggable>
                    ))}
                </div>
            )}
        </Droppable>
    </div>
  )
}

export default DragPetitionComponent