
import { Layoutcontainer } from './theme/Layout';
import './App.css';
import React,{useEffect,useState} from 'react';
import { AuthProvider } from './context/authcontext';
import Navigation from './router/Navigation';
import Swal from 'sweetalert2';
import axios from 'axios';
import config from './config/config';
import { BroadcastChannel } from 'broadcast-channel';

const App = () => {
  const logoutChannel = new BroadcastChannel('logout');
  const loginChanenel = new BroadcastChannel('login');
  const VERSION_KEY = '1';
  const [loading, setLoading] = useState(true);
  const [isInactive, setIsInactive] = useState(false);
  useEffect(() => {
    const savedVersion = localStorage.getItem(VERSION_KEY);
    axios.get(`${config.API_URL}Monitor/versionamiento`)
      .then(response => {
        let timerInterval
        if (response.data[0].version !== savedVersion) {
          //   localStorage.clear();
          localStorage.setItem(VERSION_KEY, response.data[0].version);
          //  window.location.reload();

          Swal.fire({
            showConfirmButton: true,
            confirmButtonColor: '#3BAF29',
            title: 'Se detectó una nueva versión de MonitorPKT1 !',
            html:
              'Es necesario cerrar sesion será dirigido a la pantalla de inicio en <span id="count"></span> segundos .<br/><br/>' +
              '<button id="increase" className="btn btn-warning" style="display:none;">' +
              'I need 5 more seconds!' +
              '</button>' +
              '</button>',
            timer: 5000,
            didOpen: () => {
              const content = Swal.getHtmlContainer()
              const $ = content.querySelector.bind(content)
              const increase = $('#increase')
              function toggleButtons() {
              }

              increase.addEventListener('click', () => {
                Swal.increaseTimer(5000)
              })
              timerInterval = setInterval(() => {
                Swal.getHtmlContainer().querySelector('#count')
                  .textContent = (Swal.getTimerLeft() / 1000)
                    .toFixed(0)
              }, 100)
            },
            willClose: () => {
              let Version = localStorage.getItem(1)
              logoutChannel.postMessage("Logout")
              clearInterval(timerInterval)
              localStorage.clear();
              localStorage.setItem("auth", "No")
              localStorage.setItem("lastmenu", "login")
              localStorage.setItem(VERSION_KEY, Version);
              window.location.reload(true);
            }
          })
        } else {
          setLoading(false);
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [setLoading]);
  let inactivityTime =  4 * 3600 * 1000; // en milisegundos
  let timeoutId;
  const resetTimer = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(setInactive, inactivityTime);
  };
  const setInactive = () => {
    if (localStorage.getItem("auth") != "No") {
      let Version = localStorage.getItem(1)
      logoutChannel.postMessage("Logout")
      setIsInactive(true);
      localStorage.clear();
      localStorage.setItem("auth", "No")
      localStorage.setItem("lastmenu", "login")
      localStorage.setItem(VERSION_KEY, Version);
      Swal.fire({
        icon: 'success',
        title: "Se ha cerrado su sesión por inactividad",
        showConfirmButton: false,
        timer: 1100
      }).then((result) => {
        window.location.reload(true)
        window.scrollBy(0.900)
      })

      logoutChannel.onmessage = () => {
        // logout();
        logoutChannel.close();
      }
    }
  };
  useEffect(() => {
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('mousedown', resetTimer);
    document.addEventListener('keypress', resetTimer);
    document.addEventListener('touchmove', resetTimer);
    timeoutId = setTimeout(setInactive, inactivityTime);
    return () => {
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('mousedown', resetTimer);
      document.removeEventListener('keypress', resetTimer);
      document.removeEventListener('touchmove', resetTimer);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    logoutChannel.onmessage = () => {
      let Version = localStorage.getItem(1)
      localStorage.clear();
      localStorage.setItem("auth", "No")
      localStorage.setItem("lastmenu", "login")
      localStorage.setItem(VERSION_KEY, Version);
      Swal.fire({
        icon: 'success',
        title: "Se ha cerrado su sesión",
        showConfirmButton: false,
        timer: 1100
      }).then((result) => {
        window.location.reload(true)
        window.scrollBy(0.900)
      })
      logoutChannel.close();
    }
  }, [])

  return (
    <AuthProvider>
      <Layoutcontainer>
        <Navigation></Navigation>
      </Layoutcontainer>
    </AuthProvider>
  );
}

export default App;
