import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/authcontext";
import useSiedemenu from "../utils/hooks/useSiedemenu";
import { HeaderLayout, LayoutMonitor } from "../theme/Layout";
import Sidemenu from "../components/Sidemenu/Sidemenu";
import HederComponent from "../components/Header/Header";
import useMode from "../utils/hooks/useMode";
import NavbarLarge from "../components/Header/Navbar";
import useCiudades from "../components/hooks/useCiudades";
import ModalComponent from "../utils/Components/modal/ModalComponent";
import useModal from "../utils/Components/modal/usemodal";
import flechaabajo from '../assets/iconos/flechaabajoselect.webp'
import LogoSmall from '../assets/iconos/LogoSmall.webp'
import Fondo from '../assets/imagenes/SIDEBAR.png'
import LogoutIcon from "../assets/iconos/Logout";
import Permisos from "../utils/permisos";
export function ProtectedRoute({ children }) {

    const{UsuariosPermisoSuc}=Permisos();
    const { ModalCambioSucursal,
        AbrirModalCambioSucursal,
        CerrarModalCambioSucursal, } = useModal()


    const { Activemenu, handlerActiveMenu } = useSiedemenu();
    const { Darkmode, ColorMode } = useMode()
    const [CudadPricnipal, setCudadPricnipal] = useState(localStorage.getItem("Ciudad"));
    const [CiudadSeleccionada, setCiudadSeleccionada] = useState();
    const [ActiveMenu, setActiveMenu] = useState(false)
    const { Ciudades, CargarCiudades } = useCiudades()
    const navigate = useNavigate()


    useEffect(() => {
        if (!Ciudades) {
            CargarCiudades()
        }
    }, [Ciudades, CargarCiudades])
    const HanlderActiveMenu = () => {
        if (ActiveMenu === true) {
            setActiveMenu(false)
        } else {
            setActiveMenu(true)
        }
    }
    const { user, HandlerCambioCiudad,logout } = useAuth();
    if (!user) return <Navigate to="/login" />;


    const handlernavigate = (ruta) => {
        HanlderActiveMenu()
        navigate(ruta, {replace:true})
    }

    const HanlderCambioCiudad = async (e) => {
        const Arrayporciudad = Ciudades?.filter(datos => datos.nombre == e.target.value);
        await HandlerCambioCiudad(Arrayporciudad[0])
        window.location.reload()
    }

   
    return (
        <LayoutMonitor activemenu={
            Activemenu === true ? '28fr' : '12fr'
        }
            BodyColor={Darkmode === false ? '#EFF0F2' : 'red'}
            ImagenMonitor={Fondo}
        >
            <div className="grid-container-body">
                <div className="header-Grid">
                    <HederComponent
                        activemenu={Activemenu}
                        isDarkMode={Darkmode}
                        colormode={ColorMode}
                        HanlderActiveMenu={HanlderActiveMenu}
                    ></HederComponent>
                </div>
                <div className="Header-navbar">
                    <NavbarLarge
                        activemenu={Activemenu}
                        isDarkMode={Darkmode}
                        colormode={ColorMode}
                        HanlderActiveMenu={HanlderActiveMenu}
                    ></NavbarLarge>
                </div>
                <div className="Sidebar-Grid">
                    <Sidemenu
                        Darkmode={Darkmode}
                        accionMenu={handlerActiveMenu}
                        activemenu={Activemenu}
                    ></Sidemenu>
                </div>
                <div className="Container-Body-grid">

                    {children}

                </div>
                <footer className="Footer-grid">
                    <div className="containercopy">
                        <p>DESARROLLO PKT 1 MEXICO</p>
                    </div>
                </footer>

{/* HanlderActiveMenu */}
                <div className="MenuResponsivo" style={ActiveMenu === true ? { opacity: 1, height: '100vh' } : { opacity: 0, width: '0px !important', height: '0vh', zIndex: -1 }}>
                    <div className="CrossClose" onClick={() => HanlderActiveMenu()}>
                        <span style={ActiveMenu === true ? { opacity: 1 } : { opacity: 0, width: '0px !important', height: '0vh'}}></span>
                        <span style={ActiveMenu === true ? { opacity: 1 } : { opacity: 0, width: '0px !important', height: '0vh'}}></span>
                    </div>
                    <div className="ContainerTituloMenuResponsive">
                        <h3>Menu</h3>
                    </div>
                    <ul className="ListaMenuResponsivo">
                        <li onClick={() => handlernavigate('/')}>
                            <p>Inicio</p>
                        </li>
                        <li onClick={() => handlernavigate('/mapaciudad')}>
                            <p>Mapa</p>
                        </li>
                        <li onClick={() => handlernavigate('/Peticiones')}>
                            <p >Peticiones</p>
                        </li>
                        <h4 style={{textDecoration:'underline'}}>Reportes</h4>
                        <li onClick={() => handlernavigate('/ReporteGanancias')}>
                            <p >Ganancias</p>
                        </li>
                        <div className="ContainerTituloMenuResponsive" style={{marginLeft:'15px'}}>
                            <h3 style={{marginBottom:'8px', marginTop:'35px', fontSize:'18px'}}>Ciudad</h3>
                        </div>
                        
                        <HeaderLayout
                            selectimage={flechaabajo}
                        >
                            <div className='ContainerCiudadSelect'>
                                <select
                                    value={JSON.parse(localStorage.getItem("Ciudad")).nombreciudad}
                                    onChange={(e) => HanlderCambioCiudad(e)}
                                    disabled={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? false : true}
                                    style={{
                                        backgroundImage: flechaabajo
                                    }}
                                    name="selectedFruit">
                                    {
                                        Ciudades?.map(res => {

                                            return (
                                                <option value={res.nombre} key={res.id}>{res.nombre}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>

                        </HeaderLayout>
                        <div className="ContainerTituloMenuResponsive" style={{ marginLeft: '15px', marginTop:'20px', display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }} onClick={() => logout()}>
                            <h3 style={{ fontSize: '18px' }}>Cerrar sesión</h3>
                            <div className='containericonlogout'>
                                <LogoutIcon></LogoutIcon>
                            </div>
                        </div>
                    </ul>
                    
                </div>
            </div>


        </LayoutMonitor>
    );
}