import { useRef, useState, useEffect, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import { Subject } from 'rxjs';
const useMapaClienteDireccion =(direccion,CambiarLat,CambiarLng,editar) =>{
    const [iseditable, setiseditable] = useState(true)
    const handlerchangueEditable = (data) =>{
        setiseditable(data)
    }
    const [puntoinicial, setpuntoinicial] = useState(JSON.parse(localStorage.getItem('coordenadamapa')));
    const mapaDiv = useRef();
    const mapa = useRef();
    const setRef = useCallback( (node) => {
      mapaDiv.current = node;
  },[]);
  useEffect( () => {
    const map = new mapboxgl.Map({
        container: mapaDiv.current,
        style: 'mapbox://styles/mapbox/outdoors-v12?optimize=true',
        center: [puntoinicial.lng, puntoinicial.lat],
        //center: [-74.5, 40],
        zoom: 11,
    });
    mapa.current = map;
},[ puntoinicial ]);

const agregarMarcadorCliente = useCallback((lng,lat, id) => {
    const marker = new mapboxgl.Marker({
        draggable: iseditable === true ? true : false
        })
        .setLngLat([lng, lat])
        .addTo(mapa.current);

        function onDragEnd() {
            const lngLat = marker.getLngLat();
            // HanclerChanguelat(lngLat.lat)
            // HanlderChanguelang(lngLat.lng)
           
            }
            
            marker.on('dragend', onDragEnd);

}, []);
useEffect(() => {
    if(direccion){
      
    
      const marker = new mapboxgl.Marker({
        draggable: iseditable === true ? true : false
        })
        .setLngLat([direccion.lng, direccion.lat])
        .addTo(mapa.current);

        function onDragEnd() {
          editar(true)
            const lngLat = marker.getLngLat();
            CambiarLat(lngLat.lat)
            CambiarLng(lngLat.lng)
           
            }
            marker.on('dragend', onDragEnd);
    }
}, [direccion])


useEffect(() => {
  if(direccion){
   
    mapa.current?.flyTo({ zoom: 18, center: [direccion.lng,direccion.lat], })
  }
}, [direccion])


const handleflyAddress = (lng, lat) => {
    mapa.current?.flyTo({ zoom: 10, center: [lng,lat], })
 
}
  return {
    setRef,
    agregarMarcadorCliente,
    handleflyAddress,
    handlerchangueEditable
  }
}

export default useMapaClienteDireccion