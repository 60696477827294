import React, { useState, useEffect } from 'react'
import { MapaMonitorLayout } from '../theme/Layout'
import MapaMonitor from '../components/Mapa/MapaMonitor'
import RecoLibre from '../assets/iconos/RecolectorLibre.svg'
import RecoReco from '../assets/iconos/RecolectorReco.svg'
import RecoDocu from '../assets/iconos/RecolectorDocu.svg'
import PeticionIcon from '../assets/iconos/locationpetition.svg'
import PeticionIconR from '../assets/iconos/locationpetitionR.svg'
import PeticionIconD from '../assets/iconos/locationpetitionD.svg'
import PeticionIconP from '../assets/iconos/locationpetitionP.svg'
import RecoAusente from '../assets/iconos/RecolectorAusente.svg'
import SucursalIcon from '../assets/imagenes/sucursalnvo.webp'
import { useAuth } from '../context/authcontext'
import WidgetReco from '../components/Mapa/WidgetReco'
import UsePeticiones from '../components/hooks/UsePeticiones'
import DetallesREco from '../components/Mapa/DetallesREco'
import UseDirections from '../components/Mapa/hooks/UseDirections'
import useGetZona from '../components/Mapa/hooks/useGetZona'
import useSucursalesActivas from '../components/hooks/useSucursalesActivas'
import moment from 'moment'
import useCiudades from '../components/hooks/useCiudades'

const Mapa = () => {
  const { LoadZonas, Datazona, DataZonaUsuario, LoadZonasPorCiudad } = useGetZona()
  const { CargarSucursales, SucursalesData } = useSucursalesActivas()
  const { Ciudades, CargarCiudades } = useCiudades()
  const [FechAactual, setFechAactual] = useState(moment().format('YYYY-MM-DD'))
  const { socket, RecolectoresMapa, HandleEditReco } = useAuth()
  const { LoadPeticionesReco, PeticionesRecolectando, PeticionesDocumenando, PeticionesActivas, LoadPeticiones, PeticionesMapa } = UsePeticiones()
  const [RecolectorSelecionado, setRecolectorSelecionado] = useState()
  const { GetRoutes, Ruta, LimpiarRuta } = UseDirections();
  const [RutaEncurso, setRutaEncurso] = useState();
  const [recolectorCiudad, setrecolectorCiudad] = useState();
  const [LosMochis, setLosMochis] = useState();
  const [Guadalajara, setGuadalajara] = useState();
  const [Monterrey, setMonterrey] = useState();
  const [Culiacan, setCuliacan] = useState();
  const [Hermosillo, setHermosillo] = useState();
  const [Queretaro, setQueretaro] = useState();
  const [Chihuahua, setChihuahua] = useState();
  const [Tijuana, setTijuana] = useState();
  const [Libre, setLibre] = useState()
  const [activarCiudad, setactivarCiudad] = useState(false)
  const [LMM, setLMM] = useState(false)
  const [GDL, setGDL] = useState(false)
  const [MTY, setMTY] = useState(false)
  const [CLN, setCLN] = useState(false)
  const [HMO, setHMO] = useState(false)
  const [QRO, setQRO] = useState(false)
  const [CUU, setCUU] = useState(false)
  const [TIJ, setTIJ] = useState(false)
  const [sucDespleg, setsucDespleg] = useState('')

  // const [contador, setcontador] = useState(0)

  // Método para incrementar el contador
  //  const incrementarContador = () => {
  //   setcontador(contador + 1);
  // };

  // Método para disminuir el contador
  // const disminuirContador = () => {
  //   setcontador(contador - 1);
  // };  

  //CIUDADES
  useEffect(() => {
    if (!Ciudades) {
      CargarCiudades()
    }
  }, [Ciudades, CargarCiudades])

  const handlerDesplegarCiudad = () => {
    if (activarCiudad === true) {
      setactivarCiudad(false)
    } else{
      setactivarCiudad(true)
    }
  }
  const handlerActivarCiudadLMM = () => {
      if(activarCiudad === true){
          setactivarCiudad(false)
          setLMM(false)
      }else{
          setactivarCiudad(true)
          setLMM(true)
      }
  }
  const handlerActivarCiudadGDL = () => {
      if(activarCiudad === true){
          setactivarCiudad(false)
          setGDL(false)
      }else{
          setactivarCiudad(true)
          setGDL(true)
      }
  }
  const handlerActivarCiudadMTY = () => {
      if(activarCiudad === true){
          setactivarCiudad(false)
          setMTY(false)
      }else{
          setactivarCiudad(true)
          setMTY(true)
      }
  }
  const handlerActivarCiudadCLN = () => {
    if(activarCiudad === true){
        setactivarCiudad(false)
        setCLN(false)
    }else{
        setactivarCiudad(true)
        setCLN(true)
    }
}
const handlerActivarCiudadHMO = () => {
  if(activarCiudad === true){
      setactivarCiudad(false)
      setHMO(false)
  }else{
      setactivarCiudad(true)
      setHMO(true)
  }
}
const handlerActivarCiudadQRO = () => {
  if(activarCiudad === true){
      setactivarCiudad(false)
      setQRO(false)
  }else{
      setactivarCiudad(true)
      setQRO(true)
  }
}
const handlerActivarCiudadCUU = () => {
  if(activarCiudad === true){
      setactivarCiudad(false)
      setCUU(false)
  }else{
      setactivarCiudad(true)
      setCUU(true)
  }
}
const handlerActivarCiudadTIJ = () => {
  if(activarCiudad === true){
      setactivarCiudad(false)
      setTIJ(false)
  }else{
      setactivarCiudad(true)
      setTIJ(true)
  }
}


  useEffect(() => {
    if (!DataZonaUsuario) {
      LoadZonasPorCiudad()
    }
  }, [DataZonaUsuario, LoadZonasPorCiudad])
  //MAPA
  const HandlerRutaEncurso = async (data) => {
    await setRutaEncurso(data)
  }

  useEffect(() => {
    if (RecolectorSelecionado) {

    } else {
      LimpiarRuta()
    }
  }, [RecolectorSelecionado])


  useEffect(() => {
    if (PeticionesMapa === undefined) {
      setTimeout(() => {
        LoadPeticiones(FechAactual, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        CargarSucursales()
        LoadZonas()

      }, 2000);
    }
  }, [PeticionesMapa])

  const SeleccionarReco = (data) => {
    if (data.id === RecolectorSelecionado?.id) {
      setRecolectorSelecionado(undefined)
    } else {
      setRecolectorSelecionado(data)
      LoadPeticionesReco(data.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    }
  }
  //CIUDADES RECO



  useEffect(() => {
    const recoLMM = RecolectoresMapa.filter(reco => reco.cityId == 1)
    setLosMochis(recoLMM)

    const recoGDL = RecolectoresMapa.filter(reco => reco.cityId == 2)
    setGuadalajara(recoGDL)

    const recoMTY = RecolectoresMapa.filter(reco => reco.cityId == 3)
    setMonterrey(recoMTY)

    const recoCLN = RecolectoresMapa.filter(reco => reco.cityId == 4)
    setCuliacan(recoCLN)

    const recoHMO = RecolectoresMapa.filter(reco => reco.cityId == 5)
    setHermosillo(recoHMO)

    const recoQRO = RecolectoresMapa.filter(reco => reco.cityId == 6)
    setQueretaro(recoQRO)

    const recoCUU = RecolectoresMapa.filter(reco => reco.cityId == 7)
    setChihuahua(recoCUU)

    const recoTIJ = RecolectoresMapa.filter(reco => reco.cityId == 8)
    setTijuana(recoTIJ)


  }, [RecolectoresMapa])





  return (
    <MapaMonitorLayout
      imgcar={RecoLibre}
      imgcarD={RecoDocu}
      imgcarR={RecoReco}
      imgcarI={RecoAusente}
      imgpetition={PeticionIcon}
      imgpetitionP={PeticionIconP}
      imgpetitionR={PeticionIconR}
      imgpetitionD={PeticionIconD}
      SucrusalIcon={SucursalIcon}
    >
      {
        RecolectoresMapa ?
          <>
            <div className='ContainerMapaCiudadReco' style={{ position: 'relative' }}>
              <div className='containermonitormap'>
                <MapaMonitor
                  Recolectores={RecolectoresMapa}
                  socket={socket}
                  AccionEditarReco={HandleEditReco}
                  RecolectorSelecionado={RecolectorSelecionado}
                  accionCargarReco={SeleccionarReco}
                  PeticionesMapa={[]}
                  RutaREco={Ruta}
                  RutaEncurso={RutaEncurso}
                  Datazona={Datazona}
                  Zoom="3.8"
                  Sucursales={SucursalesData}
                ></MapaMonitor>
              </div>
              {/* responsivo */}
              <div className="contenedorInfoEstatusMovil"
              style={{
                // position: 'absolute',
                // // top: 5,
                // right: RecolectorSelecionado ? '24%' : 5,
                // cursor:'pointer',
                // transition: '1s',
                // zIndex:'5'
                width: '40%'
              }}>
                {/* LOS MOCHIS */}
                <div className="estatusRecoCiudad" onClick={() => handlerDesplegarCiudad()} style={activarCiudad === true ? {width:'140px', transition: '1.3s',  opacity:1} : {width:'40%',  transition: '1.3s',  opacity:0.80}}>
                  <div className="contenedorCiudadEstatus">
                    <p>LMM</p>
                  </div>
                  <div className="contenedorEstatusRecolectores" style={activarCiudad === true  ? { display:'flex'} : {display: 'none'}}>
                    {
                      LosMochis?.map(respLmm => {
                        if (respLmm.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respLmm.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respLmm.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respLmm.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* GUADALAJARA */}
                <div className="estatusRecoCiudad" onClick={() => handlerDesplegarCiudad()} style={activarCiudad === true  ? {width:'140px', transition: '1.3s',  opacity:1} : { transition: '1.3s', width:'40%', opacity:0.80}}>
                  <div className="contenedorCiudadEstatus">
                    <p>GDL</p>
                  </div>
                  <div className="contenedorEstatusRecolectores" style={activarCiudad === true ? { display:'flex'} : {display: 'none'}}>
                    {
                      Guadalajara?.map(respGdl => {
                        if (respGdl.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respGdl.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respGdl.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respGdl.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* MONTERREY */}
                <div className="estatusRecoCiudad" onClick={() => handlerDesplegarCiudad()} style={activarCiudad === true ? {width:'140px', transition: '1.3s',  opacity:1} : {width:'40%', transition: '1.3s', opacity: 0.80}}>
                  <div className="contenedorCiudadEstatus">
                    <p>MTY</p>
                  </div>
                  <div className="contenedorEstatusRecolectores" style={activarCiudad === true ? { display:'flex'} : {display: 'none'}}>
                    {
                      Monterrey?.map(respMty => {
                        if (respMty.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respMty.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respMty.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respMty.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* CULIACAN */}
                <div className="estatusRecoCiudad" onClick={() => handlerDesplegarCiudad()} style={activarCiudad === true ? {width:'140px', transition: '1.3s',  opacity:1} : {width:'40%',  transition: '1.3s', opacity:0.80}}>
                  <div className="contenedorCiudadEstatus">
                    <p>CLN</p>
                  </div>
                  <div className="contenedorEstatusRecolectores" style={activarCiudad === true ? { display:'flex'} : {display: 'none'}}>
                    {
                      Culiacan?.map(respCln => {
                        if (respCln.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respCln.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respCln.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respCln.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* HERMOSILLO */}
                <div className="estatusRecoCiudad" onClick={() => handlerDesplegarCiudad()} style={activarCiudad === true ? {width:'140px', transition: '1.3s',  opacity:1} : {width:'40%',  transition: '1.3s', opacity:0.80}}>
                  <div className="contenedorCiudadEstatus">
                    <p>HMO</p>
                  </div>
                  <div className="contenedorEstatusRecolectores" style={activarCiudad === true ? { display:'flex'} : {display: 'none'}}>
                    {
                      Hermosillo?.map(respHmo => {
                        if (respHmo.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respHmo.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respHmo.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respHmo.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* QUERETARO */}
                <div className="estatusRecoCiudad" onClick={() => handlerDesplegarCiudad()} style={activarCiudad === true ? {width:'140px', transition: '1.3s',  opacity:1} : {width:'40%',  transition: '1.3s', opacity:0.80}}>
                  <div className="contenedorCiudadEstatus">
                    <p>QRO</p>
                  </div>
                  <div className="contenedorEstatusRecolectores" style={activarCiudad === true ? { display:'flex'} : {display: 'none'}}>
                    {
                      Queretaro?.map(respQro => {
                        if (respQro.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respQro.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respQro.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respQro.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* CHIHUAHUA */}
                <div className="estatusRecoCiudad" onClick={() => handlerDesplegarCiudad()} style={activarCiudad === true ? {width:'140px', transition: '1.3s',  opacity:1} : {width:'40%',   transition: '1.3s', opacity:0.80}}>
                  <div className="contenedorCiudadEstatus">
                    <p>CUU</p>
                  </div>
                  <div className="contenedorEstatusRecolectores" style={activarCiudad === true ? { display:'flex'} : {display: 'none'}}>
                    {
                      Chihuahua?.map(respCuu => {
                        if (respCuu.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respCuu.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respCuu.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respCuu.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* TIJUANA */}
                <div className="estatusRecoCiudad" onClick={() => handlerDesplegarCiudad()} style={activarCiudad === true ? {width:'140px', transition: '1.3s',  opacity:1} : {width:'40%',  transition: '1.3s', opacity:0.80}}>
                  <div className="contenedorCiudadEstatus">
                    <p>TIJ</p>
                  </div>
                  <div className="contenedorEstatusRecolectores" style={activarCiudad === true ? { display:'flex'} : {display: 'none'}}>
                    {
                      Tijuana?.map(respTij => {
                        if (respTij.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respTij.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respTij.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respTij.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
              </div>


              {/* dispositivos grandes */}
              <div className="contenedorInfoEstatus">
                {/* LOS MOCHIS */}
                <div className="estatusRecoCiudad">
                  <div className="contenedorCiudadEstatus">
                    <p>LMM</p>
                  </div>
                  <div className="contenedorEstatusRecolectores">
                    {
                      LosMochis?.map(respLmm => {
                        if (respLmm.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respLmm.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respLmm.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respLmm.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                    {/* <span style={{background: '#B40000'}}>{itemsCiudad}</span> */}

                    {/* <span style={{ background: '#35C1ED' }}>D</span>
                          <span style={{ background: '#FFB531' }}>R</span>
                          <span style={{ background: '#3BAF29' }}>A</span> */}
                  </div>
                </div>
                {/* GUADALAJARA */}
                <div className="estatusRecoCiudad">
                  <div className="contenedorCiudadEstatus">
                    <p>GDL</p>
                  </div>
                  <div className="contenedorEstatusRecolectores">
                    {
                      Guadalajara?.map(respGdl => {
                        if (respGdl.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respGdl.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respGdl.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respGdl.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* MONTERREY */}
                <div className="estatusRecoCiudad">
                  <div className="contenedorCiudadEstatus">
                    <p>MTY</p>
                  </div>
                  <div className="contenedorEstatusRecolectores">
                    {
                      Monterrey?.map(respMty => {
                        if (respMty.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respMty.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respMty.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respMty.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* CULIACAN */}
                <div className="estatusRecoCiudad">
                  <div className="contenedorCiudadEstatus">
                    <p>CLN</p>
                  </div>
                  <div className="contenedorEstatusRecolectores">
                    {
                      Culiacan?.map(respCln => {
                        if (respCln.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respCln.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respCln.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respCln.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* HERMOSILLO */}
                <div className="estatusRecoCiudad">
                  <div className="contenedorCiudadEstatus">
                    <p>HMO</p>
                  </div>
                  <div className="contenedorEstatusRecolectores">
                    {
                      Hermosillo?.map(respHmo => {
                        if (respHmo.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respHmo.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respHmo.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respHmo.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* QUERETARO */}
                <div className="estatusRecoCiudad">
                  <div className="contenedorCiudadEstatus">
                    <p>QRO</p>
                  </div>
                  <div className="contenedorEstatusRecolectores">
                    {
                      Queretaro?.map(respQro => {
                        if (respQro.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respQro.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respQro.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respQro.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* CHIHUAHUA */}
                <div className="estatusRecoCiudad">
                  <div className="contenedorCiudadEstatus">
                    <p>CUU</p>
                  </div>
                  <div className="contenedorEstatusRecolectores">
                    {
                      Chihuahua?.map(respCuu => {
                        if (respCuu.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respCuu.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respCuu.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respCuu.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
                {/* TIJUANA */}
                <div className="estatusRecoCiudad">
                  <div className="contenedorCiudadEstatus">
                    <p>TIJ</p>
                  </div>
                  <div className="contenedorEstatusRecolectores">
                    {
                      Tijuana?.map(respTij => {
                        if (respTij.status == "L") {
                          return (
                            <span style={{ background: '#3BAF29' }}>A</span>
                          )
                        } else if (respTij.status == "R") {
                          return (
                            <span style={{ background: '#FFB531' }}>R</span>
                          )
                        } else if (respTij.status == "D") {
                          return (
                            <span style={{ background: '#35C1ED' }}>D</span>
                          )
                        } else if (respTij.status == "I") {
                          return (
                            <span style={{ background: '#B40000' }}>I</span>
                          )
                        }
                      }
                      )
                    }
                  </div>
                </div>
              </div>

            </div>

          </>
          :
          <>
            <h3>Cargando</h3>
          </>
      }
    </MapaMonitorLayout>
  )
}

export default Mapa