import React from 'react'
import useMapaClienteDireccion from './hooks/useMapaClienteDireccion'

function MapaclientePeticion(props) {
    // HanclerChanguelat, HanlderChanguelang,isEditable
    const { setRef} = useMapaClienteDireccion(props.Direccion,props.AccionCambioLat,props.AccionCambioLng,props.AccionEditar);
  return (
    <div
        style={{height:'45vh'}}
        ref={setRef}
        className="containerMapDir"

      />
  )
}

export default MapaclientePeticion