import React, {useState} from 'react'
import UseMapZonas from './hooks/UseMapZonas'


export const MapaZonas = (props) => {
    const{setRef}=UseMapZonas(props.Recolectores,props.socket,props.Accionguardarcordenada,props.AccionEditarReco,props.RecolectorSelecionado,props.accionCargarReco,props.PeticionesMapa,props.RutaREco,props.RutaEncurso,props.Datazona,props.Zoom,props.Sucursales,props.GeoJsonZona)
    return (
      
       <div
      ref={setRef}
      className="NewMapMonitor"
      />
     
    )
}

export default MapaZonas