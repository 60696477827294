import React,{useState} from 'react'
import moment from 'moment'
const useReportePeticionesFinalizadas = () => {
    const [Estatus, setEstatus] = useState("0")
    const [FechaInicial, setFechaInicial] = useState(moment().format('YYYY-MM-DD'))
    const [Fechafinal, setFechafinal] = useState(moment().format('YYYY-MM-DD'))
    const [CiudadSeleccionada, setCiudadSeleccionada] = useState(JSON.parse(localStorage.getItem("Ciudad")).nombreciudad)
    const [IdciudadSeleccionada, setIdciudadSeleccionada] = useState(JSON.parse(localStorage.getItem("Ciudad")).idciudad)

    
    const handlerCambioFechaInicial = (data) =>{
      setFechaInicial(data)
    }
    const HanlderCambioFechaFinal = (data) =>{
      setFechafinal(data)
    }
    const HanlderCambioEstatus = (data) => {
      setEstatus(data)
    }
    const HanlderCambioCiudadReporte = (data) => {
      setCiudadSeleccionada(data)
    }
    const HanlderCambioIdCiudadSeleccionada = (data) => {
      setIdciudadSeleccionada(data)
    }

    return {
        Estatus,
        FechaInicial,
        Fechafinal,
        handlerCambioFechaInicial,
        HanlderCambioFechaFinal,
        HanlderCambioEstatus,
        CiudadSeleccionada,
        HanlderCambioCiudadReporte,
        HanlderCambioIdCiudadSeleccionada,
        IdciudadSeleccionada
  }
}

export default useReportePeticionesFinalizadas