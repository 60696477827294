import React, { useState, useEffect } from 'react'
import userimgnav from '../../assets/imagenes/usernavbar.webp'
import LogoutIcon from '../../assets/iconos/Logout'
import Bellh from '../../assets/iconos/Bellh'
import { useAuth } from '../../context/authcontext'
import { toast } from 'react-toastify';
import Notification from '../../utils/hooks/useNoti'
import UsePeticiones from '../hooks/UsePeticiones'
import { HeaderLayout } from '../../theme/Layout'
import flechaabajo from '../../assets/iconos/flechaabajoselect.webp';
import useCiudades from '../hooks/useCiudades'
import Permisos from '../../utils/permisos'
import InputComponent from '../../utils/Components/Inputs/InputBuscador'
import useDirecciones from '../hooks/useDirecciones'
import { useNavigate } from 'react-router-dom'
const NavbarLarge = (props) => {
  const{UsuariosPermisoSuc}=Permisos();
  const [resolution, setResolution] = useState({ width: 0, height: 0 });
  const { Ciudades, CargarCiudades } = useCiudades()
  const { LoadAlertas, Alertas } = UsePeticiones()
  const [isactive, setisactive] = useState(false)
  const { user, logout, HandlerCambioCiudad } = useAuth()
  const navigate = useNavigate();


  const HanlderActivesubmenu = () => {
    if (isactive === true) {
      setisactive(false)
    } else {
      setisactive(true)
    }
  }
  useEffect(() => {
    function updateResolution() {
      setResolution({ width: window.innerWidth, height: window.innerHeight });
    }

    // Agregar un event listener para actualizar la resolución cuando cambie el tamaño de la ventana
    window.addEventListener('resize', updateResolution);

    // Obtener la resolución inicial al cargar el componente
    updateResolution();

    // Limpieza: remover el event listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', updateResolution);
    };
  }, []);
  useEffect(() => {
    if (!Alertas) {
      LoadAlertas()
    }
  }, [Alertas, LoadAlertas])
  useEffect(() => {
    if (!Ciudades) {
      CargarCiudades()
    }
  }, [Ciudades, CargarCiudades])


  const handleButtonClick = () => {
    // Muestra una notificación cuando se hace clic en el botón
    toast.success('¡Esta es una notificación!', {
      position: toast.POSITION.TOP_RIGHT,
      // También puedes agregar más opciones aquí si es necesario
    });
  };


  const HanlderCambioCiudad = async(e) => {
    const Arrayporciudad =  Ciudades?.filter(datos => datos.nombre == e.target.value);
   await HandlerCambioCiudad(Arrayporciudad[0])
    window.location.reload()
    // navigate("/mapaciudad")
  }

  const SelectInput = () =>{
   // const Arrayporciudad =  Ciudades?.filter(datos => datos.id == e.target.value);
    return(
      <>
     
      <select
      value={JSON.parse(localStorage.getItem("Ciudad")).nombreciudad}
      onChange={(e) => HanlderCambioCiudad(e)}
      disabled={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? false : true}
      style={{
        backgroundImage: flechaabajo,
        cursor: 'pointer'
      }}
      name="selectedFruit">
      {
        Ciudades?.map(res => {
         
            if(user.id == 658 || user.id == 119){
              return(
                 res.id == 8  || res.id == 4  || res.id == 2 || res.id === user.idciudad ?
                 <option value={res.nombre} key={res.id}>{res.nombre}</option>
                 :
                 <>
                 </>
              )
            }
          return (
            <option value={res.nombre} key={res.id}>{res.nombre}</option>
          )
        })
      }
    </select>
      </>
    )
  }
  return (
    <HeaderLayout
      colormode={props.colormode}
      selectimage={flechaabajo}

    >
      <div className={props.activemenu === true ? 'NavbarDesk' : 'NavbarDesk'}  >
        <div className='containernavbar' style={resolution.width < 760 ? {
          display:'flex',
          width: '100%',
          borderRadius: 0,
          position: 'sticky',
          paddingBottom:10,
          alignContent:'center',
          alignItems:'center',
          paddingTop:10,
          justifyContent:'flex-end',
          background: '#151B4E',
          boxShadow: '2px 2px 8px #000',
          } : {display:'block'}}>
          <div className="bodynavbar">

            <div className='containerNavbarizquierdo' style={resolution.width < 760 ? {display:'none'} : {display:'flex'}}>
              <div className='ContainerCiudadSelect'>
                <p>Ciudad: </p>
                {
                 SelectInput()
                }
              </div>
              <div className='Nombrepagina'>
                <p>{localStorage.getItem("lastmenu").toUpperCase()}</p>
              </div>
            </div>

            <div className='containerNavbarderecho'>

              <div className='Containerbellheader'>
                <div>
                  <Bellh
                    color={resolution.width < 760 ? "#fff" : "#fff"}
                    Alertas={Alertas?.length > 0 ? true : false}
                  ></Bellh>
                  {
                    Alertas?.length > 0 ?
                      <>
                        <span className='Containerbellheader-Contador'>
                          {Alertas?.length}
                        </span>
                      </>
                      :
                      <>

                      </>
                  }

                </div>
              </div>
              <div className='conatinerusernav' style={resolution.width < 760 ? {display:'none'} : {display:'block'}}>
                <img src={userimgnav} alt="" onClick={() => HanlderActivesubmenu()} />
                <div style={{
                  opacity: isactive === true ? 1 : 0
                }} className='containernavmenu'>
                  <ul style={isactive === false ? { display: 'none' } : { display: 'block' }}>
                    <li>
                      <div className='containerCerrarsesionnavbar'>
                        <p>Perfil</p>
                      </div>
                    </li>
                    <li>
                      <div className='containerCerrarsesionnavbar' onClick={() => logout()}>
                        <p>Cerrar sesión</p>
                        <div className='containericonlogout'>
                          <LogoutIcon></LogoutIcon>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
                {/* MOVIL */}
              <div onClick={() => props.HanlderActiveMenu()} style={resolution.width < 760 ? {display:'block',cursor: 'pointer'} : {display:'none'}} className='containerHamburger'>
                  <span></span>
                  <span></span>
                  <span></span>
              </div>
            </div>
          </div>
        </div>
        <Notification />
      </div>
    </HeaderLayout>
  )
}

export default NavbarLarge