import React from 'react'
import Modal from 'react-modal';
import { ModalLayout } from '../../../theme/Layout';
import useMode from '../../hooks/useMode';

const ModalComponent = (props) =>{
  const{Darkmode,AzulFuerteLight,Blanco}=useMode()


    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          marginRight: '-5%',
            transform: 'translate(-50%, -50%)',
            background: '#F0F1F3',
            borderRadius: '18px',
            boxShadow: '0 5px 5px rgba(182, 182, 182, 0.75)',
            width: '70%', // puede ser props
            maxHeight: '90%', // puede ser props
          zIndex: '999'
        },
      };

  return (
    <Modal
        isOpen={props.Nombremodal}
        style={customStyles}
    >
      <ModalLayout
        colorHeader={Darkmode === false ? AzulFuerteLight : 'red'}
        Blanco={Darkmode === false ? Blanco : 'red'}
      >
        <div className='customModal'>
            <div className='Header-Modal'>
               <div className="titulo-modal">
                  <h3>{props.Titulo}</h3>
               </div>
               <div onClick={() => props.Cerrarmodal()} className="cerrarmodal">
                    <p>X</p>
               </div>
            </div>
            <div className='Body-modal'>
                {props.Contenido()}
            </div>
        </div>
      </ModalLayout>
    </Modal>
  )
}

export default ModalComponent