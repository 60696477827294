import React from 'react'
import { PDFViewer } from '@react-pdf/renderer';
import GananciaPDF from '../components/PDF/GananciaPDF';
const Ejemplo = () => {
  return (
    <PDFViewer style={{width: '100%', height: "100vh"}}>
          <GananciaPDF 
            TotalMesanterior="4520"
          ></GananciaPDF>
        </PDFViewer>
  )
}

export default Ejemplo