import React, { useState, useEffect } from 'react'
import { MapaMonitorLayout } from '../theme/Layout'
import Loader from '../utils/Components/Loader/Loader'
import MapaMonitor from '../components/Mapa/MapaMonitor'
import RecoLibre from '../assets/iconos/RecolectorLibre.svg'
import RecoReco from '../assets/iconos/RecolectorReco.svg'
import RecoDocu from '../assets/iconos/RecolectorDocu.svg'
import PeticionIcon from '../assets/iconos/locationpetition.svg'
import PeticionIconR from '../assets/iconos/locationpetitionR.svg'
import PeticionIconD from '../assets/iconos/locationpetitionD.svg'
import PeticionIconP from '../assets/iconos/locationpetitionP.svg'
import RecoAusente from '../assets/iconos/RecolectorAusente.svg'
import SucrusalIcon from '../assets/imagenes/sucursalnvo.webp'
import FlechaPeticiones from '../assets/iconos/flechaAbrir.svg'
import FlechaPeticionesOff from '../assets/iconos/flechaCerrar.svg'
import { useAuth } from '../context/authcontext'
import WidgetReco from '../components/Mapa/WidgetReco'
import UsePeticiones from '../components/hooks/UsePeticiones'
import DetallesREco from '../components/Mapa/DetallesREco'
import UseDirections from '../components/Mapa/hooks/UseDirections'
import useGetZona from '../components/Mapa/hooks/useGetZona'
import useSucursalesActivas from '../components/hooks/useSucursalesActivas'
import moment from 'moment'
import PeticionesPendientes from '../components/Mapa/PeticionesPendientes'
import UseMapaPrueba from '../components/Mapa/hooks/UseMapaPrueba'
import Mapaprueba from '../components/Mapa/mapaprueba'

const MapaMonitoreo = () => {
  let dimension = window.innerWidth;
  const{CargarSucursales,SucursalesData}=useSucursalesActivas()
  const{LoadZonasPorCiudad,DataZonaUsuario}=useGetZona()
  const [FechAactual, setFechAactual] = useState(moment().format('YYYY-MM-DD'))
  const { socket, RecolectoresMapa, HandleEditReco } = useAuth()
  const {LoadPeticionesReco,PeticionesRecolectando,PeticionesDocumenando,PeticionesActivas,LoadPeticiones,PeticionesMapa}=UsePeticiones()
  const [RecolectorSelecionado, setRecolectorSelecionado] = useState()
  const{GetRoutes,Ruta,LimpiarRuta}=UseDirections();
  const [RutaEncurso, setRutaEncurso] = useState();
  const [AbrirPeticionesPendientes, setAbrirPeticionesPendientes] = useState(false);
  const [Peticionespendientes, setPeticionespendientes] = useState([])
  const HandlerAbrirPeticionesPendientes = () => {
    if (AbrirPeticionesPendientes === true) {
      setAbrirPeticionesPendientes (false)
    } else {
      setAbrirPeticionesPendientes(true)
    }
  }
useEffect(() => {
 if (PeticionesMapa != undefined ) {
  const data = PeticionesMapa.filter(datos => datos.estatus === "P")
  setPeticionespendientes(data)
 }
}, [PeticionesMapa])

  const HandlerRutaEncurso = async(data) =>{
    await setRutaEncurso(data)
  }

  useEffect(() => {
    if(RecolectorSelecionado){
    
    }else{
      LimpiarRuta()
    }
  }, [RecolectorSelecionado])
  

  useEffect(() => {
    if(PeticionesMapa === undefined){
      setTimeout(() => {
        LoadPeticiones(FechAactual,JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        LoadZonasPorCiudad()
        CargarSucursales()
      }, 2000);
    }
  }, [PeticionesMapa])
  
  const SeleccionarReco = (data) =>{
    if(data.id === RecolectorSelecionado?.id){
      setRecolectorSelecionado(undefined)
    }else{
      setRecolectorSelecionado(data)
      LoadPeticionesReco(data.id,JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    }
  }
  console.log(RecolectoresMapa, "RECO MAP")



  return (
    <MapaMonitorLayout
      imgcar={RecoLibre}
      imgcarD={RecoDocu}
      imgcarR={RecoReco}
      imgcarI={RecoAusente}
      imgpetition={PeticionIcon}
      imgpetitionP={PeticionIconP}
      imgpetitionR={PeticionIconR}
      imgpetitionD={PeticionIconD}
      SucrusalIcon={SucrusalIcon}
    >
      {
        
        Object.entries(RecolectoresMapa).length === 0 ?
        <>
         <div style={{display: 'flex', justifyContent: 'center' , width: '100%', alignItems: 'center'}}>
               <Loader
                title="Cargando mapa"
               ></Loader>
            </div>
        </>
        :
          <>
          <div className="containerMonitoreo" style={{position:'relative'}}>
            <div className='ContainerMapaCiudadReco'>
              <div className='containermonitormap'>
              <MapaMonitor
                Recolectores={RecolectoresMapa}
                socket={socket}
                AccionEditarReco={HandleEditReco}
                RecolectorSelecionado={RecolectorSelecionado}
                accionCargarReco={SeleccionarReco}
                PeticionesMapa={PeticionesMapa}
                RutaREco={Ruta}
                RutaEncurso={RutaEncurso}
                Datazona={DataZonaUsuario}
               Zoom="10"
               Sucursales={SucursalesData}
               TipoMapa="City"
              ></MapaMonitor>
              {/* <Mapaprueba
                Zoom="10"
              ></Mapaprueba> */}
              <div style={{
                position: 'absolute',
                top: 5,
                right: RecolectorSelecionado ? '24%' : 5,
                cursor:'pointer',
                transition: '1s',
                zIndex:'5'
              }}>
                {
                  RecolectoresMapa?.map(resReco => {
                    if(resReco.cityId == JSON.parse(localStorage.getItem("Ciudad")).idciudad)
                    return (
                      <WidgetReco
                        data={resReco}
                        RecolectorSelecionado={RecolectorSelecionado}
                        accionCargarReco={SeleccionarReco}
                      ></WidgetReco>
                    )
                  })
                }
              </div>
            {/* <div style={Peticionespendientes.length > 0 ? AbrirPeticionesPendientes === false ? {bottom: '10px'} : dimension <= 1400 ? {top: '60px'} : {top:'160px'} :{display:'none'}} className='contenedorPeticionPendiente'> */}
            <div className={Peticionespendientes.length >= 0 ? AbrirPeticionesPendientes === false ? 'contenedorPeticionPendiente' : 'contenedorPeticionPendienteActiv' : ""}>
                <div style={Peticionespendientes === "" || PeticionesMapa === undefined || Peticionespendientes.length === 0 ? {display:'none'} : {display:'flex', justifyContent:'center', width:'100%'}}>
                <div style={AbrirPeticionesPendientes === true ? {animation:'none'} : {marginTop: '0px'}} onClick={() => HandlerAbrirPeticionesPendientes()} className='PeticionPendiente'>
                  <div className='flechaPendientes'>
                    <img src={AbrirPeticionesPendientes === true ? FlechaPeticiones : FlechaPeticionesOff} alt="" />
                  </div>
                  <p>Peticiones pendientes</p>
                </div>
                </div>
              </div>
              <div style={ Peticionespendientes.length > 0 ? AbrirPeticionesPendientes === true ? {position: 'absolute', bottom: '10px', zIndex: 2} : {height:'0vh', position: 'absolute',bottom: '4px', zIndex: 2} : {display:'none'}} className='TablaPeticionesPendientes'>
            <PeticionesPendientes
            PeticionesMapa={PeticionesMapa}
            CargaPeticiones={LoadPeticiones}
            ></PeticionesPendientes>
            </div>
              </div>
                  <div style={RecolectorSelecionado ? {opacity: 1,position:'absolute', right:'2%', height:'100vh', top:20} : {opacity:0, width:0,position:'absolute', right:0, height:'100vh'}} className='ContainerDetallesRECO'>
                  <DetallesREco
                  RecolectorSelecionado={RecolectorSelecionado}
                  PeticionesRecolectando={PeticionesRecolectando}
                  PeticionesDocumenando={PeticionesDocumenando}
                  PeticionesActivas={PeticionesActivas}
                  AccionCargaRuta={GetRoutes}
                  Ruta={Ruta}
                  HandlerRutaEncurso={HandlerRutaEncurso}
                  ></DetallesREco>
                    
                  </div>
            </div>
            </div>
          </>
         
      }
    </MapaMonitorLayout>
  )
}

export default MapaMonitoreo