import React from 'react'
import UsemapMonitor from './hooks/UsemapMonitor'

const MapaMonitor = (props) => {
    const{setRef}=UsemapMonitor(props.Recolectores,props.socket,props.Accionguardarcordenada,props.AccionEditarReco,props.RecolectorSelecionado,props.accionCargarReco,props.PeticionesMapa,props.RutaREco,props.RutaEncurso,props.Datazona,props.Zoom,props.Sucursales,props.GeoJsonZona,props.TipoMapa)
  return (
    
     <div
    ref={setRef}
    className="NewMapMonitor"
    />
   
  )
}

export default MapaMonitor