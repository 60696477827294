import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';



ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const GraficaLinea = (props) => {
    const sucursales = props.MesActual?.map((sucursal) => sucursal.sucursal);
    const ventasMesAnterior = props.MesAnterior?.map((sucursal) => sucursal.ventas);
    const ventasMesActual = props.MesActual?.map((sucursal) => sucursal.ventas);
    const datas = ["MesActual","MesAnterior"]

    const chartData = {
        labels: sucursales,
        datasets: [
          {
            label: 'Octubre',
            data: ventasMesAnterior,
            pointStyle: 'none',
            pointRadius: 0,
            backgroundColor: '#151b4e',
            borderColor: '#151b4e',
          },
          {
            label: 'Noviembre',
            data: ventasMesActual,
            pointBackgroundColor: function(context) {
             if(ventasMesActual != undefined){
              const IndexGlobal = context.dataIndex;
              const DataActual = ventasMesActual[IndexGlobal];
              const DataAnterior = ventasMesAnterior[IndexGlobal]
             return DataActual > DataAnterior ? 'green' : 'red'
             }
          },
            pointRadius: 6,
            backgroundColor: '##6a6a6a',
            borderColor: '#6a6a6a',
          },
        ],
      };
    
      const options = {
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
          },
        },
      };
    
  return (
    <div>
    <Line data={chartData} options={options} height={props.resolution.width <= 780 ? 240 : 80} />
  </div>
  )
}

export default GraficaLinea