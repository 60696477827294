import React from 'react'
import { WidgetDashboarLayout } from '../../theme/Layout'
const WidgetPeticion = (props) =>{
  return (
    <WidgetDashboarLayout
        Fondocolor={props.color}
    >
 <div className='ConatainerWidgetPeticiones'>
        <div className='ContainerbkCardWidget'>
            <img src={props.icono}/>
        </div>
        <div className='ContainerWidget-Info'>
            <p>{props.Titulo}</p>
            <div className='ContainerDataWidget'>
                <p>{props.data}</p>
            </div>
        </div>
    </div>
    </WidgetDashboarLayout>
  )
}

export default WidgetPeticion