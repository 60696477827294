// import React,{useState} from 'react'
// import moment from 'moment'
// import { CalendarioLayout } from '../theme/Layout'

// const Calendario = () => {
//     const [AñoActual, setAñoActual] = useState(moment().format("YYYY"))
//   return (
//     <div id='ContenedorPrincipal'>
//       <CalendarioLayout>
//         <p className='TitleCalendario'>Configuracion del año {AñoActual}</p>
//       </CalendarioLayout>
//     </div>
//   )
// }

// export default Calendario

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { CalendarioLayout } from '../theme/Layout'
import CalendarioComponent from '../components/Configuraciones/CalendarioComponent'
import useModal from '../utils/Components/modal/usemodal'
import ModalComponent from '../utils/Components/modal/ModalComponent'
import ModalComponentMini from '../utils/Components/modal/ModalComponentMini'
import useCalendario from '../components/Configuraciones/hooks/useCalendario'
import InputComponent from '../utils/Components/Inputs/InputBuscador'
import NuevoIcon from '../assets/iconos/NuevoIcon'


const Calendario = () => {
  const { ModalCalendario, AbrirModalCalendario, CerrarModalCalendario } = useModal()
  const [AñoActual, setAñoActual] = useState(moment().format("YYYY"))
  const diasSemana = ["lunes", "martes", "miércoles", "jueves", "viernes", "sábado", "domingo"];
  const { HanlderGenerarCalendario, calendario, handerGetCalendario, SeleccionarMes, SeleccionarDia, hanlderSelectMes, hanlderSelectDia, HanlderCambioFecha, FechaBuscador, } = useCalendario()

  useEffect(() => {
    if (calendario === undefined || calendario.length === 0) {
      handerGetCalendario()
    }
  }, [calendario])

  const handerFunction = () => {
    if (ModalCalendario == true) {

      CerrarModalCalendario()
    } else {
      AbrirModalCalendario()

    }
  }
  const HanlderCambioFechaFiltro = async (e) => {
    let fehca = moment(e).format('YYYY-MM-DD')

    await HanlderCambioFecha(fehca)

  }
  const renderItemdias = () => {
    return (
      <CalendarioLayout>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '80%', margin:'auto' }}>
          <div className='containerDiasTitle'>
            <div className="generalInfoShow" style={{ width: '60%' }}>
              <p>Medios días totales:</p>
              {/* <p style={{ paddingLeft: '8px' }}></p> */}
            </div>
            <div className="generalInfoShow" style={{ width: '60%' }}>
              <p>Días inhábiles:</p>
              {/* <p style={{ paddingLeft: '8px' }}></p> */}
            </div>
          </div>
          <div className='ContainerFechasCalendarioP'>
            <div className='ContainerBodyFechasCalendario'>
              <div className='ContainerFechasCalendario'>
                <InputComponent
                  nombre={"Agregar medio dia:"}
                  tipo={"Date"}
                  flex={1}
                  value={FechaBuscador}
                  Accion={HanlderCambioFechaFiltro}
                />
                <div className='iconnuevo-peticion CalendarioiconNuevo'>
                  <NuevoIcon width={40} height={48}></NuevoIcon>
                </div>
              </div>
              <div className='ContainerFechasCalendario'>
                <InputComponent
                  nombre={"Agregar dia no laboral:"}
                  tipo={"Date"}
                  flex={1}
                  value={FechaBuscador}
                  Accion={HanlderCambioFechaFiltro}
                />
                <div className='iconnuevo-peticion CalendarioiconNuevo'>
                  <NuevoIcon width={40} height={44}></NuevoIcon>
                </div>
              </div>

            </div>
          </div>
        </div>
      </CalendarioLayout>
    )
  }
  return (
    <div id='ContenedorPrincipal'>
      <CalendarioLayout>
        <p onClick={() => handerFunction()} className='TitleCalendario'
          style={{
            alignItems: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#2f3768',
            paddingLeft: '2px'
          }}>Configuracion del año {AñoActual} {SeleccionarMes}</p>

        <CalendarioComponent
          ModalKey={ModalCalendario}
          openModal={AbrirModalCalendario}
          closeModal={handerFunction}
          diasSemana={diasSemana}
          calendario={calendario}
          seleccionarMes={hanlderSelectMes}
        ></CalendarioComponent>
      </CalendarioLayout>


      <ModalComponent
        Nombremodal={ModalCalendario}
        Cerrarmodal={handerFunction}
        Titulo={SeleccionarMes?.toUpperCase()}
        Contenido={renderItemdias}
      ></ModalComponent>
    </div>
  )
}

export default Calendario