import React from 'react'

const Flechadoble =(props) => {
    return (
        <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 2L2 13L10 24" stroke={props.color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 2L12 13L20 24" stroke={props.color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Flechadoble