import React from 'react'
import InputComponent from '../../../utils/Components/Inputs/InputBuscador'
import { ModalLayout } from '../../../theme/Layout'
import imgExcel from '../../../assets/iconos/excel.svg'

const ReportePeticionesFinalizadas = (props) => {

  return (
    <div className='ContainerReportes'>
      <div className='tituloRep'>
        <h1>Peticiones realizadas</h1>
      </div>
      <div className="contenedorInputReportes">
        <div className='contenedorTipoRep'>
        <div className='ContenedorTipo'>
        <InputComponent
          nombre="Tipo de reporte:"
          tipo="Select"
          flex={1}
          datos={props.tipoPeticion}
        ></InputComponent>
      </div>
      {/* <div style={{display: 'flex',justifyContent: 'flex-end', width: '60%'}}>
      <div className='ContenedorCuerpo' style={{width: '80%', paddingLeft:'10%'}}> */}
            <div className='fechas'>
        <div className='ContenedorFecha'>
        <InputComponent
          nombre={"Fecha inicio:"}
          tipo={"Date"}
          flex={1}
          value={props.FechaInicio}
          Accion={props.AccionCambioFechaInicio}
        ></InputComponent>
        </div>
        <div className='ContenedorFecha' style={{marginRight: '-11px'}}>
        <InputComponent
          nombre={"Fecha final:"}
          tipo={"Date"}
          value={props.FechaFinal}
          flex={1}
          Accion={props.AccionCambioFechaFinal}
        ></InputComponent>
      </div>
      </div>
        </div>
        <div className='contenedorFiltro'>
        <div className='ContenedorCuerpo'>
        <InputComponent
          nombre="Ciudad:"
          tipo="Select"
          flex={1}
          datos={props.Ciudades}
          Accion={props.AccionCambioCiudad}
          value={props.ciudad}
        ></InputComponent>
      </div>
        <div className='ContenedorCuerpo'>
        <InputComponent
          nombre="Estatus:"
          tipo="Select"
          flex={1}
          datos={props.Datos}
          Accion={props.AccionCambioEstatus}
          // value={props.Estatus}
        >
        </InputComponent>
      </div>
        </div>
      <div>
        <ModalLayout>
            <div style={{ marginTop: '30px', paddingBottom:'10px' }} className='ContainerCheckAddressButton'>
              <div className='Boton_Aceptar'>
                {/* <button onClick={() => props.GenerarReporte(props.FechaInicio,props.FechaFinal,props.Estatus)} >Aceptar</button> */}
                <button style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection:'row-reverse',
                  fontSize:'16px',
                  width: '155px',
                  paddingTop: '7px',
                  paddingBottom: '7px'
                }}
                  onClick={() => props.GenerarReporte(props.FechaInicio, props.FechaFinal, props.IDCiudad, props.Estatus)} >Generar
                  <img style={{ width: '27px', paddingRight:'10px' }} src={imgExcel} alt="" />
                </button>
              </div>
            </div>
        </ModalLayout>
      </div>
    </div>
    </div>
  )
}

export default ReportePeticionesFinalizadas