import React from 'react'
import { TablaLayout } from '../../../../theme/Layout';
import useMode from '../../../hooks/useMode';
import primero from '../../../../assets/iconos/primero.svg'
import segundo from '../../../../assets/iconos/segundo.svg';
import penultimo from '../../../../assets/iconos/penultimo.svg';
import ultimo from '../../../../assets/iconos/ultimo.svg';
import InputComponent from '../../Inputs/InputBuscador';


const TablaPaginador = (props) => {

  const { Darkmode } = useMode()

  return (
    <div>
      <TablaLayout

        BkHeader={Darkmode === false ? '#5B5F83' : 'red'}
      >
        <div className='ContainerTablaBodyP'
          ref={props.identificador}
        >
          <div style={props.modal == true ? { display: 'none' } : { background: 'transparent', boxShadow: '-1px 9px 1px rgba(0, 0, 0, 0.35)' }}>
            <h3>{props.Titulo}</h3>
          </div>
          <div className='Cabezera-TablaPaginador'>{props?.Cabezera()}</div>
          <div className='Cuerpoprincipal' >{props?.Cuerpo()}</div>
        </div>
        <div className="contenedorPaginador">
          <div>
            {/* <InputComponent
              nombre={"Estatus:"}
              tipo={"Select"}
              flex={1}
            ></InputComponent> */}
            <div style={{ display: 'flex', flexDirection: 'row', height: '45px' }}>
              <p>Registros:</p>
              <select 
              onChange={(e) => props.CambiarRegistros(e.target.value)}
              style={{ background: '#fff', border: 'solid', borderColor: '#757575', borderWidth: '1px', marginTop: '8px', marginLeft: '5px', borderRadius: '8px' }} name="" id="">
                {
                  props.RegistrosTotales?.map(res => {
                    return(
                      <option value={res}>{res}</option>
                    )
                  })
                }
              </select></div>
          </div>
          <div className="paginador">
            <div className='flecha'>
              <img src={primero} alt="" />
            </div>
            <div className="flecha">
              <img src={segundo} alt="" onClick={() => props.Accionprev(1)} />
            </div>
            <div className='num'>
              <p>{props.pagina} / {props.totalpaginas}</p>
            </div>
            <div className="flecha">
              <img src={penultimo} alt="" onClick={() => props.Accionnext(1)} />
            </div>
            <div className="flecha">
              <img src={ultimo} alt="" />
            </div>
          </div>
        </div>
      </TablaLayout>

    </div>
  )
}

export default TablaPaginador