import React,{useState} from 'react'
import axios from 'axios'
import config from '../../config/config'
const useDatosOnsite =() => {
    const [DatosOnsite, setDatosOnsite] = useState();
    const [DatosDetalles, setDatosDetalles] = useState([]);
    

    const HanlderUpdateDatosDetalles = (data) => {
        setDatosDetalles(data)
    }
    const HanlderAddDatosDelleMercancio = (data) => {
        setDatosDetalles(data)
    }
  
    const HandlerGetDatosOnsite = async(idciudad,idusuario) =>{
        let isMounted = true;
        const source = axios.CancelToken.source();
        const URLDATOSONSITE = `${config.API_Documentacion}Documentacion/pendingdocos/${idciudad}/${idusuario}`
        try {
            const ResponseDoc = await axios.get(URLDATOSONSITE, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
                },
            }
            )
          
            setDatosOnsite(ResponseDoc.data)
        } catch (error) {
                console.error(error)
        }
    }
    const HanlderObtenerDetallesOnsite = async(idciudad,idusuario,Datosid)=>{
        let isMounted = true;
        const source = axios.CancelToken.source();
        const URL_DATOS_DETALLENSITE = `${config.API_Documentacion}Documentacion/pendingdocdetos/${idciudad}/${idusuario}`
        try {
            if(isMounted){
                const ObtenerDetalles = await axios.post(URL_DATOS_DETALLENSITE,Datosid,{
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
                    },
                })

                setDatosDetalles(ObtenerDetalles.data)
            }
           
        } catch (error) {
            
        }
  
    }
    return {
        HandlerGetDatosOnsite,DatosOnsite,HanlderObtenerDetallesOnsite,DatosDetalles,HanlderUpdateDatosDetalles
  }
}

export default useDatosOnsite