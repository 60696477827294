import React from 'react'
import { Document,Page,Text,View,Image } from '@react-pdf/renderer'
import { useAuth } from '../../context/authcontext'

const GananciaPDF = (props) => {
  
  return (
    <Document>
        <Page size="A4">
            <View style={{backgroundColor:'#1C1D4D', width: '100%',display: 'flex', justifyContent: 'center', alignItems:'center',paddingTop:8,paddingBottom:8}}>
              <Text style={{color:'#fff', fontWeight:'bold', fontSize: 17}}>Reporte de ganancias</Text>
            </View>

            <View style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center',marginTop:10}}>
                <View style={{display:'flex', width:'80%',justifyContent:'space-between', alignItems:'center',flexDirection:'row'}}>
                  <View style={{display:'flex',justifyContent:'center',alignItems:'center', alignContent:'center'}}>
                    <Text>Ganancia mes anterior</Text>
                    <Text style={{textAlign:'center',display:'flex',}}>5,000</Text>
                  </View>
                  <View style={{display:'flex',justifyContent:'center',alignItems:'center', alignContent:'center'}}>
                    <Text>Ganancia mes actual</Text>
                    <Text style={{textAlign:'center',display:'flex',}}>4,000</Text>
                  </View>
                </View>
            </View>
        </Page>
    </Document>
  )
}

export default GananciaPDF