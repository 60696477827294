import { useRef, useState, useEffect, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import { v4 } from 'uuid';
import { Subject } from 'rxjs';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import axios from 'axios';
import * as turf from "@turf/turf";
// import API_URL from '../config';
// import { Datossuc } from './apis/get/datossuc';
mapboxgl.accessToken = 'pk.eyJ1IjoicGt0MWRldmVsb3BlciIsImEiOiJjbDZ6OXVjbzgwMmFrM3BsY3FweGkyYWJzIn0.r8Iu1ZteE0WwYt1LFfT1yg';

export const UseMapZonas = ( Recolectores, socket,guardarcoordenada, Editarreco, Recolectorselecionado, SeleccionarReco, PeticionesMapa, rutareco, rutaencurso, Datazona, Zoom,Sucursales ) => {
   
    let RecolectorSelected = Recolectorselecionado;
    // CORDENADA INICIAL DE MAPA
    const [puntoinicial, setpuntoinicial] = useState(JSON.parse(localStorage.getItem('coordenadamapa')));

    // VARIABLE ACTIVADO
    const [isTabActive, setIsTabActive] = useState(true);
    const [isInactive, setIsInactive] = useState(false);
    const [Activado, setActivado] = useState()
    // REFERENCIAS
    const mapaDiv = useRef();
    const SucursalesIcon= useRef({});
    const [cordsdata, setcordsdata] = useState()
    const [geoJsonZona, setgeoJsonZona] = useState()
    const [VistaAgregarNuevaZona, setVistaAgregarNuevaZona] = useState("false")


    const draw = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
        polygon: true,
        trash: true
        },
        defaultMode: 'draw_polygon'
        });

    const HAndlerSelectedReco = (data) => {

    }


    let inactivityTime = 30 * 60 * 1000; // = 30min.
    let timeoutId;

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsTabActive(!document.hidden);
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    const resetTimer = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(setInactive, inactivityTime);
    };
    const setInactive = () => {
        setIsInactive(true);
        window.location.reload()
        // EJECUTAR MODAL DE INACTIVIDAD Y REFRESH

    };
    useEffect(() => {
        document.addEventListener('mousemove', resetTimer);
        document.addEventListener('mousedown', resetTimer);
        document.addEventListener('keypress', resetTimer);
        document.addEventListener('touchmove', resetTimer);
        timeoutId = setTimeout(setInactive, inactivityTime);
        return () => {
            document.removeEventListener('mousemove', resetTimer);
            document.removeEventListener('mousedown', resetTimer);
            document.removeEventListener('keypress', resetTimer);
            document.removeEventListener('touchmove', resetTimer);
            clearTimeout(timeoutId);
        };
    }, []);

    // EFECTOS PRINCIPALES PARA INACTIVIDAD Y REFRESH
    // EFECTO QUE RENUDAR LA CONEXION SIMPRE Y CUANDO SE CAMBIE LA PESTAÑA
  

    // FUNCION PARA AÑADIR MAPÄ
    const mapa = useRef();
    const [ coords, setCoords ] = useState( puntoinicial );
    const [iszonaclick, setiszonaclick] = useState(false)
    const setRef = useCallback((node) => {
        mapaDiv.current = node;
    }, []);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapaDiv.current,
            style: 'mapbox://styles/mapbox/outdoors-v12?optimize=true',
            center: [-102.6012034945277, 24.007726083194417],
            zoom: Zoom,
            minZoom: Zoom,

        });
        mapa.current = map;

        return () => {
            if (mapa.current) {
                mapa.current.remove();
            }
          };
    }, []);
// }, [puntoinicial]);


    // AÑADIR RECOLECTORES AL MAPA
    const marcadores = useRef({});

    

  

    // CALL BACK QUE MONITOREA EL MOVIMIENTO
   

    // FUNCION PARA CAMBIO DE CSS DE RECO

    // EFECTO QUE ESCUCHA EL MOVIMIENTO DEL MARCADOR
  

    // PETICIONES
    const Peticiones = useRef({});
    const AgregarPeticion = (data) => {
      
        const elp = document.createElement('div');
        elp.className = `
            ${data.status === 'R'
                ?
                'MarcadorPeticionR'
                :
                data.status === 'D'
                    ?
                    'MarcadorPeticionD'
                    :
                    data.status === 'P'
                        ?
                        'MarcadorPeticionP'
                        :
                        'MarcadorPeticion'
            }`;

            const popupPeticionesReco2 = new mapboxgl.Popup({ offset: 25 }).setHTML(
                `
                <div class='ContainerCardRecoMapa'>
                <div class='ContainerImagenRecoMapaPETICION'>
                    <img src=${data.Origen === "ONSITE" ? 'https://web.pktuno.mx/IMG/onsite.png' : 'https://web.pktuno.mx/IMG/sucursal.png' } ></img>
                  </div>
                      <div class='ContainerTextoReco'>
                        <p>${data.cliente}</p>
                        <p>${data.direccion}</p>
                      </div>
                  </div>
                `
            );
        const marcadorpeticiones = new mapboxgl.Marker(elp);
        marcadorpeticiones.id = data.id
        marcadorpeticiones
            .setLngLat(data.cords)
            .addTo(mapa.current)
            .setPopup(popupPeticionesReco2)
        Peticiones.current[marcadorpeticiones.id] = marcadorpeticiones;
    }

    const AgregarSucursal = async(data) =>{
 
        const elp = document.createElement('div');
        elp.className = 'SucursalesIconMapa';
        const SucursalesIConMap = new mapboxgl.Marker(elp);
        SucursalesIConMap.id = data.id
        SucursalesIConMap
            .setLngLat([data.longitud,data.latitud])
            .addTo(mapa.current)
           
        SucursalesIcon.current[SucursalesIConMap.id] = SucursalesIConMap;
    }


    useEffect(() => {
        setTimeout(() => {
            let arraypeticiones = new Array;
            if (PeticionesMapa) {
                PeticionesMapa?.map((res, index) => {
                    if (!Peticiones.current[res.id]) {
                        if (res.estatus !== "T" && res.estatus !== "C") {
                            let dt = {
                                id: res.id,
                                cords: [res.coordenadas.longitude, res.coordenadas.latitude],
                                status: res.estatus,
                                Origen: res.origen,
                                idRecolectorAsignado: res.idusuarioasignado,
                                cliente: res.cliente,
                                direccion: res.direccion,
                                fecha: res.fecha
                            }
                            arraypeticiones.push(dt)
                        }
                        arraypeticiones.map(Peticionesex => {
                            if (!Peticiones.current[Peticionesex.id]) {
                                AgregarPeticion(Peticionesex, index)
                            }
                        })
                    }
                })
            }
        }, 1500);

    }, [PeticionesMapa, AgregarPeticion])


    useEffect(() => {
        setTimeout(() => {
            let ArraySucMapa = new Array;
            if (Sucursales) {
                Sucursales?.map((res, index) => {
                   if (!SucursalesIcon.current[res.id]) {
                    AgregarSucursal(res)
                    // let dt = {
                    //     id: res.id,
                    //     cords: [res.longitud, res.latitud],
                    //     nombre: res.nombre,
                    // }
                    // ArraySucMapa.push(dt)
                   }
                })
            }
        }, 1500);

    }, [Sucursales, AgregarSucursal])

    // EFECTO QUE ESCUCHA LAS ACTUALIZACIONES DE PETICION
    useEffect(() => {
        if (!socket) return;
        socket.on('UpdateStatusPetition', (data) => {
           
            actualizarPeticion(data)


        })
    }, [socket])

    // FUNCION PARA ACTUALIZAR UNA PETICION
    const actualizarPeticion = (datosg) => {

        switch (datosg.estatus) {
            case "P":
                Peticiones.current[datosg.id]._element.className = "MarcadorPeticionP mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
            case "A":
                Peticiones.current[datosg.id]._element.className = "MarcadorPeticion mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
            case "R":
                Peticiones.current[datosg.id]._element.className = "MarcadorPeticionR mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
            case "D":
                Peticiones.current[datosg.id]._element.className = "MarcadorPeticionD mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
            case "T":
                Peticiones.current[datosg.id].remove()
                break;
            case "C":
                Peticiones.current[datosg.id].remove()
                break;
            default:
                Peticiones.current[datosg.id]._element.className = "MarcadorPeticion mapboxgl-marker mapboxgl-marker-anchor-center"
                break;
        }
        // HandlerChangueColor(datosg.status, datosg.usuarioasignado)
        // HandleEditRecoPetitonCOLOR(datosg.usuarioasignado,datosg.estatus)
        // HandleEditRecoPetiton(datosg.usuarioasignado, datosg.status === "A" ? "L" : datosg.status, RecolectoresMapa === undefined ? test : RecolectoresMapa)
    }


    // RUTAS
    useEffect(() => {
        if (rutareco) {
            if (mapa.current.getLayer('LineString')) {
                mapa.current.removeLayer('LineString')
                mapa.current.removeSource('LineString')
            }
            const geojson = {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'LineString',
                            'properties': {},
                            'coordinates': rutareco.routes[0].geometry.coordinates
                        }
                    }
                ]
            };

            mapa.current.addSource('LineString', {
                'type': 'geojson',
                'data': geojson
            })
            mapa.current.addLayer({
                'id': 'LineString',
                'type': 'line',
                'source': 'LineString',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': rutaencurso.estatus === "A" ? '#3BAF2A' : rutaencurso.estatus === "R" ? '#FFB531' : rutaencurso.estatus === "D" ? '#35C1ED' : '#3BAF2A',
                    'line-width': 5
                }
            });

        }

    }, [rutareco])



    useEffect(() => {
        if (Recolectorselecionado) {

        } else {
            if (mapa.current.getLayer('LineString')) {
                mapa.current.removeLayer('LineString')
                mapa.current.removeSource('LineString')
            }
        }
    }, [rutareco, Recolectorselecionado, mapa.current])

 // Cuando se mueve el mapa
 useEffect(() => {

    mapa.current?.on('move', () => {
        const { lng, lat } = mapa.current.getCenter();
        setCoords({
            lng: lng.toFixed(4),
            lat: lat.toFixed(4),
            zoom: mapa.current.getZoom().toFixed(2)
        })
    });

},[]);

    // ZONAS

    useEffect(() => {
        let geocercadata;
        if (Datazona) {

            Datazona?.map(responsecords => {
                if (mapa.current.getSource(responsecords.observaciones)) {
                    mapa.current.removeLayer(responsecords.observaciones)
                    mapa.current.removeSource(responsecords.observaciones)
                }
                let Arraygeocerca = new Array(responsecords.cords)
                let ObjGeocerca = JSON.parse(Arraygeocerca[0]);
                const cordenadas = ObjGeocerca.map(resto => { return resto });
                geocercadata = turf.polygon(cordenadas);
                mapa.current.addSource(responsecords.observaciones, {
                    "type": "geojson",
                    "data": {
                        "type": "Feature",
                        "geometry": geocercadata.geometry
                    }
                })
                mapa.current?.addLayer({
                    'id': responsecords.observaciones,
                    'type': 'fill',
                    'source': responsecords.observaciones, // reference the data source
                    'paint': {
                        'fill-color': responsecords.color, // blue color fill
                        'fill-opacity': 0.5
                    },
                    'filter': ['==', '$type', 'Polygon']
                });
                mapa.current?.on('click', responsecords.observaciones, (e) => {
                    if (iszonaclick === false) {
                        setiszonaclick(true)
                    } else {
                        setiszonaclick(false)
                    }
                });
            })
        }
    }, [Datazona])

    // const ActivarBotonesNuevaZona = () => {
    //     if (VistaAgregarNuevaZona === true) {
    //         setVistaAgregarNuevaZona(false)

    //     }
    //    else{
    //     setVistaAgregarNuevaZona(true)
    // }
    // }
    useEffect(() => {
        mapa.current?.addControl(draw, 'top-right');
        mapa.current?.on('draw.create', updateArea);
        mapa.current?.on('draw.delete', updateArea);
        mapa.current?.on('draw.update', updateArea);
        // alert("ENTRA useeffect")
}, []); 
   
function updateArea(e) {
    setcordsdata(JSON.stringify(e.features[0].geometry.coordinates))
    guardarcoordenada(JSON.stringify(e.features[0].geometry.coordinates))
    const data = draw.getAll();
    const answer = document.getElementById('calculated-area');
    if (data.features.length > 0) {
    const area = turf.area(data);
    // Restrict the area to 2 decimal points.
    const rounded_area = Math.round(area * 100) / 100;
    } else {
    answer.innerHTML = '';
    if (e.type !== 'draw.delete')
    alert('Click the map to draw a polygon.');
    }
}

    // FUNCION DE POSISIONAR  RECO
    useEffect(() => {
        if (Recolectorselecionado) {
            mapa.current?.flyTo({ zoom: Recolectorselecionado.status != "L" || Recolectorselecionado.status != "I" ? 13 : 18, center: [Recolectorselecionado.lng, Recolectorselecionado.lat], })
        } else {
            mapa.current?.flyTo({ zoom: Zoom, center: [puntoinicial.lng, puntoinicial.lat], })
        }

    }, [Recolectorselecionado])



    return {
        setRef,
        cordsdata,
        // ActivarBotonesNuevaZona
        // geoJsonZona
    }
}
export default UseMapZonas