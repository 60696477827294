import React, {useState} from 'react'
import moment from 'moment'
import config from '../../../config/config'
import axios from 'axios'
const useReportes = () => {
  const [LoadingReportes, setLoadingReportes] = useState(false)
  const ReporteDePeticiones = async(fechainicio,fechafinal,ciudad,estatus) =>{
    setLoadingReportes(true)
        let isMounted = true;
        const source = axios.CancelToken.source();
        const URL_REPORTE_PETICIONES = `${config.API_REPORTES}Peticiones/Peticiones?FechaInicio=${moment(fechainicio).format('YYYY-MM-DD')}&FechaFin=${moment(fechafinal).format('YYYY-MM-DD')}&IdCiudad=${ciudad}&EstatusTerminado=${estatus}`
        try {
            const response = await axios.get(URL_REPORTE_PETICIONES, {
                responseType: 'blob', // Esto indica que quieres recibir la respuesta como un objeto Blob
              });
              if(isMounted){         
                const url = window.URL.createObjectURL(new Blob([response.data]));
                setLoadingReportes(false)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Reporte de Peticiónes.xlsx'); // Nombre de archivo deseado
                document.body.appendChild(link);
                link.click();
              }
        } catch (error) {
            console.log(error.response.message)
        }
    }
  
    return {
        ReporteDePeticiones,
        LoadingReportes
  }
}

export default useReportes