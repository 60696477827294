import React,{useState } from 'react'
import { format, getDaysInMonth, isSaturday } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import axios from 'axios';
import config from '../../../config/config';
import moment from 'moment';

const useCalendario = () => {
    const [calendario, setCalendario] = useState([]);
    const [Calnedario, setCalnedario] = useState([]);
    const [SeleccionarMes, setSeleccionarMes] = useState()
    const [SeleccionarDia, setSeleccionarDia] = useState()
    const [FechaBuscador, setFechaBuscador] = useState(moment().format('YYYY-MM-DD'))
    const [FechabuscadorDia, setFechabuscadorDia] = useState(moment().format('YYYY-MM-DD'))
    const HanlderGenerarCalendario = () => {
        const year = new Date().getFullYear();
        const calendar = [];
        for (let month = 1; month <= 12; month++) {
            const totalDaysInMonth = getDaysInMonth(new Date(year, month - 1));
            const monthName = format(new Date(year, month - 1, 1), 'MMMM', { locale: esLocale });
            const days = [];
            for (let day = 1; day <= totalDaysInMonth; day++) {
                const currentDate = new Date(year, month - 1, day);
                const formattedDate = format(currentDate, 'dd-MM-yyyy', { locale: esLocale });
                const dayName = format(currentDate, 'EEEE', { locale: esLocale });
                const isHalfDay = day <= 15 || isSaturday(currentDate); 
                const isSaturdayDay = isSaturday(currentDate);
                days.push({
                    date: formattedDate,
                    dayName,
                    isHalfDay,
                    isSaturday: isSaturdayDay,
                });
            }
            calendar.push({
                month: monthName,
                days,
            });
        }
        // setCalendario(calendar);
    };

    const HanlderCambioFecha = (data) => {
        setFechaBuscador(data)
      }
      const HanlderCambioFechaDia = (data) => {
        setFechabuscadorDia(data)
      }
    const handerGetCalendario = async() => {
        const URL_CALENDARIO = `${config.API_URL}Calendario/2024`

        try {
            const ResponseCalendario = await axios.get(URL_CALENDARIO)
            setCalendario(ResponseCalendario.data)
        } catch (error) {
            // console.log(error)
        }
    }
    const hanlderSelectMes = (data) => {
        setSeleccionarMes(data)
    }
    const hanlderSelectDia = (data) => {
        setSeleccionarDia(data)
    }

    return {
        HanlderGenerarCalendario,
        handerGetCalendario,
        calendario,
        hanlderSelectMes,
        hanlderSelectDia,
        SeleccionarMes,
        SeleccionarDia,
        HanlderCambioFecha,
        FechaBuscador,
        FechabuscadorDia,
        HanlderCambioFechaDia
    }
}

export default useCalendario