import React,{useState,useEffect} from 'react';
import moment from 'moment'
import { LoginLayout } from '../theme/Layout'
import Cardlogin from '../components/Login/Cardlogin';
import { useAuth } from '../context/authcontext';
import Loader from '../utils/Components/Loader/Loader';

function Login() {
    const [mesActual, setmesActual] = useState(moment().format("MM"))
    const loginChanenel = new BroadcastChannel('login');
    const{loading}= useAuth()
    useEffect(() => {
      loginChanenel.onmessage = () => {
        window.location.replace("/#/home");}
    }, [])

    return (
        <LoginLayout>
          {loading === true
          ?
          <>
            <div style={{display: 'flex', justifyContent: 'center' , width: '100%', alignItems: 'center'}}>
               <Loader
                title="Iniciando sesión"
               ></Loader>
            </div>
          </>

          :
          <div className="login-principal" style={
            {
              backgroundRepeat: 'no-repeat !important',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              background: mesActual === '1' ?
                `url('https://api.pktuno.mx//IMG/fondoa%C3%B1onvo.png')` :
                mesActual === '5' ? `url('https://api.pktuno.mx//IMG/fondoaniversario.png')` :
                  mesActual === '11' ? `url('https://api.pktuno.mx//IMG/fondopkt1.png')` :
                    mesActual === '12' ? `url('https://api.pktuno.mx//IMG/fondoNavidad.png')` :
                      `url('https://api.pktuno.mx//IMG/fondopkt1.png')`
            }
          }
          >
            <Cardlogin></Cardlogin>
        </div>
          }
            
        </LoginLayout>

    )
}

export default Login