import React from 'react'

const NuevoIcon = (props) =>{
  return (
    <svg display={props.display} width={props.width} height={props.height} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1771_1421)">
<circle cx="30" cy="30" r="30" fill="#151B4E"/>
<line x1="29.6001" y1="20" x2="29.6001" y2="40" stroke="white" stroke-width="4" stroke-linecap="round"/>
<line x1="40" y1="29.6001" x2="20" y2="29.6001" stroke="white" stroke-width="4" stroke-linecap="round"/>
</g>
<defs>
<clipPath id="clip0_1771_1421">
<rect width="60" height="60" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default NuevoIcon