import React from 'react'
import { TablaLayout } from '../../../../theme/Layout'
import useMode from '../../../hooks/useMode'
const TablaSinPaginador =(props)  =>{
  const{Darkmode,AzulFuerteLight}=useMode()

  return (
    <TablaLayout
      Azul={Darkmode === false ? AzulFuerteLight : 'red'}
    >
      <div className=''>
          <div style={props.modal== true ?{display:'none'} : {background: 'transparent'}}>
            <h3>{props.Titulo}</h3>
          </div>
      <div className='Cabezera-TablaSinPaginador'>{props.Cabezera()}</div>
      <div className='' style={{display: 'flex', width:'100%', flexDirection: 'column'}}>{props.Cuerpo()}</div>
      </div>
    </TablaLayout>
  )
}

export default TablaSinPaginador