import React,{useState,useEffect} from 'react'
import { HeaderLayout } from '../../theme/Layout'
import LogoSmall from '../../assets/iconos/LogoSmall.webp'
import Logo from '../../assets/iconos/logoverde.webp'
import flechaabajo from '../../assets/iconos/flechaabajoselect.webp'
import WaveHeader from '../../assets/imagenes/wave'
import { useAuth } from '../../context/authcontext'
import UsePeticiones from '../hooks/UsePeticiones'




const HederComponent = (props) => {
  const{user,resolution} =useAuth()
  return (
    <HeaderLayout
      colormode={props.colormode}
      selectimage={user?.id == "119" ? flechaabajo : ''}
    >
      <div className={resolution.width < 760 ? 'ConatinerHeaderMovil' : 'ConatinerHeader'}>
        <div className='ContainerLogoHeader' style={
          {
            flex: 1,
            // background: props.isDarkMode === true ? props.colormode : props.colormode,
            position: 'relative',
            overflow: 'hidden',
            paddingTop: props.activemenu === false ? 10 : '',
            paddingBottom: props.activemenu === false ? 8 : '',
            display: resolution.width < 760 ? 'none' : 'flex'
            // transition: 'all .7s ease-out'
          }
        }>
          <img 
          onClick={() => props.HanlderActiveMenu()}
          className={props.activemenu === true ? 'logopkt1HeaderP' : 'logopkt1Header'}
            src={props.activemenu === true ? LogoSmall : Logo} alt="" />
          <div className={props.activemenu === true ? 'containerwaveheadersmall' : 'containerwaveheader'}>
            <WaveHeader
              color={props.colormode}
            ></WaveHeader>
          </div>
        </div>

        <div style={ resolution.width < 760 ?{display:'flex', background:'red',width:'100%'} : {display:'none'}}>
          
        </div>
      
      </div>
    </HeaderLayout>
  )
}

export default HederComponent