import React from 'react'
import useErrorAlert from './useErrorAlert';
const useHttpsEstatus = () => {
    const { HanlderMensajeCorrecto,HanlderWarningMensaje } = useErrorAlert()
    const HanlderHttpEstatus = async (estatuscode, mensaje,tipo) => {
        switch (estatuscode) {
            case 200:
                if(tipo === 'Buscador'){

                } else{
                    await HanlderMensajeCorrecto('')
                }
                break;
            case 201:
                await HanlderMensajeCorrecto(mensaje)
                break;
            case 204:
                await HanlderMensajeCorrecto('Sin informacion')
                break;
            case 400:
                await HanlderMensajeCorrecto('Informacion invalido')
                break;
            case 401:
                await HanlderWarningMensaje('Es necesario refrescar la sesión')
                break;
            case 404:
                await HanlderMensajeCorrecto('Servicio no encontrado')
                break;
            case 500:
                await HanlderMensajeCorrecto('Server error')
                break;
            case 502:
                await HanlderMensajeCorrecto('Servicio no disponible')
                break;

        }
    }
    return {
        HanlderHttpEstatus
    }
}

export default useHttpsEstatus