import React,{useState} from 'react'
import axios from 'axios'

const UseDirections =() => {
    const KeyMap = 'pk.eyJ1IjoicGt0MWRldmVsb3BlciIsImEiOiJjbDZ6OXVjbzgwMmFrM3BsY3FweGkyYWJzIn0.r8Iu1ZteE0WwYt1LFfT1yg'
    const [Ruta, setData] = useState(null);
    const [error, setError] = useState(null);

    const LimpiarRuta = () =>{
      setData(undefined)
    }

    const GetRoutes = async(CordenadaOrigen,CordenadaDestino) => {
        let isMounted = true;
        const source = axios.CancelToken.source();
        let URL_RUTA = `https://api.mapbox.com/directions/v5/mapbox/driving/${CordenadaOrigen}%3B${CordenadaDestino}?alternatives=false&continue_straight=false&geometries=geojson&overview=full&steps=false&access_token=${KeyMap}`
        setTimeout(() => {
            source.cancel('Request timed out');
        }, 5000);
        try {
            const response = await axios.get(URL_RUTA, {
                cancelToken: source.token
              });
              
              if (isMounted) {
      
                setData(response.data)
              }
        } catch (error) {
            console.error(error)
        }
        // https://api.mapbox.com/directions/v5/mapbox/driving/-108.98872%2C25.791918%3B-108.978708%2C25.820214?alternatives=false&continue_straight=false&geometries=geojson&overview=full&steps=false&access_token=pk.eyJ1IjoicGt0MWRldmVsb3BlciIsImEiOiJjbDZ6OXVjbzgwMmFrM3BsY3FweGkyYWJzIn0.r8Iu1ZteE0WwYt1LFfT1yg
    }
    
  return {
    GetRoutes,
    Ruta,
    LimpiarRuta
  }
}

export default UseDirections