import React,{useState} from 'react'
import axios from 'axios'
import config from '../../config/config';


const useSucursalesActivas = () => {
    const [SucursalesData, setData] = useState(null);
    const [error, setError] = useState(null);

    const CargarSucursales = async() => {
        let isMounted = true;
        const source = axios.CancelToken.source();
        const url = `${config.API_URL}Sucursal/GetBranchesActive?page=1&registerbypage=1000&active=true`
        try {
            const ResponseSucursales = await axios.get(url, {
                cancelToken: source.token
              });
              
              if (isMounted) {
                setData(ResponseSucursales.data.resultado);
              } 
        } catch (error) {
            
        }
    }
    return{
        CargarSucursales,
        SucursalesData
    }
}

export default useSucursalesActivas