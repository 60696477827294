import React, {useState} from 'react'

export const UseAgregarReco = () => {
    const [recoGeo, setrecoGeo] = useState([]);
    const [selectRecoGeo, setselectRecoGeo] = useState()

    const HandlerSelectRecolectorZona2 = (id, user, indexs) => {
        const data = { id: id, nombre: user }

                if (JSON.stringify(recoGeo).indexOf(id) >=0 ) {
                    const removedreco = recoGeo.filter(reco => reco.id !== id)
                    setrecoGeo(removedreco)
                } 
                else{
                    setrecoGeo([...recoGeo,data])
                }
    }
 

    // const HandlerSelectRecolectorZona = (id, user, indexs) => {
    //     if ( selectRecoGeo === id){
    //         setselectRecoGeo()
    //     } else{
    //         setselectRecoGeo(id)
    //         HandlerRecolectorGeo(id, user, indexs)
    //     }
    // }


    // const HandlerRecolectorGeo = (id, user, indexs) => {
    //     const data = [
    //         {
    //             id: id,
    //             nombre: user
    //         }
    //     ]
    //     if (JSON.stringify(recoGeo).indexOf(id) >=0 ) {
    //         const remove = recoGeo.filter((emba, index) => index !== indexs)
    //         setrecoGeo(remove)
    //     } else{
    //         setrecoGeo([...recoGeo,data])
    //     }
    // }
  return {
    recoGeo, 
    selectRecoGeo,
    HandlerSelectRecolectorZona2

  }
}
