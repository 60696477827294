import React from 'react'
import AvatarReco from '../../assets/imagenes/AvatarInicio.webp'

const WidgetReco = (props) => {

    let nombre = props.data.name;
    var frg = nombre.split(" ");
    var result = frg[0];
  
  return (
    <div className={props.data.id == props.RecolectorSelecionado?.id ? 'ContainerRecoMonitoreoCiudadActive' :''} style={props.data.id == props.RecolectorSelecionado?.id ?{marginTop:0} : {marginBottom:30}}>
      
        <div onClick={() => props.accionCargarReco(props.data)} className='BurbujaReco' style={
        {
            display: props.data.id == props.RecolectorSelecionado?.id || props.RecolectorSelecionado === undefined ? 'block' : 'none',
            border: `3px solid 
                        ${props.data.status === "I" ? '#B40000' : 
                        props.data.status === "R" ? '#FFB531' : 
                        props.data.status === "D" ? '#35C1ED' : 
                        props.data.status === "L " ? '#3BAF29' : '#3BAF29'}`,
            animation: props.data.status === "I" ? `pulse 2s infinite` : ''
        }
        }>
            <img src={AvatarReco} />
            <p>{result}</p>
        </div>
    </div>
  )
}

export default WidgetReco