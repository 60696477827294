import React,{useEffect,useState} from 'react'
import { ReportesLayout } from '../theme/Layout'
import ReportePeticionesFinalizadas from '../components/Reportes/PeticionesFinalizadas/ReportePeticionesFinalizadas'
import useReportePeticionesFinalizadas from '../components/Reportes/PeticionesFinalizadas/hooks/useReportePeticionesFinalizadas'
import useReportes from '../components/Reportes/hooks/useReportes'
import UsePeticiones from '../components/hooks/UsePeticiones'
import useCiudades from '../components/hooks/useCiudades'
import moment from 'moment'
import Loader from '../utils/Components/Loader/Loader'
const Reportes = () => {  
  const{NuevaPeticion}=UsePeticiones()
  const{
    handlerCambioFechaInicial,
    FechaInicial,
    Fechafinal, 
    HanlderCambioFechaFinal,
    HanlderCambioEstatus,
    Estatus,
    CiudadSeleccionada,
    HanlderCambioCiudadReporte,
    HanlderCambioIdCiudadSeleccionada,
    IdciudadSeleccionada
  }=useReportePeticionesFinalizadas()
  const{ReporteDePeticiones, LoadingReportes}=useReportes()
  const{Ciudades, CargarCiudades}=useCiudades()
  const [LODER, setLODER] = useState(false)

  
  useEffect(() => {
    if (!Ciudades) {
      CargarCiudades()
    }
  }, [Ciudades, CargarCiudades])

  useEffect(() => {
    if(JSON.stringify(FechaInicial) == JSON.stringify(Fechafinal)){
      let newDate = new Date;
      const firstDayOfMonth = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
      handlerCambioFechaInicial(moment(firstDayOfMonth))
    }
  }, [FechaInicial,Fechafinal])

  const DatosEstatus = [
    {
      "value": "2",
      "nombre": "Todas"
    },
    {
      "value": "0",
      "nombre": "Finalización normal"
    },
    {
      "value": "1",
      "nombre": "Finalización manual"
    }]
  
    const TipoReporte = [
      {
        "value": "1",
        "nombre": "Peticiones realizadas"
      }]
  const CambioFechaInicio = (e) =>{

    let fehcai = moment(e).format('YYYY-MM-DD')
    handlerCambioFechaInicial(fehcai)
  }
  const CambioFechaFinal = (e) =>{
   
    let fehcaf = moment(e).format('YYYY-MM-DD')
    HanlderCambioFechaFinal(fehcaf)
  }
  const CambioEstatus = (e) =>{
   
    const data =  DatosEstatus?.filter(datos => datos.nombre == e);

    // handlerCambioFechaInicial(data[0]?.value)
      HanlderCambioEstatus(data[0]?.value)
  }
   const CambioCiudad = (e) => {
    const Arrayporciudad =  Ciudades?.filter(datos => datos.nombre == e);
  
    HanlderCambioCiudadReporte(Arrayporciudad[0].nombre)
    HanlderCambioIdCiudadSeleccionada(Arrayporciudad[0].id)
  }
  return (
    <ReportesLayout>
      {LoadingReportes === true ?
      <>
       <div style={{display: 'flex', justifyContent: 'center' , width: '100%', alignItems: 'center'}}>
               <Loader
                title="Generando reporte"
               ></Loader>
            </div>
      </>
      :
     <div style={{
      display:'flex',
      justifyContent: 'center',
      height: '100%'
     }}>
       <ReportePeticionesFinalizadas
      // Titulo="Reporte peticiones finalizadas"
      tipoPeticion={TipoReporte}
      FechaInicio={FechaInicial}
      FechaFinal={Fechafinal}
      AccionCambioFechaInicio={CambioFechaInicio}
      AccionCambioFechaFinal={CambioFechaFinal}
      Datos={DatosEstatus}
      AccionCambioEstatus={CambioEstatus}
      GenerarReporte={ReporteDePeticiones}
      Estatus={Estatus}
      // test={NuevaPeticion}   
      Ciudades={Ciudades}
      ciudad={CiudadSeleccionada}
      AccionCambioCiudad={CambioCiudad}
      IDCiudad={IdciudadSeleccionada}   
    >
    </ReportePeticionesFinalizadas>
     </div>
      }
    </ReportesLayout>
  )
}

export default Reportes