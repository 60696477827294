import React from 'react'
import userimgnav from '../../assets/imagenes/usernavbar.webp'
import VermasCardReco from '../../assets/iconos/VermasCardReco'
import Loader from '../../utils/Components/Loader/Loader'

const CardRecolectores = (props) => {

    return (
        <div className='containerCardRecolectoresDash'>
            <div className='ContainerCardRecolectores-Titulo'>
                <h4>Recolectores</h4>
            </div>
            {
                props.Loading ? 
                <>
                    <Loader
                    title="Recolectores"
                    ></Loader>
                </>
                :
                props.Recolectores?.map(res => {
                  
                    return (
                        <>
                            <div key={res.id} className='CardRecolectores' onClick={() => props.AccionCambiorecolector(res)}>
                                <div className="conatinerimagencardrecos">
                                    <img src={userimgnav} />
                                </div>
                                <div className='containerInfoCardReco' style={{flex:2,marginRight:'15px'}}>
                                    <p className='containerInfoCardReco-nombre'>{res.nombreusuarioasignado}</p>
                                    <p className='containerInfoCardReco-Peticion'>Petición:<span>15</span></p>
                                </div>
                                <div className='ContainerInfoCardRight' style={{flex:2}}>
                                    <div className='ContainerInfoCardRight-Flex'>
                                        <div className='ContainerInfoCardRight-Estatus'>

                                            <p>Conectado
                                                <span></span>
                                            </p>
                                        </div>
                                        <div className='ContainerInfoCardRight-BotonVerMAs'>
                                            <VermasCardReco></VermasCardReco>
                                        </div>
                                    </div>
                                    <div className='ContainerInfoCardRight-Zona'>
                                        <p>Zona 1</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
            }
        </div>
    )
}

export default CardRecolectores