import React, { useEffect, useState } from 'react'
import { DashboardLayout, MapaMonitorLayout } from '../theme/Layout'
import { useAuth } from '../context/authcontext'
import CardBienvenido from '../components/Dashboard/CardBienvenido'
import useMode from '../utils/hooks/useMode'
import UseRecolectores from '../components/hooks/UseRecolectores'
import CardRecolectores from '../components/Dashboard/CardRecolectores'
import WidgetPeticion from '../components/Dashboard/WidgetPeticion'
import IconoCaja from '../assets/iconos/IconoCaja.webp'
import UsePeticiones from '../components/hooks/UsePeticiones'
import Permisos from '../utils/permisos'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import MapaMonitor from '../components/Mapa/MapaMonitor'
import useSucursalesActivas from '../components/hooks/useSucursalesActivas'
import UseDirections from '../components/Mapa/hooks/UseDirections'
import useGetZona from '../components/Mapa/hooks/useGetZona'
import RecoLibre from '../assets/iconos/RecolectorLibre.svg'
import RecoReco from '../assets/iconos/RecolectorReco.svg'
import RecoDocu from '../assets/iconos/RecolectorDocu.svg'
import PeticionIcon from '../assets/iconos/locationpetition.svg'
import PeticionIconR from '../assets/iconos/locationpetitionR.svg'
import PeticionIconD from '../assets/iconos/locationpetitionD.svg'
import PeticionIconP from '../assets/iconos/locationpetitionP.svg'
import RecoAusente from '../assets/iconos/RecolectorAusente.svg'
import SucursalIcon from '../assets/imagenes/sucursalnvo.webp'
import WidgetReco from '../components/Mapa/WidgetReco'
import imgMapaNacional from '../assets/iconos/mapaPrincipal.png'

const Dashboard = () => {



  const Navegar = useNavigate()
  const { AzulFuerteLight, Darkmode, AzulBajoLight, GrisFuerteLight } = useMode()
  const { user } = useAuth();
  const { UsuariosPermisoSuc } = Permisos()
  // LLAMADA A PETICIONES
  const {
    // VARIABLES
    PeticionesPendientes,
    PeticionesTerminadas,
    PeticionesCanceladas,
    PeticionesActivas,
    PeticionesRecolectando, PeticionesDocumenando,
    LoadPeticiones, PeticionesMapa,
    // FUNCIONES
    LoadAlertas,
    LoadPeticionesReco,

  } = UsePeticiones();


  // MAPA
  const { LoadZonas, Datazona } = useGetZona()
  const { CargarSucursales, SucursalesData } = useSucursalesActivas()
  const [FechAactual, setFechAactual] = useState(moment().format('YYYY-MM-DD'))
  const { socket, RecolectoresMapa, HandleEditReco } = useAuth()
  const [RecolectorSelecionado, setRecolectorSelecionado] = useState()
  const { GetRoutes, Ruta, LimpiarRuta } = UseDirections();
  const [RutaEncurso, setRutaEncurso] = useState()

  const HandlerRutaEncurso = async (data) => {
    await setRutaEncurso(data)
  }

  useEffect(() => {
    if (RecolectorSelecionado) {

    } else {
      LimpiarRuta()
    }
  }, [RecolectorSelecionado])


  useEffect(() => {
    if (PeticionesMapa === undefined) {
      setTimeout(() => {
        LoadPeticiones(FechAactual, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        CargarSucursales()
        LoadZonas()

      }, 2000);
    }
  }, [PeticionesMapa])

  const SeleccionarReco = (data) => {
    if (data.id === RecolectorSelecionado?.id) {
      setRecolectorSelecionado(undefined)
    } else {
      setRecolectorSelecionado(data)
      LoadPeticionesReco(data.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    }
  }

  // LLAMADA A 
  const { LoadRecolectores, RecolectorSeleccionado, Recolectores, HandlerCambioRecolector,   // Loading
    LoadingRecolectores } = UseRecolectores()


  // EFECTO DE RENDER
  useEffect(() => {
    if (user) {
      LoadRecolectores(user.idciudad)
      LoadAlertas()
    }
  }, [user])

  useEffect(() => {
    if (RecolectorSeleccionado) {
      LoadPeticionesReco(RecolectorSeleccionado.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    }
  }, [RecolectorSeleccionado])

  return (
    <DashboardLayout
      AzulFuerte={Darkmode === true ? 'red' : AzulFuerteLight}
      Azulbajo={Darkmode === true ? 'red' : AzulBajoLight}
      GrisFuerte={Darkmode === true ? 'red' : GrisFuerteLight}
    >
      <div className='ContainerDashboard'>
        <div className='BodyDashboard'>
          <div className="InicioIzquierdo">
            <CardBienvenido
              nombre={user.descripcion}
            ></CardBienvenido>

            <div className='SeparadorAvatar'>
              <p>Estadísticas de </p>
              <p className='SeparadorAvatar-Reco'>{RecolectorSeleccionado === undefined ? moment().format('YYYY-MM-DD') : RecolectorSeleccionado?.descripcion}</p>
            </div>
            <div className='ConatinerWidgetDashboard'>
              <WidgetPeticion
                gris={Darkmode === true ? 'red' : GrisFuerteLight}
                color={"#3BAF2A"}
                icono={IconoCaja}
                Titulo={"Peticiones Totales"}
                data={PeticionesActivas?.length === undefined ? 0 : PeticionesActivas?.length}
              ></WidgetPeticion>

              <WidgetPeticion
                gris={Darkmode === true ? 'red' : GrisFuerteLight}
                color={"#151B4E"}
                icono={IconoCaja}
                Titulo={"Peticiones Terminadas"}
                data={PeticionesTerminadas?.length === undefined ? 0 : PeticionesTerminadas?.length}
              ></WidgetPeticion>

              <WidgetPeticion
                gris={Darkmode === true ? 'red' : GrisFuerteLight}
                color={"#B40000"}
                icono={IconoCaja}
                Titulo={"Peticiones Canceladas"}
                data={PeticionesCanceladas?.length === undefined ? 0 : PeticionesCanceladas?.length}
              ></WidgetPeticion>

              <WidgetPeticion
                gris={Darkmode === true ? 'red' : GrisFuerteLight}
                color={"#9597a7"}
                icono={IconoCaja}
                Titulo={"Peticiones Pendientes"}
                data={PeticionesPendientes?.length === undefined ? 0 : PeticionesPendientes?.length}
              ></WidgetPeticion>

            </div>

            {/* style={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? { background: 'transparent' } : { display: 'none' }}  mapaciudad */}
            <div className='ContenedorMapaDash' style={UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ? { background: 'transparent' } : { display: 'none' }}>
              <div className='ContainerCheckAddressButton' style={{justifyContent:'left'}}>
                <div className='Boton_Aceptar'>
                  <button style={{ 
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection:'row-reverse',
                    fontSize:'16px',
                    width: '180px',
                    paddingTop: '7px',
                    paddingBottom: '7px'
                     }} onClick={() => Navegar('/MapaPrincipal')}>Mapa Nacional
                  <img style={{ width: '27px', paddingRight:'8px' }} src={imgMapaNacional} alt="" />
                  </button>
                </div>
              </div>
              {/* <MapaMonitorLayout
                imgcar={RecoLibre}
                imgcarD={RecoDocu}
                imgcarR={RecoReco}
                imgcarI={RecoAusente}
                imgpetition={PeticionIcon}
                imgpetitionP={PeticionIconP}
                imgpetitionR={PeticionIconR}
                imgpetitionD={PeticionIconD}
                SucrusalIcon={SucursalIcon}
              >
                {
                  RecolectoresMapa ?
                    <>
                      <div className='containermonitormapDASH'>
                        <div className='containermonitormapDASH'>
                          {
                            UsuariosPermisoSuc.includes(JSON.stringify(user?.id)) ?
                              <MapaMonitor
                                Recolectores={RecolectoresMapa}
                                socket={socket}
                                AccionEditarReco={HandleEditReco}
                                RecolectorSelecionado={RecolectorSelecionado}
                                accionCargarReco={SeleccionarReco}
                                PeticionesMapa={[]}
                                RutaREco={Ruta}
                                RutaEncurso={RutaEncurso}
                                Datazona={Datazona}
                                Zoom="3.8"
                                Sucursales={SucursalesData}
                              ></MapaMonitor>
                              :
                              <>

                              </>

                          }

                        </div>


                      </div>

                    </>
                    :
                    <>
                      <h3>Cargando</h3>
                    </>
                }
              </MapaMonitorLayout> */}

            </div>
          </div>
          <div className="Inicioderecho">
            <div className='ContainerRecoDash'>
              <CardRecolectores
                Recolectores={Recolectores}
                AccionCambiorecolector={HandlerCambioRecolector}
                Loading={LoadingRecolectores}
              ></CardRecolectores>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Dashboard