import React, { useState, useRef } from 'react'
import { InputLayout, PeticionesLayout } from '../../../theme/Layout'
import SearchIcon from '../../../assets/iconos/SearchIcon'
import useMode from '../../hooks/useMode'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { es } from 'date-fns/locale';
import CalendarioIcon from '../../../assets/iconos/CalendarioIcon';
import Loader from '../Loader/Loader';
const InputComponent = (props) => {

  const { AzulFuerteLight, Darkmode, Blanco } = useMode()
  const [VerDatosCp, setVerDatosCp] = useState(false);
  const [ValorCp, setValorCp] = useState()
  const AbrirModalClientes = () => {
    props.Accionbuscador(props.valor)
    props.AccionAbrirmodal()
  }
  const btnFecha = useRef();


  const Cargarclientepaginador = async () => {
    await props.CargarClientebuscador(props.valor)
    props.AccionAbrirmodal()
  }

  const ObtenerCodigospostales = async (e) => {
    if (e.target.value.length >= 4) {
      setVerDatosCp(true)
      props.BuscarCp(e.target.value)
    } else {
      setVerDatosCp(false)
    }
  }
  const ObtenerCodigopostal = async (e, data, valor) => {
    e.preventDefault()
    setVerDatosCp(false)
    await props.AccionCargaCp(data)
    setValorCp(valor)
  }
  const handleDateSelect = () => {
    btnFecha.current.setOpen(true)
}

  return (
    <InputLayout
      FLEX={props.flex}
    >
      <div
        style={{
          flex: props.flex,

        }}
        className='ContainerInput'>
        <div className='ContainerInput-Nombre'>
          <p>{props.nombre}</p>
        </div>
        <div
          className={[`InputPrincipal ${props.tipo === "Buscador" || props.tipo === "Buscador2" ? 'containerinputbuscador' : ''}`]} style={{ position: 'relative', flex:props.flex }}>
          {
            props.tipo == "Select" ?
              <>
                <select className='Select-Filtro'
                  value={props.value}
                  defaultValue='Todos'
                  onChange={(e) => props.Accion(e.target.value)}
                  style={
                    {
                      flex: props.flex,
                      borderRadius: props.tipo === "Buscador" ? '7px 0px 0px 7px' : '7px',
                      position: 'relative'
                    }
                  }
                  name=''
                >
                  {
                    props.Loading ?
                    <>
                    <Loader
                      title="Cargando datos"
                    ></Loader>
                    </>
                    :
                    props.datos?.map((res, index) => {

                      return (
                        <option key={index} value={res.nombre}>{res.nombre}</option>
                      )
                    })
                  }
                </select>
              </>
              :
              props.tipo == "Date" ?
                <>
                 <DatePicker ref={btnFecha} locale={es} dateFormat="dd/MM/yyyy" selected={moment(props?.value).toDate()} onChange={(e) => props.Accion(e)}/>
                </>
                :
                <>
                  <input
                    onChange={props.tipo === "CodigoPostal" ? (e => ObtenerCodigospostales(e)) : (e => props.Accion(e.target.value))}
                    placeholder={props.Placeholder}
                    disabled={props.Disabled}
                    value={props?.value}
                    style={
                      {
                        flex: props.flex,
                        borderRadius: props.tipo === "Buscador" || props.tipo === "Buscador2" ? '5px 0px 0px 5px' : '5px',
                        position: 'relative',
                      }
                    }
                    type={'text'}

                  />
                </>
          }
          <div style={props.tipo === "Buscador" ? { color: 'fff' } : { display: 'none' }} >
            <div onClick={() => props.valor.length > 0 ? Cargarclientepaginador() : AbrirModalClientes()} className='ContainerActionSearch'>
              <SearchIcon></SearchIcon>
            </div>
          </div>

          <div style={props.tipo === "Buscador2" ? { color: 'fff' } : { display: 'none' }} >
            <div onClick={() => props.AccionBoton()} className='ContainerActionSearch'>
              <SearchIcon></SearchIcon>
            </div>
          </div>

          <div style={props.tipo === "Date" ? { color: 'fff' } : { display: 'none' }} >
            <div onClick={() => handleDateSelect()} className='ContainerActionSearch'>
              <CalendarioIcon color="#ffff"></CalendarioIcon>
            </div>
          </div>

          <div style={props.tipo === "CodigoPostal" && props.datos && VerDatosCp ? { position: 'absolute', background: '#DBDDED', width: '100%', top: '41px', zIndex: 1, height: '200px', borderRadius: 5, maxHeight: '200px', overflowY: 'scroll' } : { display: 'none' }}>
            <ul className='listcpdata'>
              {
                props?.datos?.map(res => {
                  return (
                    <li onClick={(e) => ObtenerCodigopostal(e, res.valueJson, res.direccion)}>{res.direccion}</li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </InputLayout>


  )
}

export default InputComponent