import React from 'react';
import { LoaderLayout } from '../../../theme/Layout';
import imgLoader1 from '../../../assets/iconos/loader1.svg';
import imgLoader2 from '../../../assets/iconos/loader2.svg';
import LoaderCarro from '../../../assets/imagenes/data.json'
import Lottie from 'lottie-react'

const Loader = (props) => {
  return (
    <LoaderLayout>
      
              <div className="loader">
                <div className='containerLoaderLoti'>
                    <div className='lottiicon'>
                    <Lottie animationData={LoaderCarro} loop={true} />
                    </div>
                </div>
                
                {/* <div className="loaderImg">
                    <div className="duo duo1">
                        <div className="dot dot-a"> <img src={imgLoader1} /></div>
                        <div className="dot dot-b"> <img src={imgLoader2} /></div>
                    </div>
                    <div className="duo duo2">
                        <div className="dot dot-a"> <img src={imgLoader2} /></div>
                        <div className="dot dot-b"> <img src={imgLoader1} /></div>
                    </div>
                </div> */}
                <div className="loaderTexto">
                    <p>{props.title}...</p>
                </div>
            </div>

    </LoaderLayout>
  )
}

export default Loader