import React from 'react'

const VermasCardReco = (props) =>{
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.8125 3.28125H17.1875V2.29688C17.1875 2.0358 17.0788 1.78542 16.8855 1.60082C16.6921 1.41621 16.4298 1.3125 16.1562 1.3125H2.83594C2.44847 1.3125 2.07688 1.45942 1.8029 1.72095C1.52892 1.98247 1.375 2.33718 1.375 2.70703V15.4219C1.375 15.6829 1.48365 15.9333 1.67705 16.1179C1.87044 16.3025 2.13275 16.4062 2.40625 16.4062H3.4375V4.59375C3.4375 4.24565 3.58237 3.91181 3.84023 3.66567C4.09809 3.41953 4.44783 3.28125 4.8125 3.28125Z" fill="black"/>
<path d="M19.5938 4.59375H5.84375C5.57025 4.59375 5.30794 4.69746 5.11455 4.88207C4.92115 5.06667 4.8125 5.31705 4.8125 5.57812V18.7031C4.8125 18.9642 4.92115 19.2146 5.11455 19.3992C5.30794 19.5838 5.57025 19.6875 5.84375 19.6875H19.5938C19.8673 19.6875 20.1296 19.5838 20.323 19.3992C20.5163 19.2146 20.625 18.9642 20.625 18.7031V5.57812C20.625 5.31705 20.5163 5.06667 20.323 4.88207C20.1296 4.69746 19.8673 4.59375 19.5938 4.59375ZM16.8438 12.7969H13.4062V16.0781H12.0312V12.7969H8.59375V11.4844H12.0312V8.20312H13.4062V11.4844H16.8438V12.7969Z" fill="black"/>
</svg>
  )
}

export default VermasCardReco