import React from 'react'
import { Droppable } from "react-beautiful-dnd";

const PeticionIcon = (props) => {
    return (
<svg width="30" height="36" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.0723 27.125L21.0938 16.9006V26.4688C21.0938 26.6428 21.1555 26.8097 21.2654 26.9328C21.3753 27.0559 21.5243 27.125 21.6797 27.125H30.0723Z" fill={props.color} stroke-width="3" stroke-linejoin="round"/>
<path d="M17.9688 29.3125V15.75H5.85938C5.54857 15.75 5.2505 15.8883 5.03073 16.1344C4.81096 16.3806 4.6875 16.7144 4.6875 17.0625V52.9375C4.6875 53.2856 4.81096 53.6194 5.03073 53.8656C5.2505 54.1117 5.54857 54.25 5.85938 54.25H30.0781C30.3889 54.25 30.687 54.1117 30.9068 53.8656C31.1265 53.6194 31.25 53.2856 31.25 52.9375V30.625H19.1406C18.8298 30.625 18.5318 30.4867 18.312 30.2406C18.0922 29.9944 17.9688 29.6606 17.9688 29.3125Z" fill={props.color} stroke-width="3" stroke-linejoin="round"/>
<path d="M35.7422 13.125H44.1348L35.1562 2.90063V12.4688C35.1562 12.6428 35.218 12.8097 35.3279 12.9328C35.4378 13.0559 35.5868 13.125 35.7422 13.125Z" fill={props.color} stroke-width="3" stroke-linejoin="round"/>
<path d="M33.2031 16.625C32.8923 16.625 32.5943 16.4867 32.3745 16.2406C32.1547 15.9944 32.0312 15.6606 32.0312 15.3125V1.75H16.7969C16.4861 1.75 16.188 1.88828 15.9682 2.13442C15.7485 2.38056 15.625 2.7144 15.625 3.0625V12.25H19.7383C20.2633 12.2524 20.7827 12.3709 21.2667 12.5987C21.7507 12.8266 22.1898 13.1593 22.5586 13.5778L33.2188 25.7184C33.9624 26.5636 34.3777 27.7037 34.374 28.8903V43.75H44.1406C44.4514 43.75 44.7495 43.6117 44.9693 43.3656C45.189 43.1194 45.3125 42.7856 45.3125 42.4375V16.625H33.2031Z" fill={props.color} stroke-width="3" stroke-linejoin="round"/>
</svg>
 )
}

export default PeticionIcon
