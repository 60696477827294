import React, { useState, useEffect, useRef } from 'react';
import { TablaLayout, DireccionesLayout, PeticionesLayout } from '../theme/Layout';
import { useAuth } from '../context/authcontext';
import useClientes from '../components/hooks/useClientes';
import useDirecciones from '../components/hooks/useDirecciones';
import useAutocompletecp from '../components/hooks/useAutocompletecp';
import useNuevaPeticion from '../components/Petiticiones/Nuevo/hooks/useNuevapeticion';
import useModal from '../utils/Components/modal/usemodal';
import MapaclientePeticion from '../components/Mapa/MapaclientePeticion';
import Loader from '../utils/Components/Loader/Loader';
import InputComponent from '../utils/Components/Inputs/InputBuscador';
import ModalComponent from '../utils/Components/modal/ModalComponent';
import TablaPaginador from '../utils/Components/Tablas/paginador/TablaPaginador';
import TablaSinPaginador from '../utils/Components/Tablas/TablaSinpaginador/TablaSinPaginador';
import imgDirecciones from '../assets/iconos/direccionesIcon.svg';
import imgEditar from '../assets/iconos/EditarIcon.svg';
import NuevoIcon from '../assets/iconos/NuevoIcon';
import Swal from 'sweetalert2';
import useErrorAlert from '../utils/hooks/useErrorAlert';

const Direcciones = () => {
    let Tablapaginador = useRef();
    const { user } = useAuth()
    const { HanlderMensajeCorrecto, HanlderWarningMensaje } = useErrorAlert()
    const {
        loadingDirecciones,
        ClientesDirecciones,
        ClientesPaginador,
        handlerSiguiente,
        TotalRegistros,
        handlerAnterior,
        Buscar,
        HandlerBuscar,
        Totalpaginas,
        HanlderChangueRegistros,
        Pagina
    } = useClientes();
    const {
        CargarDirecciones,
        Direcciones,
        CargarDireccionesDeRecoleccion,
        GeocoderData,
        GeocoderObtenerDireccion,
        SeleccionarDireccion,
        AñadirNuevaDireccion,
        ActuliazarDireccionARecolecion,
        LimpiarGeocoder,
        LimpiarDireccion,
        //DIRECCION
        HanlderCambioLatitudeDireccion,
        LatitudeDireccion,
        HanlderCambioLongitudeDireccion,
        LongitudeDireccion,
        PaisDireccion,
        HandlerCambioPaisDireccion,
        CalleDireccion,
        HandlerCambioCalleDireccion,
        NumeroExtDireccion,
        HandlerCambioNumExtDireccion,
        NumeroIntDireccion,
        HandlerCambioNumIntDireccion,
        CpDireccion,
        HandlerCambioCpDireccion,
        ColoniaDireccion,
        HandlerCambioColoniaDireccion,
        CiudadDireccion,
        HandlerCambioCiudadDireccion,
        MunicipioDireccion,
        HandlerCambioMunicipioDireccion,
        EstadoDireccion,
        HandlerCambioEstadoDireccion,
        CorreoDireccion,
        HandlerCambioCorreoDireccion,
        TelefonoDireccion,
        HandlerCambioTelefonoDireccion,
        //BUSCAR
        BuscarDir,
        HandlerBuscarDir,
        //NUEVA DIRECCION
        LoadingNuevaDireccion
    } = useDirecciones();
    const [coordenadaGuardada, setcoordenadaGuardada] = useState();
    const { CargarCodigoPostales,
        Datacp,
        LoadingCp
    } = useAutocompletecp();
    const {
        HanlderClienteBuscador,
        HanlderSeleccionarCliente,
        ClienteSeleccionado,
        // DIRECCION
        PaisNueva,
        CalleNueva,
        NumeroExtNueva,
        NumIntNueva,
        CpNueva,
        ColoniaNueva,
        CiudadNueva,
        MunicipioNueva,
        EstadoNueva,
        CorreoNueva,
        TelefonoNueva,
        HandlerCambioPaisNueva,
        HandlerCambioCalleNueva,
        HandlerCambioNumeroExtNueva,
        HandlerCambioNumIntNueva,
        HandlerCambioCpNueva,
        HandlerCambioColoniaNueva,
        HandlerCambioCiudadNueva,
        HandlerCambioMunicipioNueva,
        HandlerCambioEstadoNueva,
        HandlerCambioCorreoNueva,
        HandlerCambioTelefonoNueva,
        LatitudeNueva,
        LongitudeNueva,
        HanlderCambioLatitudeNueva,
        HanlderCambioLongitudeNueva,
        LimpiarNueva
    } = useNuevaPeticion();
    const [BanderaDireccionVerificar, setBanderaDireccionVerificar] = useState(false);
    const [EditarDireccionnuevapeticion, setEditarDireccionnuevapeticion] = useState(false)
    const {
        ModalDireccionesCliente,
        AbrirModalDireccionesCliente,
        CerrarModalDireccionesCliente,
        ModalNuevaDrireccionRecolecion,
        AbrirNuevaDireccionRecolecion,
        CerrarNuevaDireccionRecoleccion,
        ModalEditarDireccionCliente,
        AbrirModalEditarDireccionCliente,
        CerrarModalEditarDireccionCliente
    } = useModal()
    const [seleccionada, setseleccionada] = useState();
    const [active, setactive] = useState(false);
    const [tipo, settipo] = useState();
    const [original, setoriginal] = useState();
    const [activarSubmenu, setactivarSubmenu] = useState(false);
    const [activarMapa, setactivarMapa] = useState(false);
    const [BanderaNuevapeticionVerificar, setBanderaNuevapeticionVerificar] = useState(false)

    const handlerActivarMapa = () => {
        if (activarMapa === true) {
            setactivarMapa(false)
        } else {
            setactivarMapa(true)
        }
    }

    const handlerSeleccionar = (data) => {
        if (seleccionada === data) {
            setseleccionada()
            setactivarSubmenu(false)
        } else {
            setseleccionada(data)
            setactivarSubmenu(true)
        }
    }
    const handlerFueraAcciones = (e, type) => {
        e.preventDefault()
        if (type === active) {
            setseleccionada(true)
        } else {
            setseleccionada()
        }
    }
    //MODAL DIRECCIONES
    const HandlerModalDirecciones = async (id) => {
        CargarDirecciones(id)
        AbrirModalDireccionesCliente()
    }
    const HandlerCerrarModalDirecciones = () => {
        CerrarModalDireccionesCliente()
        LimpiarGeocoder()
    }

    //MODAL EDITAR DIRECCION
    const HandlerModalEditarDir = (res) => {
        AbrirModalEditarDireccionCliente()
        HanlderSelectDireccion(res)
    }
    const HandlerCerrarModalEditarDir = () => {
        CerrarModalEditarDireccionCliente()
        LimpiarNueva()
        LimpiarGeocoder()
    }
    // SELECCIONAR CLIENTE
    const Seleccionarcliente = async (data) => {
        await HanlderClienteBuscador(data.nombrecompleto)
        await CargarDireccionesDeRecoleccion(data.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        await CargarDirecciones(data.id)
        HanlderSeleccionarCliente(data)
    }
    //FUNCION PARA LLENAR CP 
    const HandlerPrecargaCodigopostalDireccion = async (data) => {
        HandlerCambioCpDireccion(data.cp)
        HandlerCambioColoniaDireccion(data.colonia)
        HandlerCambioCiudadDireccion(data.ciudad)
        HandlerCambioEstadoDireccion(data.estado)
        HandlerCambioPaisDireccion(data.pais)
        HandlerCambioMunicipioDireccion(data.municipio)
    }

    // FUNCION PARA SELECCIONAR DIRECCION
    const HanlderSelectDireccion = async (res) => {
        SeleccionarDireccion(res)
        HandlerCambioCpDireccion(res.cp)
        HandlerCambioTelefonoDireccion(res.telefono)
        HandlerCambioCalleDireccion(res.calle)
        HandlerCambioNumExtDireccion(res.noext)
        HandlerCambioNumIntNueva(res.noint)
        HandlerCambioColoniaDireccion(res.colonia)
        HandlerCambioMunicipioDireccion(res.municipio)
        HandlerCambioCiudadDireccion(res.localidad)
        HandlerCambioEstadoDireccion(res.estado)
        HandlerCambioPaisDireccion(res.pais)
        HandlerCambioCorreoDireccion(res.correo)
        settipo(res.tipo)
        setoriginal(res.original)
        HanlderCambioLatitudeDireccion(res.latitude)
        HanlderCambioLongitudeDireccion(res.longitude)
    }

    //TRAER COORDENADA CARGADA
    const HandlerCargaCoordenada = (res) => {
        if ( res.latitude === null || res.longitude === null) {
            HandlerModalEditarDir(res)
        } else{
            //Traer coordenadas guardadas
            HandlerCargaCoordenadaGuardada(res)
        }
    }

    const HandlerCargaCoordenadaGuardada = (res) => {
        const cords = { lat: res.latitude, lng: res.longitude }
        setcoordenadaGuardada(cords)
        setBanderaDireccionVerificar(false)
         HandlerModalEditarDir(res)
    }

    // FUNCION PARA VERIFICAR DIRECCION EN EDITAR
    const HanlderVerificarDireccionEditar = async () => {
        handlerActivarMapa()
        setBanderaDireccionVerificar(true)
        let DireccionCadenaEditar = `${CalleDireccion} ${NumeroIntDireccion === "s/n" ? NumeroExtDireccion : NumeroExtDireccion},${ColoniaDireccion},${CpDireccion},${CiudadDireccion},${EstadoDireccion}.,${PaisDireccion}`

        await GeocoderObtenerDireccion(DireccionCadenaEditar)
    }
    useEffect(() => {
        if (GeocoderData && BanderaDireccionVerificar) {
            HanlderCambioLatitudeDireccion(GeocoderData.lat)
            HanlderCambioLongitudeDireccion(GeocoderData?.lng)
        }
        else {
            LimpiarGeocoder()
        }
    }, [GeocoderData, BanderaDireccionVerificar])

    //NUEVA DIRECCION
    const NuevaDireccionEdit = async () => {
        await CargarDireccionesDeRecoleccion(ClienteSeleccionado.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
        HandlerModalDirecciones(ClienteSeleccionado.id)
    }

    const HanlderEditarNuevaDireccion = (data) => {
        setEditarDireccionnuevapeticion(data)
    }

    //DIRECCION RECOLECCION
    const HanlderEditarDirCliente = async () => {
        let requestEditarDireccion = {
            "calle": CalleDireccion,
            "noext": NumeroExtDireccion,
            "noint": NumIntNueva === undefined ? '' : NumIntNueva,
            "colonia": ColoniaDireccion,
            "cp": CpDireccion,
            "localidad": CiudadDireccion,
            "estado": EstadoDireccion,
            "idcliente": ClienteSeleccionado.id,
            "pais": PaisDireccion,
            "correo": CorreoDireccion,
            "telefono": TelefonoDireccion,
            // "original": "No",
            "municipio": MunicipioDireccion,
            "idasesor": user?.id,
            "coordenadas": {
                "latitude": LatitudeDireccion.toString(),
                "longitude": LongitudeDireccion.toString()
            }
            // "tipo": 2
        }
        if (tipo === 1) {
             await ActuliazarDireccionARecolecion(requestEditarDireccion, user?.id, 1, LatitudeDireccion, LongitudeDireccion)
        } else{
             await ActuliazarDireccionARecolecion(requestEditarDireccion, user?.id, 2, LatitudeDireccion, LongitudeDireccion)
        }
        HanlderMensajeCorrecto('Se editó la dirección correctamente')
        await CargarDirecciones(ClienteSeleccionado.id, BuscarDir)
        NuevaDireccionEdit()
        HanlderEditarNuevaDireccion(false)
        LimpiarGeocoder()
        LimpiarDireccion()
        HandlerCerrarModalEditarDir()
    }
  
    //PAGINADOR CLIENTES
    const HanlderBuscarBtn = async () => {
        await ClientesDirecciones(Buscar)
    }

    useEffect(() => {
        if (ClientesPaginador === undefined) {
            ClientesDirecciones()
        }
    }, [ClientesPaginador, ClientesDirecciones])

    //BUSCAR DIRECCION
    const HanlderBuscarDirBtn = async () => {
        await CargarDirecciones(ClienteSeleccionado.id, BuscarDir)
    }
    useEffect(() => {
        if (Direcciones === undefined) {
            CargarDirecciones()
        }
    }, [Direcciones, CargarDirecciones])


    const RenderItemCabezeraTablaPaginador = () => {
        return (
            <>
                <div className='ItemHeader-TablaPaginador' style={{ flex: 2 }}>
                    <p>Núm. Cliente</p>
                </div>
                <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
                    <p>Nombre</p>
                </div>
                <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                    <p>Sucursal</p>
                </div>
                <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
                    <p>RFC</p>
                </div>
                <div className='ItemHeader-TablaPaginador' style={{ flex: 3 }}>
                    <p>Dirección</p>
                </div>
                <div className='ItemHeader-TablaPaginador' style={{ flex: 1 }}>
                    <p></p>
                </div>
            </>
        )
    }
    const RenderItemCuerpoTablaPaginador = () => {
        return (
            <div style={{ background: '#fff' }}>
                <>
                    {
                        ClientesPaginador?.map((resp, index) => {
                            return (
                                <>
                                    <div className='Cuerpo-TablaSinPaginador' style={{ height: '60px' }} onClick={() => Seleccionarcliente(resp)}>
                                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 2, paddingTop: '5px' }}>
                                            <p style={{ margin: '0px' }}>{resp.id}</p>
                                        </div>
                                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3, textAlign:'left' }}>
                                            <p>{resp.nombrecompleto}</p>
                                        </div>
                                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1 }}>
                                            <p>{resp.nombreSucursal}</p>
                                        </div>
                                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3, textAlign: 'center', padding: '3px' }}>
                                            <p>{resp.rfc} </p>
                                        </div>
                                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 3, textAlign: 'justify' }}>
                                            <p>{resp.calle},{resp.noext},{resp.colonia},{resp.ciudad}</p>
                                        </div>
                                        <div className='ItemCuerpo-Tablapaginador' style={{ flex: 1, position: 'relative', height: '66px' }}>
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                className={`ContainerActions ${resp.id === seleccionada ? "active" : ""}`} onClick={() => handlerSeleccionar(resp.id)}>
                                                <span style={{ background: '#9597A7' }}></span>
                                                <span style={{ background: '#9597A7' }}></span>
                                                <span style={{ background: '#9597A7' }}></span>
                                            </div>
                                            {/* <div className="ContainerSubAction" onMouseLeave={(e) => handlerFueraAcciones(e)} style={res.id !== isselected ? { display: 'none' } : { background: '#D9D9D9' }}> */}
                                            <div className="ContainerSubAction" onMouseLeave={(e) => handlerFueraAcciones(e)}
                                                style={resp.id !== seleccionada
                                                    ?
                                                    { display: 'none' }
                                                    :
                                                    (ClientesPaginador.length > 1 ? index > ClientesPaginador.length - 5 : false)
                                                        ? { display: 'block', bottom: '57px' }
                                                        : { display: 'block', top: '57px' }
                                                }
                                            >
                                                <div className="ItemSubmenu" onClick={() => HandlerModalDirecciones(resp.id)}>
                                                    <img style={{ width: '34px' }} src={imgDirecciones} alt="" />
                                                    <p>Direcciones</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                    <ModalComponent
                        Nombremodal={ModalDireccionesCliente}
                        Cerrarmodal={HandlerCerrarModalDirecciones}
                        Titulo={"Direcciones"}
                        Contenido={RenderItemModalDireccionesCliente}>
                    </ModalComponent>
                </>
            </div>
        )
    }
    const RenderItemCabezera = () => {
        return (
            <>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Calle</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Núm. Ext.</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Colonia</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Ciudad</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Estado</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 2 }}>
                    <p>Tipo</p>
                </div>
                <div className='ItemHeader-TablaSinPaginador' style={{ flex: 1 }}>
                    <p></p>
                </div>
            </>
        )
    }
    const RenderItemCuerpo = () => {
        return (
            <>
                <div className="cuerpoTabla">
                    {
                        Direcciones?.map(res => {
                            return (
                                <>
                                    <div className='Cuerpo-TablaSinPaginador'>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2 }}>
                                            <p>{res.calle}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2 }}>
                                            <p>{res.noext}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2 }}>
                                            <p>{res.colonia}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2 }}>
                                            <p>{res.localidad}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2 }}>
                                            <p>{res.estado}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 2 }}>
                                            <p >{res.tipo === 0 ? "Sin tipo" : res.tipo === 2 ? "Recolección" : res.tipo === 1 ? "Principal" : res.tipo === 3 ? "Recolección" : "Sin tipo"}</p>
                                        </div>
                                        <div className='ItemCuerpo-TablaSinpaginador' style={{ flex: 1 }}>
                                            <img style={{ width: 25, paddingLeft: 10, cursor: 'pointer' }} src={imgEditar} onClick={() => HandlerCargaCoordenada(res)} />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        )
                    }
                </div>
                <ModalComponent
                    Nombremodal={ModalEditarDireccionCliente}
                    Cerrarmodal={HandlerCerrarModalEditarDir}
                    Titulo={"Editar dirección"}
                    Contenido={RenderItemEditarDireccion}>
                </ModalComponent>
            </>
        )
    }
    const RenderItemModalDireccionesCliente = () => {
        return (
            <DireccionesLayout>
                
                <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, cursor: 'pointer' }}>
                        <div className="inputBuscar" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginTop: '-20px', marginRight: '10px', width: '248px' }}>
                            <InputComponent
                                nombre={"Buscar:"}
                                tipo={"Buscador2"}
                                Accion={HandlerBuscarDir}
                                flex={1}
                                AccionBoton={HanlderBuscarDirBtn}
                            ></InputComponent>
                        </div>
                        <div className='iconnuevo-peticion' onClick={() => AñadirNuevaDireccionIcono()} style={{ marginTop: '15px' }}>
                            <NuevoIcon width={40} height={40}></NuevoIcon>
                        </div>
                    </div>
                    <TablaSinPaginador
                        Cabezera={RenderItemCabezera}
                        Cuerpo={RenderItemCuerpo}
                        Modal={true}
                    ></TablaSinPaginador>
                </div>
            </DireccionesLayout>
        )
    }

    const RenderItemEditarDireccion = () => {
        return (
            <>
                <div>
                    <div className="generalInfoShow" style={{ width: '48%' }}>
                        <p>Cliente: {ClienteSeleccionado?.nombrecompleto} </p>
                        <p style={{ paddingLeft: '8px' }}></p>
                    </div>
                </div>
                <div className='Contenido-Grid-NuevadireccionRecoleccion'>
                    <div className='MapaNueva' style={BanderaDireccionVerificar === true ? {visibility:'hidden'} : {visibility:'visible'}}>
                        <MapaclientePeticion
                            Direccion={coordenadaGuardada}
                            AccionCambioLat={HanlderCambioLatitudeDireccion}
                            AccionCambioLng={HanlderCambioLongitudeDireccion}
                            AccionEditar={HanlderEditarNuevaDireccion}
                        ></MapaclientePeticion>
                    </div>
                    <div className='MapaNueva' style={BanderaDireccionVerificar === true ? {visibility:'visible'} : {visibility:'hidden'}}>
                        <MapaclientePeticion
                            Direccion={GeocoderData}
                            AccionCambioLat={HanlderCambioLatitudeDireccion}
                            AccionCambioLng={HanlderCambioLongitudeDireccion}
                            AccionEditar={HanlderEditarNuevaDireccion}
                        ></MapaclientePeticion>
                    </div>
                    <div className='Nuevadireccion'>
                        <div className='Contenedor1-direccionRecolecion'>
                            <PeticionesLayout>
                                <div className="inputsContenedor">
                                    <div>
                                        <div className='ContainerInfoClienteInputs'>
                                            <InputComponent
                                                nombre={"Buscar por código postal"}
                                                tipo={"CodigoPostal"}
                                                flex={1}
                                                datos={Datacp}
                                                BuscarCp={CargarCodigoPostales}
                                                AccionCargaCp={HandlerPrecargaCodigopostalDireccion}
                                                Loading={LoadingCp}
                                                Placeholder={`${CpDireccion}, ${ColoniaDireccion}, ${CiudadDireccion}, ${MunicipioDireccion}, ${EstadoDireccion}, ${PaisDireccion}`}
                                                Disabled={tipo == 1 ? true : false}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"País"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioPaisDireccion}
                                                value={PaisDireccion}
                                                Disabled={true}
                                            ></InputComponent>
                                        </div>

                                        <div className='ContainerInfoClienteInputs'>
                                            <InputComponent
                                                nombre={"Calle"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioCalleDireccion}
                                                value={CalleDireccion}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Núm. Ext."}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioNumExtDireccion}
                                                value={NumeroExtDireccion}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Núm. Int."}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioNumIntNueva}
                                                value={NumIntNueva}
                                            ></InputComponent>
                                        </div>

                                        <div className='ContainerInfoClienteInputs'>
                                            <InputComponent
                                                nombre={"C. P."}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioCpDireccion}
                                                value={CpDireccion}
                                                Disabled={tipo == 1 ? true : false}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Colonia"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioColoniaDireccion}
                                                value={ColoniaDireccion}
                                            ></InputComponent>
                                        </div>

                                        <div className='ContainerInfoClienteInputs'>
                                            <InputComponent
                                                nombre={"Ciudad"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioCiudadDireccion}
                                                value={CiudadDireccion}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Municipio"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioMunicipioDireccion}
                                                value={MunicipioDireccion}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Estado"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioEstadoDireccion}
                                                value={EstadoDireccion}
                                            ></InputComponent>
                                        </div>

                                        <div className='ContainerInfoClienteInputs'>
                                            <InputComponent
                                                nombre={"Correo"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioCorreoDireccion}
                                                value={CorreoDireccion}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Teléfono"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioTelefonoDireccion}
                                                value={TelefonoDireccion}
                                            ></InputComponent>
                                        </div>
                                    </div>
                                </div>
                                <div className='ContainerCheckAddressButton'>
                                    <div className='Boton_Aceptar'>
                                        <button style={{ marginTop: '10px' }} onClick={GeocoderData === undefined ? () => HanlderVerificarDireccionEditar() : () => HanlderEditarDirCliente()}>{GeocoderData === undefined ? 'Verificar' : 'Guardar'}</button>
                                    </div>
                                </div>
                            </PeticionesLayout>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    ///////////////////////////////NUEVA DIRECCION CLIENTE
  // FUNCION PARA AÑADIR NUEVA DIRECCION DE RECOLECION 
  const AñadirNuevaDireccionIcono = () => {
    AbrirNuevaDireccionRecolecion()
}

//CERRAR MODAL NUEVA DIR
const HandlerCerrarModalNuevaDireccion = () => {
        CerrarNuevaDireccionRecoleccion()
        LimpiarNueva()
        LimpiarGeocoder()
    }
// FUNCION PARA LLENAR CP 
const HandlerPrecargaCodigopostalNueva = async (data) => {
    HandlerCambioCpNueva(data.cp)
    HandlerCambioColoniaNueva(data.colonia)
    HandlerCambioCiudadNueva(data.ciudad)
    HandlerCambioEstadoNueva(data.estado)
    HandlerCambioPaisNueva(data.pais)
    HandlerCambioMunicipioNueva(data.municipio)
}
//FUNCIONES PARA DIRECCION
const NuevaDireccion = async () => {
    await CargarDireccionesDeRecoleccion(ClienteSeleccionado.id, JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    HandlerModalDirecciones(ClienteSeleccionado.id)
    // CerrarModalSinDirecciones()
    // AbrirModalDirecciones()
}

const HanlderNuevaDireccion = async () => {
    let requestNuevaDireccion = {
        "calle": CalleNueva,
        "noext": NumeroExtNueva,
        "noint": NumIntNueva === undefined ? '' : NumIntNueva,
        "colonia": ColoniaNueva,
        "cp": CpNueva,
        "localidad": CiudadNueva,
        "estado": EstadoNueva,
        "idcliente": ClienteSeleccionado.id,
        "pais": PaisNueva,
        "correo": CorreoNueva,
        "telefono": TelefonoNueva,
        "original": "No",
        "municipio": MunicipioNueva,
        "idasesor": user?.id,
        "coordenadas": {
            "latitude": LatitudeNueva.toString(),
            "longitude": LongitudeNueva.toString()
        },
        "tipo": 2
    }
    await AñadirNuevaDireccion(requestNuevaDireccion, user?.id)
    CerrarNuevaDireccionRecoleccion()
    LimpiarGeocoder()
    LimpiarNueva()
    NuevaDireccion()
    HanlderEditarNuevaDireccion(false)
}

//VERIFICAR DIRECCION
const HanlderVerificarNueva = async () => {
    setBanderaDireccionVerificar(true)
    let DireccionCadena = `${CalleNueva} ${NumIntNueva === "s/n" ? NumeroExtNueva : NumeroExtNueva},${ColoniaNueva},${CpNueva},${CiudadNueva},${EstadoNueva}.,${PaisNueva}`

    await GeocoderObtenerDireccion(DireccionCadena)

}
useEffect(() => {
    if (GeocoderData && BanderaDireccionVerificar) {
        HanlderCambioLatitudeNueva(GeocoderData.lat)
        HanlderCambioLongitudeNueva(GeocoderData?.lng)
    } else {
        LimpiarGeocoder()
    }
}, [GeocoderData, BanderaDireccionVerificar])


const RenderItemNuevaDireccion = () => {
    return (
        LoadingNuevaDireccion ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30%', margin: 'auto' }}>
                <Loader
                    title="Añadiendo Direccion"
                ></Loader>
            </div>
            :
            <>
                <div>
                    <div className="generalInfoShow" style={{ width: '48%' }}>
                        <p>Cliente: {ClienteSeleccionado?.nombrecompleto} </p>
                        <p style={{ paddingLeft: '8px' }}></p>
                    </div>
                </div>
                <div className='Contenido-Grid-NuevadireccionRecoleccion'>
                    <div className='MapaNueva'>
                        <MapaclientePeticion
                            Direccion={GeocoderData}
                            AccionCambioLat={HanlderCambioLatitudeNueva}
                            AccionCambioLng={HanlderCambioLongitudeNueva}
                            AccionEditar={HanlderEditarNuevaDireccion}
                        ></MapaclientePeticion>
                    </div>
                    <div className='Nuevadireccion'>
                        <div className='Contenedor1-direccionRecolecion'>
                            <PeticionesLayout>
                                <div className="inputsContenedor">
                                    <div>
                                        <div className='ContainerInfoClienteInputs'>
                                            <InputComponent
                                                nombre={"Buscar por código postal"}
                                                tipo={"CodigoPostal"}
                                                flex={1}
                                                datos={Datacp}
                                                BuscarCp={CargarCodigoPostales}
                                                AccionCargaCp={HandlerPrecargaCodigopostalNueva}
                                                Loading={LoadingCp}

                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"País"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioPaisNueva}
                                                value={PaisNueva}

                                            ></InputComponent>
                                        </div>

                                        <div className='ContainerInfoClienteInputs'>
                                            <InputComponent
                                                nombre={"Calle"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioCalleNueva}
                                                value={CalleNueva}

                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Núm. Ext."}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioNumeroExtNueva}
                                                value={NumeroExtNueva}

                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Núm. Int."}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioNumIntNueva}
                                                value={NumIntNueva}
                                            ></InputComponent>
                                        </div>

                                        <div className='ContainerInfoClienteInputs'>
                                            <InputComponent
                                                nombre={"C. P."}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioCpNueva}
                                                value={CpNueva}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Colonia"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioColoniaNueva}
                                                value={ColoniaNueva}
                                            ></InputComponent>
                                        </div>

                                        <div className='ContainerInfoClienteInputs'>
                                            <InputComponent
                                                nombre={"Ciudad"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioCiudadNueva}
                                                value={CiudadNueva}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Municipio"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioMunicipioNueva}
                                                value={MunicipioNueva}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Estado"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioEstadoNueva}
                                                value={EstadoNueva}
                                            ></InputComponent>
                                        </div>

                                        <div className='ContainerInfoClienteInputs'>
                                            <InputComponent
                                                nombre={"Correo"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioCorreoNueva}
                                                value={CorreoNueva}
                                            ></InputComponent>
                                            <InputComponent
                                                nombre={"Teléfono"}
                                                tipo={"Text"}
                                                flex={1}
                                                Accion={HandlerCambioTelefonoNueva}
                                                value={TelefonoNueva}
                                            ></InputComponent>
                                        </div>
                                    </div>
                                </div>
                                <div className='ContainerCheckAddressButton'>
                                    <div className='Boton_Aceptar'>
                                        <button style={{ marginTop: '10px' }} onClick={GeocoderData === undefined ? () => HanlderVerificarNueva() : () => HanlderNuevaDireccion()}>{GeocoderData === undefined ? 'Verificar' : 'Guardar'}</button>
                                    </div>
                                </div>
                            </PeticionesLayout>
                        </div>
                    </div>
                </div>    
            </>
    )
}

    return (
        loadingDirecciones ?
            <>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                    <Loader
                        title="Cargando clientes">
                    </Loader>
                </div>
            </>
            :
            <>
                <div id='ContenedorPrincipal'>
                    <DireccionesLayout>
                        <TablaLayout>
                     
                            <div className='CamposFiltrosPaginaror' style={{ width: '95%', margin: 'auto', display: 'flex', justifyContent: 'space-between'}}>
                                   <div style={{display: 'flex', justifyContent: 'center',alignContent: 'center',alignItems: 'center',fontSize: '20px',fontWeight: 'bold',color: '#2f3768', paddingLeft: '2px'}}>
                    <p>Listado de clientes</p>
                </div>
                                <div style={{ marginRight: '95px' }}>
                                    <InputComponent
                                        nombre={"Buscar:"}
                                        tipo={"Buscador2"}
                                        Accion={HandlerBuscar}
                                        flex={1}
                                        AccionBoton={HanlderBuscarBtn}
                                    ></InputComponent>
                                </div>
                            </div>
                        </TablaLayout>
                        <div className='CampoTabla'>
                            <TablaPaginador
                                Cabezera={RenderItemCabezeraTablaPaginador}
                                Cuerpo={RenderItemCuerpoTablaPaginador}
                                identificador={Tablapaginador}
                                pagina={Pagina}
                                totalpaginas={Totalpaginas}
                                Accionnext={handlerSiguiente}
                                Accionprev={handlerAnterior}
                                RegistrosTotales={TotalRegistros}
                                CambiarRegistros={HanlderChangueRegistros}
                            ></TablaPaginador>
                        </div>
                    </DireccionesLayout>
                </div>
                <ModalComponent
                Nombremodal={ModalNuevaDrireccionRecolecion}
                Cerrarmodal={HandlerCerrarModalNuevaDireccion}
                Titulo={"Nueva Dirección"}
                Contenido={RenderItemNuevaDireccion}
            ></ModalComponent>
            </>
            
    )
}

export default Direcciones