import React, { useState } from 'react'
import Uselogin from './hook/uselogin'
import usuarioimg from '../../assets/iconos/usuario.webp';
import contra from '../../assets/iconos/contra.webp';
import loginmovil from '../../assets/imagenes/MovilBk.webp'
import { useAuth } from '../../context/authcontext';
import movilNavidad from '../../assets/imagenes/movilNavidad.webp';
const Cardlogin = (props) => {
  const{login}=useAuth()
  const {  usuario,
    contraseña,
    CambioUsuario,
    CambioContraseña,
    IniciarSecion } = Uselogin(login)
  return (
    <div className="contendor-login">
      <div className='cuerpologin'>
        <form className='formulario-login'  onSubmit={(e) => login(e,usuario,contraseña)}>
          <div className="contenedor-datos">
            <div className="cuerpodeformulario">
              <h1>Bienvenido
              </h1>
              <h2><span><img src={usuarioimg} /></span>Usuario</h2>
              <input onChange={(e) => CambioUsuario(e.target.value)}></input>
              <h2><span><img src={contra} /></span>Contraseña</h2>
              <input type="password" onChange={(e) => CambioContraseña(e.target.value)}></input>
              {/* <h3>Recuperar contraseña</h3> */}
              <div className="btnIngresar">
                <button type="submit">Iniciar sesión</button>
              </div>
            </div>
          </div>
        </form>
        <div className="-contenedorimg">
          <img className='EscritorioContenedor' style={{width:416, height:520}} src={props.mesActual === "1" ? "https://api.pktuno.mx//IMG/loginizqnuevo.png" :
            props.mesActual === "5" ? "https://api.pktuno.mx//IMG/loginizqaniversario.png" :
              props.mesActual === "11" ? "https://api.pktuno.mx//IMG/loginizqpkt1.png" :
                props.mesActual === "12" ? "https://api.pktuno.mx//IMG/cardNavidad.png"
                  : "https://api.pktuno.mx//IMG/loginizqpkt1.png"} alt="" />
          <img className='Movilcontenedorlogin' src={props.mesActual === "11" ? movilNavidad : loginmovil} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Cardlogin