import React from 'react'
import Swal from 'sweetalert2';

const  useErrorAlert =()  =>{

    const HanlderVerMensajeError = async(mensajeError) => {
        Swal.fire({
            icon: 'error',
            title: 'Ocurrio el siguiente Error..',
            text: `${mensajeError}`,
            confirmButtonColor: '#3BAF29',
        })
    }

    const HanlderMensajeCorrecto = async(Mensaje) => {
      Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: `${Mensaje}`,
          showConfirmButton: false,
          timer: 1500
      })
  }
  
  const HanlderWarningMensaje = async(Mensaje) => {
    Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: `${Mensaje}`,
        showConfirmButton: false,
        timer: 1500
    })
}

  return {
    HanlderVerMensajeError,
    HanlderMensajeCorrecto,
    HanlderWarningMensaje
  }
}

export default useErrorAlert