import React from 'react'

const LogoutIcon = () => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 16L19 11L15 6" stroke="#151B4E" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 11L13 11" stroke="#151B4E" strokeWidth="3" strokeLinecap="round"/>
<path d="M8 2C8 2 2.82354 1.9994 2.3529 2C1.88226 2.0006 1.88231 20 2.3529 20C2.82349 20 8 20 8 20" stroke="#151B4E" strokeWidth="3" strokeLinecap="round"/>
</svg>

  )
}

export default LogoutIcon