import React from 'react'

const Permisos =() => {
    const perimisos = ["Peticiones", "nuevo"]
    const UsuariosPermisoSuc = ["78", "8", "1", "7","740","743", "747","658"]

  return {
    perimisos,
    UsuariosPermisoSuc
  }
}

export default Permisos