import React,{useState,useEffect} from 'react'
import axios from 'axios'
import config from '../../../config/config';
import useErrorAlert from '../../../utils/hooks/useErrorAlert';

const useAsignarRecolector = () => {
    const{HanlderVerMensajeError}=useErrorAlert()
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

  const HandlerAsignarRecolector = async(Recolectorid,idpeticion) =>{

    let isMounted = true;
    let URL_ASIGNAR = `${config.API_SOCKET}/api/Picker/Assign/${Recolectorid}/${idpeticion}`
    const source = axios.CancelToken.source();
    try {
        const RespuestaAsignarRecolector = await axios.get(URL_ASIGNAR, {
            cancelToken: source.token
          });
          if (isMounted) {
           
          }

         
    } catch (error) {
        console.error(error)
        HanlderVerMensajeError(error.response.data.message)
    }
  }

  const HandlerDesignarRecolector = async(Recolectorid,idpeticion) =>{

    let isMounted = true;
    let URL_ASIGNAR = `${config.API_SOCKET}/api/Picker/Unassign/${Recolectorid}/${idpeticion}`
    const source = axios.CancelToken.source();
    try {
        const RespuestaAsignarRecolector = await axios.get(URL_ASIGNAR, {
            cancelToken: source.token
          });
          if (isMounted) {
            window.location.reload(true)
          }

         
    } catch (error) {
        console.error(error)
        HanlderVerMensajeError(error.response.data.message)
    }
  }
  // const HandlerCambioRecolector = (Recolectorid,idpeticion) => {
  //   switch (key) {
  //     case value:
        
  //       break;
    
  //     default:
  //       break;
  //   }
  // }
  return {
    HandlerAsignarRecolector,
    HandlerDesignarRecolector
  }
}

export default useAsignarRecolector